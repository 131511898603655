import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Button,
  SxProps,
} from '@mui/material';

interface ModalProps {
  title?: string;
  subTitle?: string;
  subTitle2?: string;
  subTitle3?: string;
  children?: React.ReactNode | string;
  open: boolean;
  handleClose: () => void;
  handleConfirm?: () => void;
  btn1: string;
  btn2?: string;
  sx?: SxProps;
}

const CustomModal = ({
  title,
  subTitle,
  subTitle2,
  subTitle3,
  children,
  open,
  handleClose,
  handleConfirm,
  btn1,
  btn2,
  sx,
  ...props
}: ModalProps) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      sx={{
        textAlign: 'center',
        '& .MuiPaper-root': {
          bgcolor: 'background.modal',
          padding: '16px 24px',
        },
        ...sx,
      }}
    >
      <DialogTitle id='alert-dialog-title'>
        <Typography variant='h3'>{title}</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          <Typography variant='body2'>{subTitle}</Typography>
          <Typography variant='body2'>{subTitle2}</Typography>
          <Typography variant='body2'>{subTitle3}</Typography>
        </DialogContentText>
        {children}
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          onClick={handleClose}
          sx={{
            width: '100%',
            color: 'white',
            bgcolor: btn2 ? 'customMenuListColor.main' : 'primary.main',
          }}
        >
          {btn1}
        </Button>
        {btn2 && (
          <Button
            onClick={handleConfirm}
            sx={{ width: '100%', color: 'white', bgcolor: 'primary.main' }}
            autoFocus
          >
            {btn2}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CustomModal;
