import { LoadingButton } from '@mui/lab';
import { SxProps, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';

interface CustomModalProps {
  open: boolean;
  title?: string;
  handleClose?: () => void;
  handleConfirm?: () => void;
  children: React.ReactNode;
  sx?: SxProps;
  btn1?: string;
  btn2?: string;
  needsLoadingBtn?: boolean;
  [x: string]: any;
}

export default function CustomModalRenewal({
  open,
  title,
  handleClose,
  handleConfirm,
  children,
  btn1,
  btn2,
  sx,
  needsLoadingBtn,
}: CustomModalProps) {
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        sx={{
          textAlign: 'center',
          '& .MuiPaper-root': {
            bgcolor: 'background.modal',
            padding: '32px',
            minWidth: '400px',
            borderRadius: 2,
          },
          '& .MuiDialogContent-root': {
            overflowY: 'hidden',
          },
          ...sx,
        }}
      >
        {title && (
          <DialogTitle id='alert-dialog-title' sx={{ py: 2 }}>
            <Typography variant='h3'>{title}</Typography>
          </DialogTitle>
        )}

        {children}
        {(btn1 || btn2) && (
          <DialogActions sx={{ display: 'flex', justifyContent: 'center', padding: 0 }}>
            <Button
              variant='contained'
              onClick={handleClose}
              sx={{
                borderRadius: 2,
                color: 'white',
                width: '100%',
                fontSize: 15,
                py: 3,
                bgcolor: btn2 ? 'customMenuListColor.main' : 'primary.main',
              }}
            >
              {btn1}
            </Button>
            {btn2 && !needsLoadingBtn && (
              <Button variant='contained' onClick={handleConfirm} autoFocus sx={btnSxProps}>
                {btn2}
              </Button>
            )}
            {btn2 && needsLoadingBtn && (
              <LoadingButton
                loading={needsLoadingBtn}
                variant='contained'
                sx={{
                  ...btnSxProps,
                  color: '#fff',
                  '& .MuiLoadingButton-loadingIndicator': {
                    color: '#fff',
                  },
                }}
                onClick={handleConfirm}
              >
                {btn2}
              </LoadingButton>
            )}
          </DialogActions>
        )}
      </Dialog>
    </>
  );
}

const btnSxProps = { width: '100%', borderRadius: 2, py: 3, fontSize: 15 };
