import SeoMetaTag from '@components/SeoMetaTag';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Avatar, Box, Button, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { userAtom, userProfileAtom } from '../../recoil/Auth/userAtom';
import AuthServices from '../../services/AuthServices';

const tabs = [
  { title: '나의 데이터', path: '/my-page' },
  { title: '나의 정보', path: '/my-page/info' },
  { title: '사용 내역', path: '/my-page/application' },
  { title: '획득 내역', path: '/my-page/acquisition' },
  { title: '문의', path: '/my-page/inquiry' },
];

const MyPage = () => {
  const { t } = useTranslation(['translation', 'mypage', 'common', 'popup']);
  const user = useRecoilValue(userAtom);
  const userProfile = useRecoilValue(userProfileAtom);
  const { pathname } = useLocation();

  const { mutate, data } = useMutation((uid: string) => AuthServices.getUserInfo({ uid }));

  useEffect(() => {
    if (user) {
      mutate(user?.uid);
    }
  }, []);

  return (
    <Box maxWidth='1240px' sx={{ mt: 14, margin: '64px auto 37px auto' }}>
      <SeoMetaTag
        title='Decakill | My Page'
        description='데카킬 마이페이지입니다.'
        keywords='마이페이지, MyPage'
      />
      <Stack>
        {/* 유저 정보 */}
        <Box
          sx={{
            bgcolor: 'background.modal',
            padding: '24px 32px',
            borderRadius: 2,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Avatar
            variant='square'
            src={userProfile?.profile_image_url}
            alt='userProfile'
            sx={{ width: 48, height: 48, borderRadius: 2, mr: 3 }}
          />
          <Typography variant='h5' color='white' mr={1.5}>
            {userProfile?.display_name}
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', mt: 5 }}>
          {/* tabs */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',

              bgcolor: 'background.modal',
              padding: '16px',
              mr: 5,
              borderRadius: 2,
            }}
          >
            {tabs.map((tab) => {
              const active = pathname === tab.path;
              return (
                <Link to={tab.path}>
                  <Button
                    endIcon={<ArrowForwardIosIcon />}
                    sx={{
                      color: active ? '#fff' : 'text.primary',
                      width: '158px',
                      padding: '12px 16px',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    {t(`탭.${tab.title}`, { ns: 'mypage' })}
                  </Button>
                </Link>
              );
            })}
          </Box>
          {/* Contents */}
          <Box sx={{ flex: 1 }}>
            <Outlet />
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};

export default MyPage;
