import {
  Avatar,
  Box,
  CircularProgress,
  Divider,
  Stack,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material';
import { GemInfo } from '@services/MyPageServices';
import { t } from 'i18next';

interface P {
  isLoading: boolean;
  data?: GemInfo;
}

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 218,
    padding: '12px',
    color: '#9e9ea0',
    backgroundColor: '#1c1c21',
    fontSize: '13px',
    textAlign: 'center',
    lineHeight: 1.6,
  },
});

const GemBox: React.FC<P> = ({ isLoading, data }) => {
  return (
    <Box sx={gemInfoContainer}>
      <Stack direction='row' divider={<Divider orientation='vertical' flexItem />}>
        <Box sx={gemBox1}>
          <Box sx={gemInnerBox}>
            <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
              <Avatar
                src='/images/icon/ico-league-pt.svg'
                alt='esportGem'
                sx={{ width: 15, height: 21, mr: 1 }}
              />
              <Typography
                color='white'
                variant='body2'
                sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
              >
                {`${t('퍼플젬', { ns: 'reward' })}`}
              </Typography>
            </Box>
            {isLoading ? (
              <Typography variant='body2' sx={{ color: 'primary.light' }}>
                <CircularProgress sx={{ color: 'primary.main' }} disableShrink size={14} />
              </Typography>
            ) : (
              <Typography variant='body2' sx={{ color: 'primary.light', mr: 1, flex: 0.5 }}>
                {data?.gemData.purpleGem.toLocaleString()}
              </Typography>
            )}
            <CustomWidthTooltip
              title={`${t('퍼플젬툴팁', { ns: 'reward' })}`}
              arrow
              placement='top'
              sx={{
                '& .MuiTooltip-arrow': {
                  color: 'background.modal',
                },
              }}
            >
              <Avatar
                src='/images/icon/ico-info-02.png'
                alt='infoIcon'
                sx={{
                  width: '20px',
                  height: '20px',
                  ml: 1,
                }}
              />
            </CustomWidthTooltip>
          </Box>
        </Box>
        <Box sx={gemBox2}>
          <Box sx={gemInnerBox}>
            <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
              <Avatar
                src='/images/icon/ico-streamer-pt.svg'
                alt='greenGem'
                sx={{ width: 15, height: 21, mr: 1 }}
              />
              <Typography
                color='white'
                variant='body2'
                sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
              >
                {`${t('그린젬', { ns: 'reward' })}`}
              </Typography>
            </Box>
            {isLoading ? (
              <Typography variant='body2' sx={{ color: 'secondary.main' }}>
                <CircularProgress sx={{ color: 'secondary.main' }} disableShrink size={14} />
              </Typography>
            ) : (
              <Typography variant='body2' sx={{ color: 'secondary.main', mr: 1, flex: 0.5 }}>
                {data?.gemData.greenGem.toLocaleString()}
              </Typography>
            )}
            <CustomWidthTooltip
              title={`${t('그린젬툴팁', { ns: 'reward' })}`}
              arrow
              placement='top'
              sx={{
                '& .MuiTooltip-arrow': {
                  color: 'background.modal',
                },
              }}
            >
              <Avatar
                src='/images/icon/ico-info-02.png'
                alt='infoIcon'
                sx={{
                  width: '20px',
                  height: '20px',
                  ml: 1,
                }}
              />
            </CustomWidthTooltip>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};

export default GemBox;

const gemInfoContainer = {
  padding: '12px',
  bgcolor: 'customMenuListColor.main',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: 2,
  '& .MuiDivider-root': {
    borderColor: 'customBorderColor.light',
  },
};

const gemBox1 = {
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
};

const gemBox2 = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

const gemInnerBox = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 24px',
};
