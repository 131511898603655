import { createTheme, experimental_sx as sx } from '@mui/material';
import { Theme } from '@mui/material/styles';
import colorToken from '../ui/colorToken';

const { purple, green, orange, grey, black } = colorToken;

declare module '@mui/material/styles' {
  interface CustomColor {
    light: string;
    main: string;
    dark: string;
  }
  interface Palette {
    customBorderColor: CustomColor;
    customMenuListColor: CustomColor;
  }
  interface PaletteOptions {
    customBorderColor: CustomColor;
    customMenuListColor: CustomColor;
  }

  interface TypeText {
    primary: string;
    secondary: string;
    disabled: string;
    helper?: string;
  }

  interface TypeBackground {
    appBar: string;
    modal: string;
  }
}

const { palette } = createTheme();
const theme = createTheme({
  palette: {
    customBorderColor: palette.augmentColor({
      color: {
        light: grey[200],
        main: grey[700],
        dark: purple[700],
      },
    }),
    customMenuListColor: palette.augmentColor({
      color: {
        light: grey[400],
        main: grey[600],
      },
    }),
    background: {
      default: black[100],
      appBar: black[200],
      modal: grey[800],
    },
    text: {
      primary: grey[100],
      secondary: grey[300],
      disabled: grey[50],
      helper: orange[100],
    },
    primary: {
      light: purple[500],
      main: purple[600],
      dark: purple[800],
      contrastText: '#fff',
    },
    secondary: {
      light: green[500],
      main: green[600],
      dark: green[900],
      contrastText: '#fff',
    },
  },
  typography: {
    htmlFontSize: 15,
    fontFamily: ['Noto Sans KR', 'sans-serif'].join(','),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      #root {
        height: 100%;
        overflow-y: scroll;
    }
      `,
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: grey[600],
            boxShadow: 'none',
            borderColor: 'transparent',
          },
          '&:disabled': {
            backgroundColor: grey[600],
            color: 'white',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: sx({
          borderRadius: 2,
          '& .MuiOutlinedInput-root': {
            '& > fieldset': {
              borderColor: 'none',
            },
          },
        }),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&:hover': {
            '& > fieldset': {
              borderColor: 'none',
            },
          },
          'Mui-focused': {
            '& > fieldset': {
              borderColor: '#8c40cc',
            },
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: `1px solid transparent`,
          },
          '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              border: `1px solid #8c40cc`,
            },
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: 15,
        },
        button: {
          fontSize: 13,
        },
        h1: {
          fontSize: 48,
          fontWeight: 'bold',
          color: '#fff',
        },
        h2: {
          fontSize: 36,
          fontWeight: 'bold',
          color: '#fff',
        },
        h3: {
          fontSize: 24,
          fontWeight: 'bold',
          color: '#fff',
        },
        h4: {
          fontSize: 20,
          fontWeight: 'bold',
        },
        h5: {
          fontSize: 18,
          fontWeight: 'bold',
        },
        h6: {
          fontSize: 15,
          fontWeight: 'bold',
          color: '#fff',
        },
        // Body Lg - 메인타이틀과 같이 쓰이는 서브텍스트
        body1: {
          fontSize: 18,
          fontWeight: 'regular',
          color: grey[100],
        },
        // Body md - 기본 텍스트 크기
        body2: {
          fontSize: 15,
          fontWeight: 'regular',
          color: grey[100],
        },
        subtitle1: {
          fontSize: 13,
          fontWeight: 'regular',
          color: grey[300],
        },
        subtitle2: {
          fontSize: 13,
          fontWeight: 'regular',
          color: grey[100],
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '15px',
          '&.Mui-selected': {
            backgroundColor: 'transparent',
            color: '#fff',
            '&.Mui-focusVisible': {
              outline: 'none',
              background: 'transparent',
            },
          },
        },
      },
    },
  },
  spacing: 4,
});

export default theme;
