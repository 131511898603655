import { InfoHelperText } from '@components/HelperText';
import { SxProps, TextField } from '@mui/material';
import { FormValues } from '@services/MyPageServices';
import { Control, Controller, FieldError } from 'react-hook-form';

interface rules {
  [x: string]:
    | string
    | {
        value: number | RegExp;
        message: string;
      };
}
interface P {
  type: string;
  id: string;
  label: string;
  sx: SxProps;
  rules: rules;
  control: Control<FormValues>;
  errors?: FieldError;
  name:
    | 'address'
    | 'idx'
    | 'uid'
    | 'firstName'
    | 'lastName'
    | 'phoneCode'
    | 'phone'
    | 'region'
    | 'postalCode'
    | 'province'
    | 'city'
    | 'addressDetail'
    | 'email'
    | 'code';
  [x: string]: any;
}

const InfoTextField: React.FC<P> = ({
  type,
  id,
  label,
  sx,
  rules,
  control,
  name,
  errors,
  onFocus,
  ...props
}) => {
  return (
    <>
      <Controller
        render={({ field: { onChange, onBlur, name, value, ref }, fieldState: { error } }) => (
          <TextField
            type={type}
            id={id}
            name={name}
            autoComplete='off'
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            value={value}
            inputRef={ref}
            error={!!error}
            label={label}
            sx={sx}
            {...props}
          />
        )}
        control={control}
        name={name}
        rules={rules}
      />
      {errors && <InfoHelperText>{errors.message}</InfoHelperText>}
    </>
  );
};

export default InfoTextField;
