export const privacyPolicy = `

### 개인정보 취급방침

'(주)팀스노우볼'(이하 '회사')가 운영하는 ‘Decakill’은 정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하 ‘정보통신망법’) 제27조의 2에 의거 이용자의 개인정보보호와 권익을 보호하고 관련된 고충 및 애로사항을 신속하게 처리하기 위해 아래의 개인정보처리방침을 제정·운영하고 있습니다.

회사는 관계법령에서 규정하고 있는 책임과 의무를 준수하고 실천하기 위해 최선의 노력을 하고 있습니다.

### **제1조 개인정보 수집이용 및 보유기간 안내**

회사는 아래와 같이 제공하는 서비스에 따라 개인정보의 수집목적, 항목, 보유 및 이용기간을 달리하여 서비스제공을 위하여 필요한 최소한의 개인정보를 수집하고 있습니다.

- 회원가입 및 고객관리
    - 수집 목적 : (시청자/스트리머)회원가입, Twitch 계정연동
    - 필수 수집항목 : Twitch ID, 이메일
    - 보유 및 이용기간 : 회원탈퇴시
- 서비스 이용(시청자)
    - 수집 목적 : 경품 구매 및 당첨, 배송과 환불
    - 필수 수집항목 : 이름, 생년월일, 성별, 휴대전화 번호, 통신사, 집 주소, 접속기록, 접속지IP 등 자동수집정보
    - 보유 및 이용기간 : 회원탈퇴시
- 서비스 이용(스트리머)
    - 수집 목적 : Decakill 소프트웨어 이용 및 정산관리
    - 필수 수집항목 : 소프트웨어 이용기록, 접속기록, 접속지IP 등 자동수집정보, 주소, 전화번호
    - 보유 및 이용기간 : 회원탈퇴시
- 고객문의
    - 수집 목적 : 1:1 문의응대
    - 필수 수집항목 : 이름, 휴대전화 번호, 이메일, 문의내용
    - 보유 및 이용기간 : (회원)위탁업무 계약 종료 시 또는 회원탈퇴 시, (비회원)위탁업무 계약 종료 시 문의 종료 후 1년
- 기타
    - 회사는 만 14세 미만 아동에게 당사의 서비스를 제공하지 않으며 이와 관련된 개인정보를 수집하지 않습니다.
    - 회사가 처리하는 회원정보의 목적과 항목이 변경될 경우에는 관련법령에 따라 사전에 동의를 요청합니다.
    - 회사는 주민등록번호 처리를 원칙적으로 금지하며 업무 수행 중 법률, 대통령령, 국회규칙, 대법원규칙, 헌법재판소규칙, 중앙선거관리위원회 규칙 및 감사원규칙에서 구체적으로 주민등록번호의 처리를 요구할 경우에만 처리하고 있습니다.
    - 회사는 다음의 방식으로 개인정보를 수집하며 수집 전 사전동의를 획득합니다.
        - 서비스 이용 과정에서 이용자가 개인정보를 직접 입력하는 방식
        - 박람회, 세미나, 행사진행 등 오프라인에서 서면으로 개인정보를 수집하는 방식
        - 서비스를 이용하는 과정에 쿠키, 접속로그 등 자동으로 생성 및 수집되는 방식

### **제2조 개인정보자동수집 장치의 설치와 운영거부에 관한 사항**

회사는 서비스 이용과정에서 이용자로부터 다음과 같은 정보들이 자동으로 생성/수집되고 다음의 목적으로 이용될 수 있습니다.

- 개인정보 자동수집안내 및 거부방법
    - 수집항목 : 방문기록, 접속IP정보, 서비스 이용기록, PC 소프트웨어 이용 로그, 광고식별자, 쿠키(쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자 PC 컴퓨터의 하드디스크에 저장되기도 합니다.)
    - 분석도구를 활용한 수집방법 : 구글애널리틱스 : Google에서 제공하는 웹 로그 분석도구를 이용하고 있으며, 구글애널리틱스는 쿠키(Cookie)를 통하여 당사 서비스 이용자의 주요행동(행태정보)를 수집합니다. 수집한 정보로 이용자 개인을 식별 할 수는 없으나 아래 방법으로 거부하실 수 있습니다.
    - 쿠키의 설치∙운영 및 거부 방법 : 아래 방법을 통해 쿠키 저장을 거부 할 수 있습니다.
        - Internet Explorer의 경우 : 웹브라우저 상단의 도구>인터넷 옵션>개인정보 메뉴의 옵션 설정
        - Microsoft Edge의 경우 : 웹브라우저 상단 메뉴 > 설정 > 고급 설정 보기 > 쿠키 메뉴의 옵션 설정
        - Chrome의 경우 : 웹브라우저 상단 메뉴 > 설정 > 고급 > 콘텐츠 설정 > 쿠키 메뉴의 옵션 설정
- 개인정보 자동수집정보 사용목적
    - 관련법규의 준수 : 회사는 관련법규의 준수를 위해 이용자의 접속기록(로그인)기록을 보관할 의무가 있습니다.
    - Session방식의 회원인증 : 회원인증을 위해 암호화된 Session ID만 서버에 저장하는 방식으로 개인정보가 노출되지 않습니다. 또한 일정시간이 지나면 Session 정보가 자동으로 사라지며, 이용자가 Session정보를 삭제하고 싶으신 경우 로그아웃을 하거나 브라우저를 닫으면 바로 삭제가 됩니다.
    - 서비스 품질 향상 및 신상품 개발 : 이용자의 사용이력, 구매 및 검색 이력 등 이용자의 관심, 성향 등을 파악하고 분석하여 기존상품의 품질을 개선하여 더 나은 서비스를 제공하고 신상품을 개발하는데 사용합니다.
    - 이용자 맞춤 추천 서비스 제공 : 이용자의 사용이력, 구매 및 검색 이력 등을 분석하여 이용자가 선호할 것으로 예상되는 상품을 추천합니다.

### **제3조 개인정보의 보유·이용기간 및 파기**

회사는 수집한 개인정보는 이용자로부터 개인정보 수집 시에 동의 받은 개인정보 보유·이용기간 내 또는 관련법령에 따른 개인정보 보유·이용기간 내 처리하고 해당 목적이 달성 및 보유이용기간이 경과할 시에는 지체없이 해당 개인정보를 복구 또는 재생할 수 없는 방법으로 파기합니다.
- 회원정보
    - 이용자가 서비스 회원을 탈퇴할 경우 회사는 부정이용을 방지하기 위하여 (스트리머) 1년, (시청자) 60일 간 개인정보를 보관합니다. 이 경우 개인정보는 식별할 수 없는 상태로 보관되어 동일한 id로 재가입이 불가합니다. (단, 관계 법령에서 개인정보를 보존해야할 필요가 있는 경우 해당 법률의 규정에 따릅니다.)
    - 1년이상 서비스에 접속하지 않은 이용자의 개인정보는 안전하게 분리하여 보관하며 30일 전 다음의 사항을 이용자에게 안내합니다.
        - 기간 만료 30일 전까지 개인정보가 파기되거나 분리되어 저장·관리되는 사실
        - 기간 만료일
        - 해당 개인정보의 항목
        - 안내방법 : 전자우편·서면·전화 또는 이와 유사한 방법 중 어느 하나의 방법
    - 자동수집정보 : 수집일로부터 최대 24개월간 보유·이용되며 보유기간 경과 후 즉시삭제 됩니다.
- 관련법령에 따른 의무 보유기간

|                 보유목적                	|  기간 	|                  보유 근거                 	|
|:---------------------------------------:	|:-----:	|:------------------------------------------:	|
|    거래내역 및 증빙서류와 관련된 정보   	|  5년  	|            국세기본법, 법인세법            	|
|          표시•광고에 관한 기록          	| 6개월 	| 전자상거래 등에서의 소비자보호에 관한 법률 	|
|    계약 또는 청약철회 등에 관한 기록    	|  5년  	| 전자상거래 등에서의 소비자보호에 관한 법률 	|
|  대금결제 및 재화 등의 공급에 관한 기록 	|  5년  	| 전자상거래 등에서의 소비자보호에 관한 법률 	|
| 소비자의 불만 또는 분쟁처리에 관한 기록 	|  3년  	| 전자상거래 등에서의 소비자보호에 관한 법률 	|
|           접속에 관한 기록보존          	| 3개월 	|               통신비밀보호법               	|

### **제4조 개인정보 처리 위탁**

회사는 원활한 개인정보 업무처리를 위하여 일부 개인정보처리업무를 위탁하고 있으며 위탁 계약 체결 시 관련법령에 따라 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다. 위탁업무의 내용이나 수탁자가 추가, 변경될 경우에는 지체 없이 관련 법령에 따른 사전 동의 안내 또는 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.
- 개인정보처리 위탁업무 및 수탁사 안내

|           수탁자           	|         위탁업무         	|          연락처          	|
|:--------------------------:	|:------------------------:	|:------------------------:	|
|   조이코퍼레이션(채널톡)   	|         고객문의         	|      privacy@zoyi.co     	|
| (주)오니온파이브(오큐파이) 	|         고객문의         	| legal@support.oqupie.com 	|
|       Microsoft Azure      	| 시스템운영 및 데이터보관 	|        02-531-4500       	|

### **제5조 개인정보의 안전성 확보조치**

회사는 이용자의 개인정보를 안전하게 관리하여 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지 않도록 최선을 다하고 있으며 필요한 기술적, 관리적 및 물리적 조치를 하고 있습니다.

- 개인정보 취급 직원의 최소화 및 교육
    - 개인정보를 취급하는 직원을 최소화하고, 주기적인 개인정보 보호 교육을 실시하여 개인정보를 관리하는 대책을 시행하고 있습니다.
- 내부관리계획의 수립 및 시행
    - 개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.
- 접속기록의 보관 및 위변조 방지
    - 개인정보 침해사고 발생 시 대응이 용이하도록 개인정보처리시스템에 접속한 기록(웹 로그, 요약정보 등)을 최소 1년 이상 보관, 관리하고 있으며, 접속 기록이 위변조 및 도난, 분실되지 않도록 보안기능을 사용하고 있습니다.
- 개인정보의 암호화
    - 이용자의 개인정보는 암호화 되어 저장 및 관리되고 있습니다.
- 해킹 등에 대비한 기술적 대책
    - 회사는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 합니다. 또한 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.
- 개인정보에 대한 접근통제 제한
    - 개인정보를 처리하는 개인정보처리시스템에 대한 접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위한 조치를 하고 있습니다.

### **제6조 이용자 및 법정대리인의 권리와 그 행사 방법**

회사는 이용자의 개인정보를 안전하게 관리하여 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지 않도록 최선을 다하고 있으며 필요한 기술적, 관리적 및 물리적 조치를 하고 있습니다.

- 이용자의 권리 및 행사방법
    - 열람/수정 : (시청자) 마이페이지, (스트리머) 대시보드
    - 회원탈퇴 : (시청자) 마이페이지 > 탈퇴, (스트리머) 대시보드> 계정설정
    - 그 밖에 서면, 전자우편 등을 통하여 개인정보의 처리 정지 및 삭제를 요구할 수 있습니다.
    - 회사는 개인정보의 오류 등에 대한 정정 또는 삭제를 요청한 경우에는 정정 또는 삭제를 완료하기 전까지 당해 개인정보를 이용하거나 제공하지 않습니다.
    - 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 당해 개인정보의 삭제를 요구할 수 없습니다.
    - 회사는 이용자 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인인지를 확인합니다.

### **제7조 개인정보보호 책임자 및 이용자 권익침해에 대한 구제방법**

회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 이용자의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보보호책임자를 지정하고 있습니다.

- 개인정보보호 책임자
    - 성명 : 김진일
    - 직책 : 대표이사
    - 연락처 : 070-8811-8282
- 개인정보보호 책임자의 역할
    - 이용자는 서비스를 이용하면서 발생한 모든 개인정보보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보보호책임자에게 문의하실 수 있습니다. 회사는 이용자의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.
- 권익침해관련 도움받을수 있는 기관
    - 이용자의 권익침해 관련 자세한 도움이 필요하시면 아래 기관에 문의하여 주시기 바랍니다.
        1. 개인정보 침해신고센터 (한국인터넷진흥원 운영)
            - 소관업무 : 개인정보 침해사실 신고, 상담 신청
            - 홈페이지 : privacy.kisa.or.kr
            - 전화 : (국번없이) 118
            - 주소 : (58324) 전남 나주시 진흥길 9(빛가람동 301-2) 3층 개인정보침해신고센터
        2. 개인정보 분쟁조정위원회
            - 소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)
            - 홈페이지 : **[www.kopico.go.kr](http://www.kopico.go.kr/)**
            - 전화 : (국번없이) 1833-6972
            - 주소 : (03171) 서울특별시 종로구 세종대로 209 정부서울청사 4층
        3. 대검찰청 사이버범죄수사단 : 02-3480-3573 (**[www.spo.go.kr](http://www.spo.go.kr/)**)
        4. 경찰청 사이버안전국 : 182 (cyberbureau.police.go.kr)

### 개인정보 수집∙이용 동의서

'Decakill.com'의 홈페이지 회원 가입을 신청하시는 분께 아래와 같이 개인정보의 수집·이용목적, 수집하는 개인정보의 항목, 개인정보의 보유 및 이용기간을 안내하여 드리오니 내용을 자세히 읽으신 후 동의 여부를 결정하여 주십시오.

### 1. 수집하는 개인정보의 항목

'Decakill.com'은(는) 다음의 개인정보 항목을 수집합니다.

1. 홈페이지 회원가입 및 관리수집 정보: 이메일 주소, 닉네임, 비밀번호
2. 민원사무 처리수집 정보: 이메일 주소
3. 재화 또는 서비스 제공수집 정보 : 이메일 주소, 닉네임, 비밀번호
4. 마케팅 및 광고에의 활용선택: 이메일 주소
5. 인터넷 서비스 이용과정에서 아래 개인정보 항목이 자동으로 생성되어 기록 및 수집될 수 있습니다. 회원이 (트위치 회사)에 등록한 계정과의 연동을 위한 식별자 접속 IP정보, 쿠키, 접속로그, 서비스 이용기록, 이용제한 기록 등

### 2. 개인정보의 수집·이용 목적

'Decakill.com'은(는) 개인정보를 다음의 목적을 위해 수집·이용합니다. 수집한 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며 이용 목적이 변경될 시에는 사전동의를 구할 예정입니다.

1. 홈페이지 회원가입 및 관리회원 가입의사 확인, 회원자격 유지•관리, 서비스 부정이용 방지, 각종 고지•통지, 분쟁 조정을 위한 기록 보존 등을 목적으로 개인정보를 수집·이용합니다.
2. 민원사무 처리민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락·통지, 처리결과 통보 등을 목적으로 개인정보를 수집·이용합니다.
3. 재화 또는 서비스 제공서비스 제공, 콘텐츠 제공 등을 목적으로 개인정보를 수집·이용합니다.
4. 마케팅 및 광고에의 활용신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공, 인구통계학적 특성에 따른 서비스 제공 및 광고 게재, 서비스의 유효성 확인, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 수집·이용합니다.

### 3. 개인정보의 보유 및 이용 기간

1. 'Decakill.com'은(는) 법령에 따른 개인정보 보유∙이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의 받은 개인정보 보유∙이용기간 내에서 개인정보를 처리, 보유합니다.
2. 각각의 개인정보 보유 및 이용 기간은 다음과 같습니다.
    1. 홈페이지 회원가입 및 관리: 홈페이지 탈퇴일로부터 30일이 경과하는 날까지. 다만, 관계 법령 위반에 따른 수사, 조사 등이 진행중인 경우에는 해당 수사, 조사 종료 시까지.
    2. 민원사무 처리: 민원사무 처리 완료 시까지. 단, 민원 발생 후 소송절차가 개시된 경우 해당 소송절차가 확정적으로 종결되는 날까지.
    3. 재화 또는 서비스 제공: 재화·서비스 공급 완료 시까지. 단, 관련 법령에서 소비자 보호 등을 위하여 필요한 경우 해당 법령에서 정한 기간의 만료일까지.
    4. 마케팅 및 광고에의 활용: 회원탈퇴 후 다른 사람이 탈퇴회원의 이메일주소를 이용하여 즉시 재가입하는 것을 방지하기 위하여 회원 탈퇴일로부터 30일이 되는 날까지. 단, 마케팅 및 맞춤서비스 제공과 관련하여 소송절차가 개시된 경우 해당 소송절차가 확정적으로 종결되는 날까지.

### 4. 기타 사항

1. 개인정보 수집 및 이용에 대해서는 거부할 수 있으며, 거부 시에는 회원가입이 불가합니다.
2. 서비스 제공을 위해서 반드시 필요한 최소한의 개인정보이므로 동의를 하셔야 서비스 이용이 가능합니다.
3. 이 외 서비스 이용과정에서 별도 동의를 통해 추가정보 수집이 있을 수 있습니다.
`;
