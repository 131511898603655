export const termsForUser = `

### 이용약관 (시청자)

### 제1조 (목적)

본 약관은 ㈜팀스노우볼(이하 "회사" 또는 "팀스노우볼")에서 제공하는 서비스(이하 "서비스")의 이용과 관련하여, 회사와 회원 간에 서비스 이용에 관한 권리 및 의무와 책임사항, 기타 필요한 사항을 규정하는 것을 목적으로 합니다. 그리고 회사는 아래와 같은 서비스를 제공합니다.

1. [https://decakill.live](https://decakill.live/login)
2. [https://decakill.com/](https://decakill.com/)
3. 회사가 제공하는 트위치 익스텐션(Decakill)

### 제2조 (**용어의 정의)**

1. 서비스: 개인용 컴퓨터 (PC), TV, 휴대형 단말기, 전기통신설비 등을 포함한 각종 유무선 장치와 같이 구현되는 단말기와 상관없이 회사가 제공하는 서비스를 의미합니다.
2. 회원: 회사와 서비스 이용계약을 체결하고 회사가 제공하는 서비스를 이용하는 모든 사용자를 의미합니다.
3. 계정: 회원의 식별 및 서비스 이용을 위하여 회원이 선정하고 회사가 인증한 문자, 숫자 또는 특수문자의 조합으로 만들어진 식별자(이메일 주소 등)를 의미합니다.
4. 비밀번호: 회원의 개인 정보 보호 및 회원의 동일성 확인을 위해서 회원이 정한 문자, 숫자 또는 특수문자의 조합을 의미합니다.
5. 계정 정보: 계정, 비밀번호, 닉네임 등 회원이 회사에 제공한 일반정보 및 게임 전적 정보 등 생성정보를 통칭합니다.
6. 게시물: 회원이 서비스를 이용함에 있어 회원이 서비스에 게시한 문자, 문서, 그림, 음성, 동영상, 링크, 파일 혹은 이들의 조합으로 이루어진 정보 등 모든 정보나 자료를 의미합니다.
7. 가입신청자: 회사에 대하여 서비스 이용을 신청하였으나 아직 회사로부터 그 서비스 이용에 관하여 동의를 받지 못한 자를 의미합니다.
8. “젬” 이라 함은 “회원”이 “Decakill 서비스”에 참여하여 받은 보상으로 “decakill.com” 에서 사용되는 포인트를 의미한다.

### **제3조 (약관 등의 명시와 설명 및 개정)**

1. 회사가 약관을 개정할 경우에는 적용 일자 및 개정 사유를 명시하여 현행 약관과 함께 서비스 초기화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다. 다만, 회원에게 불리하게 약관내용을 변경하는 경우에는 30일의 사전 유예기간을 두고 공지합니다.
2. 회원은 변경된 약관에 동의하지 않을 권리가 있으며, 적용일로부터 7일(회원에게 불리하게 약관내용을 변경하는 경우에는 30일) 이내에 변경된 약관에 대해 거절의 의사를 서면 또는 이메일을 통하여 명시적으로 표시하지 않았을 때에는 변경된 약관에 동의한 것으로 간주합니다.

### **제 4조(회사의 의무)**

1. 회사는 회원에게 서비스를 제공할 의무가 있습니다.
2. 회사는 회원의 개인정보를 보호할 의무가 있습니다.

### **제5조(서비스의 중단 및 종료)**

1. 회사는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신의 두절, 화재, 폭풍, 홍수, 지진, 사고, 전쟁, 반란, 폭동, 기타 민란, 전염병, 격리, 천재지변, 정부조치 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.
2. 회사는 서비스의 제공이 일시적으로 중단된 경우에는 사유 및 중단 기간 등을 회원에게 신속하게 공지합니다.
3. 사업종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로 서비스를 종료할 경우에는 회사는 제8조에 정한 방법으로 회원에게 통지합니다.

### **제6조(회원가입)**

1. 이용자는 회사가 정한 가입 양식에 따라 회원정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로서 회원가입을 신청합니다.
2. 회사는 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각 호에 해당하지 않는 한 회원으로 등록합니다.
    - 가입신청자가 이 약관 제7조 3항에 의하여 이전에 회원자격을 상실한 적이 있는 경우
    - 등록 내용에 허위, 기재누락, 오기가 있는 경우
    - 기타 회원으로 등록하는 것이 서비스 제공에 현저히 지장이 있다고 판단되는 경우
3. 회원가입계약의 성립 시기는 회사의 승낙이 회원에게 도달한 시점으로 합니다.
4. 회원은 회원가입 시 등록한 사항에 변경이 있는 경우, 상당한 기간 이내에 회원정보 수정 등의 방법으로 그 변경사항을 알려야 합니다.

### **제7조(회원 탈퇴 및 자격 상실 등)**

1. 회원은 회사에 언제든지 탈퇴를 요청할 수 있으며 회사는 즉시 회원탈퇴를 처리합니다.
2. 회원이 다음 각 호의 사유에 해당하는 경우, 회사는 회원자격을 제한 및 정지시킬 수 있습니다.
    - 가입 신청 시에 허위 내용을 등록한 경우
    - 회원이 본 약관에서 정한 의무사항을 위반하여 서비스의 정상적인 운영을 방해한 경우
    - 회원이 타인의 명의를 도용하여 회원가입을 한 경우
    - 타인의 명예를 훼손하거나 모욕하는 경우
    - 회원간 또는 회사와 회원간에 분쟁이 발생하여 분쟁이 해결되지 않는 경우
    - 회원이 제3자의 지적재산권을 침해한 것으로 의심될 상당한 이유가 있는 경우
    - 서비스를 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우
1. 회사가 회원 자격을 제한 또는 정지 시킨 후, 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 회사는 회원자격을 상실 시킬 수 있습니다.
2. 회사가 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우 회원에게 이를 통지하고, 회원등록 말소 전에 최소한 7일 이상의 기간을 정하여 소명할 기회를 부여합니다.
3. 회원이 탈퇴하는 경우 탈퇴 요청 이전에 환불 절차를 거치지 않은 캐시는 탈퇴와 동시에 삭제되고 회원은 회사에 대하여 그 책임을 물을 수 없습니다.

### **제8조(회원에 대한 통지)**

1. 회사는 회원에 대한 통지를 하는 경우, 회원이 회사와 미리 약정하여 지정한 전자우편 주소 또는 SMS 등으로 할 수 있습니다.
2. 회사는 불특정다수 회원에 대한 통지의 경우 1주일이상 서비스 게시판 등에 게시하여 개별 통지에 갈음할 수 있습니다. 다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 개별통지를 합니다.
3. 회사가 상기와 같이 통지하였으나 회원이 통지된 내용을 확인하지 않음으로써 회원에게 발생한 불이익에 대해서 회사는 책임을 지지 않습니다.

### **제9조(개인정보 제공 동의)**

1. 회사가 제3자에게 회원의 개인정보를 제공할 필요가 있는 경우 1) 개인정보를 제공받는 자, 2)개인정보를 제공받는 자의 개인정보 이용목적, 3) 제공하는 개인정보의 항목, 4) 개인정보를 제공받는 자의 개인정보 보유 및 이용기간을 회원에게 알리고 동의를 받아야 합니다. (동의를 받은 사항이 변경되는 경우에도 같습니다.)
2. 회사가 제3자에게 회원의 개인정보를 취급할 수 있도록 업무를 위탁하는 경우에는 1) 개인정보 취급위탁을 받는 자, 2) 개인정보 취급위탁을 하는 업무의 내용을 구매자에게 알리고 동의를 받아야 합니다. (동의를 받은 사항이 변경되는 경우에도 같습니다.) 다만, 서비스제공에 관한 계약이행을 위해 필요하고 구매자의 편의증진과 관련된 경우에는 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」에서 정하고 있는 방법으로 개인정보 취급방침을 통해 알림으로써 고지절차와 동의절차를 거치지 않아도 됩니다.

### **제 10조(회원의 의무)**

1. 회원은 회원가입을 통해 이용신청을 할 경우 사실에 근거하여 작성하여야 합니다. 회원이 허위 또는 타인의 정보를 등록한 경우 회사에 대하여 일체의 권리를 주장할 수 없으며, 이로 인하여 발생하는 손해에 대해 회사는 책임지지 않습니다.
2. 아이디와 비밀번호에 관한 관리책임은 회원에게 있습니다.
3. 회원은 자신의 아이디 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.
4. 회원이 자신의 아이디 및 비밀번호를 도난당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 회사에 통보하고 회사의 안내가 있는 경우에는 그에 따라야 합니다.
5. 회원이 다음 각 항에 해당하는 행위를 하는 경우에 회사는 회원의 서비스 이용을 제한하거나 정지 또는 회원 자격 박탈을 할 수 있습니다. 또한 회사는 민사상 손해배상이나 기타 법적인 조치를 취할 수 있습니다.
    - 해킹 또는 기타 시스템에 무리를 주는 행위
    - 서비스에 대한 권리를 제 3 자에게 양도, 매매하는 행위
    - 결제에 있어 그 결제수단을 위, 변조, 도용하거나 부정취득 또는 사용하는 행위
    - 불특정 다수의 시청자를 대상으로 하여 광고 또는 선전하는 내용을 후원 닉네임 혹은 메시지에 게시하는 등의 영리활동을 하는 행위
    - 회사, 다른 회원 또는 제3자를 비방하거나 중상모략으로 명예를 손상시키는 내용을 후원 닉네임 혹은 메시지에 게시하는 행위

### 제 11조(젬의 획득)

### 제 12조(젬의 사용)

1. 캐시는 회사의 유료 서비스 이용료를 결제하는데 이용되며, 회원의 결제 및 이용 내역 등은 홈페이지에서 확인하실 수 있습니다.
2. 회사 서비스의 중대한 하자에 의해 콘텐츠가 손상, 훼손, 삭제되었거나 불량했을 경우 회사의 판단에 따라 캐시의 재충전, 또는 해당 콘텐츠의 복원 등으로 보상 받을 수 있습니다.
3. 캐시 잔액에 대한 이자수익은 발생하지 않습니다.
4. 캐시를 이용하여 구매한 콘텐츠에 대해서는 청약철회를 할 수 없습니다.

### 제 13조(젬의 소멸)

상법 제64조 상사시효에 근거하여 캐시 서비스의 최종 이용일로부터 이용내역 없이 [60]개월이 경과한 경우 잔여 캐시는 자동으로 소멸됩니다.

### 제 14조(젬의 환불)

1. 회원이 자신의 아이디를 통해 충전한 캐시의 잔여액에 대한 환불을 요청하면 적법한 절차에 따라 환불 받을 수 있습니다. 단, 회원이 무료로 지급받은 캐시나 이미 사용한 캐시는 환불 받을 수 없습니다.
2. 환불 시에는 캐시 잔액에서 10%의 수수료를 공제한 금액을 환불해 드리며(단, 캐시 잔액이 10,000원 미만인 경우, 1,000원의 환불 수수료를 공제합니다.) 캐시 잔액이 1,000원이하인 경우에는 환불이 불가능합니다. 환불 대상 캐시가 100,000원 이상인 경우에는 결제에 대한 요금 수납이 완료된 경우에만 환불이 가능합니다.
3. 주민등록법, 저작권법, 컴퓨터프로그램보호법, 정보통신망법 등 각종 법령에 대한 중대한 불법행위 또는 서비스 운영에 심각한 악영향을 미치는 행위를 한 것으로 판단되는 회원의 계정 및 아이디 이용을 제한하거나 해당 회원과의 계약을 해지하는 경우, 회사는 캐시를 환불하지 않을 수 있습니다. 다만, 회원이 중대한 불법행위 또는 서비스 운영에 심각한 악영향을 미치는 행위를 하지 않았다는 것을 소명하는 경우에는 그러하지 않습니다.
4. 회사는 회원이 충전한 캐시를 사용하지 않았고 결제대행사를 통해 전액 취소 처리가 가능한 경우 별도의 수수료 없이 즉각 취소 처리를 진행합니다. 그렇지 않은 경우에는 회원이 환불 요청 시 기입한 은행계좌를 통해 송금하며, 경우에 따라 은행 송금 수수료가 발생하는 경우 이를 제외한 금액을 입금합니다.
5. 위 항목에 따라 환불이 가능한 경우에도 사용자가 결제 시 이용한 특정 결제수단을 제공하는 결제대행사 혹은 금융사의 요청이 있을 경우 환불이 제한되거나 보류될 수 있습니다. 이에 해당하는 결제수단의 경우 별도 안내를 통해 확인할 수 있으며, 회사는 특정 결제수단에 대한 결제대행사 혹은 금융사의 정책 변경이 있을 경우 즉각 고지, 안내합니다.

### 제 15조(경품에 대한 건)

### **제16조(개인정보보호)**

1. 회사는 회원의 개인정보 수집 시 서비스제공을 위하여 필요한 범위에서 최소한의 개인정보를 수집합니다.
2. 회사는 회원가입시 구매계약이행에 필요한 정보를 미리 수집하지 않습니다. 다만, 관련 법령상 의무이행을 위하여 구매계약 이전에 본인확인이 필요한 경우로서 최소한의 특정 개인정보를 수집하는 경우에는 그러하지 아니합니다.
3. 회사는 회원의 개인정보를 수집·이용하는 때에는 당해 회원에게 그 목적을 고지하고 동의를 받습니다.
4. 회사는 수집된 개인정보를 목적 외의 용도로 이용할 수 없으며, 새로운 이용목적이 발생한 경우 또는 제3자에게 제공하는 경우에는 이용·제공단계에서 당해 회원에게 그 목적을 고지하고 동의를 받습니다. 다만, 관련 법령에 달리 정함이 있는 경우에는 예외로 합니다.
5. 회사는 제2항과 제3항에 의해 이용자의 동의를 받아야 하는 경우에는 개인정보관리 책임자의 신원(소속, 성명 및 전화번호, 기타 연락처), 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공 관련사항(제공받은 자, 제공목적 및 제공할 정보의 내용) 등 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 제22조제2항이 규정한 사항을 미리 명시하거나 고지해야 하며 이용자는 언제든지 이 동의를 철회할 수 있습니다.
6. 회원은 언제든지 회사가 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수 있으며 회사는 이에 대해 지체 없이 필요한 조치를 취할 의무를 집니다. 이용자가 오류의 정정을 요구한 경우에는 회사는 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다.
7. 회사는 개인정보 보호를 위하여 이용자의 개인정보를 취급하는 자를 최소한으로 제한합니다.
8. 회사는 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적 또는 제공받은 목적을 달성한 때에는 당해 개인정보를 지체 없이 파기합니다.
9. 회사는 개인정보의 수집·이용·제공에 관한 동의 란을 미리 선택한 것으로 설정해두지 않습니다. 또한 개인정보의 수집·이용·제공에 관한 이용자의 동의 거절 시 제한되는 서비스를 구체적으로 명시하고, 필수수집항목이 아닌 개인정보의 수집·이용·제공에 관한 이용자의 동의 거절을 이유로 회원가입 등 서비스 제공을 제한하거나 거절하지 않습니다.

### **제17조(저작권의 귀속 및 이용제한)**

1. 회사가 작성한 저작물에 대한 저작권 기타 지적재산권은 회사에 귀속합니다.
2. 회원은 서비스를 이용함으로써 얻은 정보 중 회사에게 지적재산권이 귀속된 정보를 회사의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.

### **제18조(양도 금지)**

회사와 회원은 상대방의 사전 서면에 대한 동의 없이는 본 약관상의 권리 또는 의무를 제3자에게 양도하거나 담보로 제공할 수 없습니다.

### **제 19조(면책)**

1. 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.
2. 회사는 회원의 귀책사유로 인한 서비스 이용의 장애에 대하여 책임을 지지 않습니다.
3. 회사는 회원 상호간 또는 회원과 제3자 간에 서비스를 매개로 발생한 분쟁에 대하여는 개입할 의무, 손해를 배상할 책임이 없습니다.
4. 회원이 제3자의 저작권을 침해하여 회사가 제3자로부터 소송을 당하거나 법적 책임을 부담하게 되는 경우 회원은 회사를 면책시키고 회사가 입은 손해를 배상할 의무를 부담합니다.

### **제20조(분쟁해결)**

1. 서비스 이용에 관하여 회사와 회원 간에 이견 또는 분쟁이 있는 경우, 양 당사자간의 합의에 의해 원만히 해결하여야 합니다.
2. 회사는 회원이 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여 피해보상처리기구를 설치, 운영합니다.
3. 회사는 회원으로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와 처리일정을 즉시 통보해 드립니다.
4. 회사와 회원 간에 발생한 분쟁과 관련하여 회원의 피해구제신청이 있는 경우에는 공정거래위원회 또는 시·도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.

### **제21조(재판권 및 준거법)**

1. 회사와 회원 간에 발생한 분쟁에 관한 소송은 서울중앙지방법원을 전속관할로 합니다.
2. 회사와 회원 간에 제기된 소송에는 한국법을 적용합니다.

### **부칙**

이 약관은 2020년 11월 20일부터 시행합니다.
`;

export const termsForStreamer = `

### 이용약관 (스트리머)

### 제1조 (목적)

본 약관은 ㈜팀스노우볼(이하 "회사" 또는 "팀스노우볼")에서 제공하는 서비스(이하 "서비스")의 이용과 관련하여, 회사와 회원 간에 서비스 이용에 관한 권리 및 의무와 책임사항, 기타 필요한 사항을 규정하는 것을 목적으로 합니다. 그리고 회사는 아래와 같은 서비스를 제공합니다.

1. [https://decakill.com/](https://decakill.com/)
2. 트위치 익스텐션 Decakill

### 제2조 (**용어의 정의)**

1. 서비스: 개인용 컴퓨터 (PC), TV, 휴대형 단말기, 전기통신설비 등을 포함한 각종 유무선 장치와 같이 구현되는 단말기와 상관없이 회사가 제공하는 서비스를 의미합니다.
2. 회원: 회사와 서비스 이용계약을 체결하고 회사가 제공하는 서비스를 이용하는 모든 사용자를 의미합니다.
3. 계정: 회원의 식별 및 서비스 이용을 위하여 회원이 선정하고 회사가 인증한 문자, 숫자 또는 특수문자의 조합으로 만들어진 식별자(이메일 주소 등)를 의미합니다.
4. 비밀번호: 회원의 개인 정보 보호 및 회원의 동일성 확인을 위해서 회원이 정한 문자, 숫자 또는 특수문자의 조합을 의미합니다.
5. 계정 정보: 계정, 비밀번호, 닉네임 등 회원이 회사에 제공한 일반정보 및 게임 전적 정보 등 생성정보를 통칭합니다.
6. 게시물: 회원이 서비스를 이용함에 있어 회원이 서비스에 게시한 문자, 문서, 그림, 음성, 동영상, 링크, 파일 혹은 이들의 조합으로 이루어진 정보 등 모든 정보나 자료를 의미합니다.
7. 가입신청자: 회사에 대하여 서비스 이용을 신청하였으나 아직 회사로부터 그 서비스 이용에 관하여 동의를 받지 못한 자를 의미합니다.
8. “후원금액” 이라 함은 불특정 다수의 시청자가 서비스를 이용하여 “트위치 비트” 를 충전 후 회원에게 “Decakill 서비스”를 통해 후원한 금액을 말합니다.
9. “서비스 수수료”라 함은 회사가 회원에게 서비스를 제공하는 대가를 말합니다.
10. “정산금액”이라 함은 회원에 대한 “후원금액”에서 서비스 수수료 및 결제대행사 수수료, 기타 세금 등을 차감한 후 회사가 회원에게 지급하는 금액을 말합니다.

### **제3조 (약관의 개정)**

1. 회사가 약관을 개정할 경우에는 적용 일자 및 개정 사유를 명시하여 현행 약관과 함께 서비스 초기화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다. 다만, 회원에게 불리하게 약관내용을 변경하는 경우에는 30일의 사전 유예기간을 두고 공지합니다.
2. 회원은 변경된 약관에 동의하지 않을 권리가 있으며, 적용일로부터 7일(회원에게 불리하게 약관내용을 변경하는 경우에는 30일) 이내에 변경된 약관에 대해 거절의 의사를 서면 또는 이메일을 통하여 명시적으로 표시하지 않았을 때에는 변경된 약관에 동의한 것으로 간주합니다.

### **제 4조(회사의 의무)**

1. 회사는 회원에게 서비스를 제공할 의무가 있습니다.
2. 회사는 회원에 대한 후원금액을 정산할 의무가 있습니다.
3. 회사는 회원의 개인정보를 보호할 의무가 있습니다.

### **제5조(서비스의 중단 및 종료)**

1. 회사는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신의 두절, 화재, 폭풍, 홍수, 지진, 사고, 전쟁, 반란, 폭동, 기타 민란, 전염병, 격리, 천재지변, 정부조치 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.
2. 회사는 서비스의 제공이 일시적으로 중단된 경우에는 사유 및 중단 기간 등을 회원에게 신속하게 공지합니다.
3. 사업종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로 서비스를 종료할 경우에는 회사는 제8조에 정한 방법으로 회원에게 통지합니다.

### **제6조(회원가입)**

1. 이용자는 회사가 정한 가입 양식에 따라 회원정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로서 회원가입을 신청합니다.
2. 회사는 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각 호에 해당하지 않는 한 회원으로 등록합니다.
    - 가입신청자가 이 약관 제7조 3항에 의하여 이전에 회원자격을 상실한 적이 있는 경우
    - 등록 내용에 허위, 기재누락, 오기가 있는 경우
    - 기타 회원으로 등록하는 것이 서비스 제공에 현저히 지장이 있다고 판단되는 경우
3. 회원가입계약의 성립 시기는 회사의 승낙이 회원에게 도달한 시점으로 합니다.
4. 회원은 회원가입 시 등록한 사항에 변경이 있는 경우, 상당한 기간 이내에 회원정보 수정 등의 방법으로 그 변경사항을 알려야 합니다.

### **제7조(회원 탈퇴 및 자격 상실 등)**

1. 회원은 회사에 언제든지 탈퇴를 요청할 수 있으며 회사는 즉시 회원탈퇴를 처리합니다.
2. 회원이 다음 각 호의 사유에 해당하는 경우, 회사는 회원자격을 제한 및 정지시킬 수 있습니다.
    - 가입 신청 시에 허위 내용을 등록한 경우
    - 회원이 본 약관에서 정한 의무사항을 위반하여 서비스의 정상적인 운영을 방해한 경우
    - 회원이 타인의 명의를 도용하여 회원가입을 한 경우
    - 타인의 명예를 훼손하거나 모욕하는 경우
    - 회원간 또는 회사와 회원 간에 분쟁이 발생하여 분쟁이 해결되지 않는 경우
    - 회원이 제3자의 지적재산권을 침해한 것으로 의심될 상당한 이유가 있는 경우
    - 서비스를 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우
3. 회사가 회원 자격을 제한 또는 정지시킨 후, 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 회사는 회원자격을 상실시킬 수 있습니다.
4. 회사가 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우 회원에게 이를 통지하고, 회원등록 말소 전에 최소한 7일 이상의 기간을 정하여 소명할 기회를 부여합니다.
5. 회원 탈퇴 시 남아있는 후원금액에 대한 정산은 탈퇴신청 한 월의 익익월 [1일]에 정산됩니다. 단, 회사가 진행하는 이벤트를 통해서 지급된 후원금액은 회원 탈퇴 시 정산 대상에 포함되지 않습니다.

### **제8조(회원에 대한 통지)**

1. 회사는 회원에 대한 통지를 하는 경우, 회원이 회사와 미리 약정하여 지정한 전자우편 주소 또는 SMS 등으로 할 수 있습니다.
2. 회사는 불특정다수 회원에 대한 통지의 경우 1주일이상 서비스 게시판 등에 게시하여 개별 통지에 갈음할 수 있습니다. 다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 개별통지를 합니다.
3. 회사가 상기와 같이 통지하였으나 회원이 통지된 내용을 확인하지 않음으로써 회원에게 발생한 불이익에 대해서 회사는 책임을 지지 않습니다.

### **제9조(개인정보 제공 동의)**

1. 회사가 제3자에게 회원의 개인정보를 제공할 필요가 있는 경우 1) 개인정보를 제공받는 자, 2)개인정보를 제공받는 자의 개인정보 이용목적, 3) 제공하는 개인정보의 항목, 4) 개인정보를 제공받는 자의 개인정보 보유 및 이용기간을 회원에게 알리고 동의를 받아야 합니다. (동의를 받은 사항이 변경되는 경우에도 같습니다.)
2. 회사가 제3자에게 회원의 개인정보를 취급할 수 있도록 업무를 위탁하는 경우에는 1) 개인정보 취급위탁을 받는 자, 2) 개인정보 취급위탁을 하는 업무의 내용을 구매자에게 알리고 동의를 받아야 합니다. (동의를 받은 사항이 변경되는 경우에도 같습니다.) 다만, 서비스제공에 관한 계약이행을 위해 필요하고 구매자의 편의증진과 관련된 경우에는 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」에서 정하고 있는 방법으로 개인정보 취급방침을 통해 알림으로써 고지절차와 동의절차를 거치지 않아도 됩니다.

### **제 10조(회원의 의무)**

1. 회원은 회원가입을 통해 이용신청을 할 경우 사실에 근거하여 작성하여야 합니다. 회원이 허위 또는 타인의 정보를 등록한 경우 회사에 대하여 일체의 권리를 주장할 수 없으며, 이로 인하여 발생하는 손해에 대해 회사는 책임지지 않습니다.
2. 아이디와 비밀번호에 관한 관리책임은 회원에게 있습니다.
3. 회원은 자신의 아이디 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.
4. 회원이 자신의 아이디 및 비밀번호를 도난당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 회사에 통보하고 회사의 안내가 있는 경우에는 그에 따라야 합니다.
5. 회원은 회사에 대해 수수료를 지급할 의무가 있습니다.
6. 회원은 다음 행위를 하여서는 안 됩니다.
    - 상품 판매를 목적으로 후원금을 받거나, 유도하는 경우
    - 후원한 사람에게 돌려주려는 목적으로 후원금을 받거나, 유도하는 경우
    - 제3자에게 지급할 목적으로 후원금을 받거나, 유도하는 경우
    - 기타 현행 법령상 위법 사유를 포함한 방송을 통하여 후원금을 받거나, 유도하는 경우
7. 전항의 사유가 발생하는 경우, 회사는 아래의 조치를 취할 수 있습니다.
    - 본 계약의 해지
    - 전 항의 사유로 발생한 모든 거래의 거래취소 및 결제금액 환불
    - 본 계약 정산 조항과 관계없이 정산금액 지급의 보류
    - 전 항의 사유로 인하여 회사에 손해 발생 시 회원에게 손해액 배상 청구 등 법적 조치
8. 회원은 1회 이상 후원 받은 이후 처음 도래하는 월말까지 회사가 제공하는 절차를 통하여 정산정보등록(정산계약)를 하여야 하며, 회사와의 협의 없이 유효한 정산 정보 제공이 이루어지지 않는 경우 회사는 일시적으로 회원의 서비스 사용을 중지할 수 있습니다. 회원이 정산 정보를 제공하지 않아 발생한 피해에 대하여 회사는 책임을 지지 않습니다.
9. 회사는 전항에서 중지된 회원으로부터 유효한 정보가 제공된 경우 서비스를 다시 재개할 수 있습니다.

### **제 11조(정산방법 및 서비스 수수료)**

1. 서비스 수수료는 [1]%로 합니다. 단, 회사가 진행하는 이벤트가 있을 경우에는 해당 이벤트에서 공시하는 서비스 수수료가 우선합니다.
2. 후원금액의 정산은 정산되지 않은 후원금액이 존재할 경우에 가능합니다.
3. 정산대상 기간은 매월 [1]일부터 [말]일까지로 하여 [1]개월 단위로 정산을 시행합니다. 다만, 회사 및 결제대행사의 사정에 의하여 정산 대상기간을 달리 정하여야 하는 경우에는 회사 및 결제기관의 정책에 따릅니다.
4. 회원은 1회 이상 후원 받은 이후 처음 도래하는 월말까지 회사가 제공하는 절차를 통하여 정산정보등록(정산계약)를 하여야 하며, 등록을 하지 않는 경우 정산을 진행할 수 없습니다.
5. 회원이 가입 후 처음 도래한 정산요청 가능일로부터 [60]개월내 정산계약을 하지 아니하였을 경우, 후원금액은 자동으로 소멸됩니다.
6. 회사와 회원이 각각 보유한 회원의 후원 내역이 상이한 경우, 회사와 회원은 상호 보유한 자료를 비교하여 정산 금액을 합의할 수 있고, 결제가 이루어진 결제대행사의 정보를 참고할 수 있습니다. 합의가 지연되는 경우에 회사는 양 당사자가 주장하는 정산금액 중 적은 금액을 기준으로 정산을 실행할 수 있습니다. 이 경우 합의 시까지 회사는 정산금액 지급 연체에 대한 책임을 면합니다.
7. 회사는 회원이 요청하는 경우 정산과 관련된 자료를 회원에게 제공해야 합니다.
8. 정산 대상기간에 발생한 정산금액의 지급기일은, 정산대상 월의 익익월 [1]일까지로 합니다. 단, 요청 서류 누락, 계좌번호 오기입 등 회원의 귀책사유로 정산금의 지급이 실패한 경우 해당 사유가 해소된 날의 익월 [1]일까지 지급합니다.
9. 회사는 정산금액의 지급기일에 정산금액을 회원이 지정한 계좌에 이체합니다. 정산 금액은 후원금액에서 서비스 수수료 및 결제대행사의 수수료, 소득세 등 세금을 공제한 금액으로 합니다.
10. 회원이 병역 근무 중인 경우, 병무청의 별도 요청이 있을 시 병역 기간 중 후원금액의 정산이 제한될 수 있습니다.
11. 회사는 도용 등 불법적인 사용에 의한 결제로 후원된 금액에 대해서는 그 내용을 전자우편, 전화 등의 방법으로 통보 후 회원의 정산금액에서 제외할 수 있습니다. 또, 불법적인 방법으로 취득 혹은 취득하였다고 의심되는 후원 금액의 경우 사전 안내 없이 후원 금액의 정산을 취소, 의심되는 상황에 대한 회원의 소명이 종료될 때까지 지급을 거부 또는 기지급된 정산금액을 회수할 수 있습니다.
12. 회원은 원활한 정산 처리를 위해 회사가 별도의 공지사항 혹은 이용안내로 고지한 방법을 통해 회원 명의의 신분증(또는 사업자등록증), 계좌정보 등 정산금 지급에 필요한 정보 기재 후 일정한 방법을 통해 본인인증을 진행하여 정산등록신청을 하여야 합니다. 해당 절차를 모두 완료하여 회사에서 승인처리를 완료한 경우 회원과 회사는 정산계약을 체결한 것으로 간주합니다.
13. 회원이 만 14세 미만이거나 한정치산자인 경우 법정대리인(부모 등)의 동의를 받아 정산계약을 할 수 있으며, "회사"가 지정 및 안내하는 방법에 따라 이용동의를 하여야 합니다.
14. 결제대행사의 수수료는 별도의 공지를 통해 고지하며, 결제대행사의 사정에 따라 조정될 수 있습니다.

### **제 12조(거래 취소 및 결제금액 환불 등)**

1. 회원에게 후원금을 지불한 시청자가 후원금의 취소 또는 환불을 요청할 경우, 회원이 직접 응대 및 처리하여야 합니다. 다만, 회사와 회원 사이에 별도 협의가 있거나, 관계법령 또는 정부기관의 명령이 있을 경우 회사가 처리할 수 있습니다.
2. 회원에게 후원금을 지불한 시청자가 회사에 직접 또는 결제기관 또는 관계기관 및 협회 등을 통하여 이의(시청자 본인이 아닌 제3자가 결제 정보 등을 도용하여 이루어지는 부정거래 관련 클레임 포함)을 제기하는 경우, 회사는 아래와 같은 조치를 취할 수 있습니다.
    - 이의 관련 결제내역 자료의 제공
    - 이의 관련 금액 상당액의 정산금액 지급 보류
    - 결제서비스 제공 일시 정지(제한) 또는 계약 해지
    - 이의와 관련한 결제의 거래취소 또는 결제금액 환불

### **제13조(개인정보보호)**

1. 회사는 회원의 개인정보 수집 시 서비스제공을 위하여 필요한 범위에서 최소한의 개인정보를 수집합니다.
2. 회사는 회원가입시 구매계약이행에 필요한 정보를 미리 수집하지 않습니다. 다만, 관련 법령상 의무이행을 위하여 구매계약 이전에 본인확인이 필요한 경우로서 최소한의 특정 개인정보를 수집하는 경우에는 그러하지 아니합니다.
3. 회사는 회원의 개인정보를 수집·이용하는 때에는 당해 회원에게 그 목적을 고지하고 동의를 받습니다.
4. 회사는 수집된 개인정보를 목적 외의 용도로 이용할 수 없으며, 새로운 이용목적이 발생한 경우 또는 제3자에게 제공하는 경우에는 이용·제공단계에서 당해 회원에게 그 목적을 고지하고 동의를 받습니다. 다만, 관련 법령에 달리 정함이 있는 경우에는 예외로 합니다.
5. 회사는 제2항과 제3항에 의해 이용자의 동의를 받아야 하는 경우에는 개인정보관리 책임자의 신원(소속, 성명 및 전화번호, 기타 연락처), 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공 관련사항(제공받은자, 제공목적 및 제공할 정보의 내용) 등 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 제22조제2항이 규정한 사항을 미리 명시하거나 고지해야 하며 이용자는 언제든지 이 동의를 철회할 수 있습니다.
6. 회원은 언제든지 회사가 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수 있으며 회사는 이에 대해 지체 없이 필요한 조치를 취할 의무를 집니다. 이용자가 오류의 정정을 요구한 경우에는 회사는 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다.
7. 회사는 개인정보 보호를 위하여 이용자의 개인정보를 취급하는 자를 최소한으로 제한합니다.
8. 회사는 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적 또는 제공받은 목적을 달성한 때에는 당해 개인정보를 지체 없이 파기합니다.
9. 회사는 개인정보의 수집·이용·제공에 관한 동의 란을 미리 선택한 것으로 설정해두지 않습니다. 또한 개인정보의 수집·이용·제공에 관한 이용자의 동의 거절 시 제한되는 서비스를 구체적으로 명시하고, 필수수집항목이 아닌 개인정보의 수집·이용·제공에 관한 이용자의 동의 거절을 이유로 회원가입 등 서비스 제공을 제한하거나 거절하지 않습니다.

### **제14조(저작권의 귀속 및 이용제한)**

1. 회사가 작성한 저작물에 대한 저작권 기타 지적재산권은 회사에 귀속합니다.
2. 회원은 서비스를 이용함으로써 얻은 정보 중 회사에게 지적재산권이 귀속된 정보를 회사의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.

### **제15조(양도 금지)**

회사와 회원은 상대방의 사전 서면에 대한 동의 없이는 본 약관상의 권리 또는 의무를 제3자에게 양도하거나 담보로 제공할 수 없습니다.

### **제 16조(면책)**

1. 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.
2. 회사는 회원의 귀책사유로 인한 서비스 이용의 장애에 대하여 책임을 지지 않습니다.
3. 회사는 회원 상호간 또는 회원과 제3자 간에 서비스를 매개로 발생한 분쟁에 대하여는 개입할 의무, 손해를 배상할 책임이 없습니다.
4. 회원이 제3자의 저작권을 침해하여 회사가 제3자로부터 소송을 당하거나 법적 책임을 부담하게 되는 경우 회원은 회사를 면책시키고 회사가 입은 손해를 배상할 의무를 부담합니다.

### **제17조(분쟁해결)**

1. 서비스 이용에 관하여 회사와 회원 간에 이견 또는 분쟁이 있는 경우, 양 당사자간의 합의에 의해 원만히 해결하여야 합니다.
2. 회사는 회원이 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여 피해보상처리기구를 설치, 운영합니다.
3. 회사는 회원으로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와 처리일정을 즉시 통보해 드립니다.
4. 회사와 회원 간에 발생한 분쟁과 관련하여 회원의 피해구제신청이 있는 경우에는 공정거래위원회 또는 시·도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.

### **제18조(재판권 및 준거법)**

1. 회사와 회원 간에 발생한 분쟁에 관한 소송은 서울중앙지방법원을 전속관할로 합니다.
2. 회사와 회원 간에 제기된 소송에는 한국법을 적용합니다.

### **부칙**

이 약관은 2021년 6월 29일부터 시행합니다.
`;
