import SeoMetaTag from '@components/SeoMetaTag';
import { Box, Stack, Typography } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { privacyPolicy } from '../../lib/termsAndConditions/privacyPolicy';

import PageTopGradient from '../../components/PageTopGradient';

const PrivacyPolicy = () => {
  return (
    <Box sx={{ textAlign: 'center', position: 'relative' }}>
      <SeoMetaTag
        title='Decakill | Privacy Policy'
        description='데카킬 개인정보 취급방침 페이지입니다.'
        keywords='데카킬 개인정보취급방침, 개인정보 처리방침, decakill privacy policy'
      />

      <Stack direction='column'>
        <PageTopGradient />
        {/* 로고 */}
        <Box mb={16}>
          <Typography mt={16} variant='h2' component='h1'>
            {/* {t('표제', { ns: 'rank' })} */}
            개인정보 취급방침
          </Typography>
        </Box>
        <Box sx={MDXSx}>
          <ReactMarkdown children={privacyPolicy} remarkPlugins={[remarkGfm]} />
        </Box>
      </Stack>
    </Box>
  );
};

export default PrivacyPolicy;

const MDXSx = {
  textAlign: 'left',
  width: '1000px',
  margin: '32px auto',
  bgcolor: 'background.modal',
  padding: '32px',
  borderRadius: 2,
  fontSize: 15,
  fontWeight: 'regular',
  color: 'text.primary',
  lineHeight: '24px',

  h2: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#fff',
    margin: '12px 0',
  },

  h3: {
    fontSize: 15,
    fontWeight: 'bold',
    color: '#fff',
    margin: '12px 0',
  },

  ul: {
    listStyle: 'disc',
    listStylePosition: 'inside',
    '& >li>ul': {
      paddingLeft: 5,
      listStyle: 'circle',
      listStylePosition: 'inside',
      '& >li>ul': {
        paddingLeft: 5,
        listStyle: 'square',
        listStylePosition: 'inside',
      },
    },
  },

  ol: {
    listStyle: 'decimal',
    listStylePosition: 'inside',
    '& >li>ol': {
      paddingLeft: 5,
      listStyle: 'circle',
      listStylePosition: 'inside',
      '& >li>ol': {
        paddingLeft: 5,
        listStyle: 'square',
        listStylePosition: 'inside',
      },
    },
  },

  table: {
    margin: '12px 0',
    width: '100%',
    '& >thead>tr>th': {
      padding: '12px 0',
      bgcolor: 'customMenuListColor.main',
      border: '1px solid #3e3e42',
    },
    '& >tbody>tr>td': {
      padding: '12px 0',
      border: '1px solid #3e3e42',
    },
  },
};
