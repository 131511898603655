import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import qs from 'qs';
import { liveAPI, testAPI } from '../../Pages/config';

// import { store } from '../../index';

const baseUrl = window.location.hostname === 'decakill.com' ? liveAPI : testAPI;

export interface IAxiosResponse<T> {
  status: {
    auth: string;
    description: string;
    errorMessage: null | string | undefined;
    error_code: number;
    message: string;
    status_code: number;
  };
}

export class ApiError {
  errorCode: number;
  errorMessage: string | null | undefined;
  description: string;
  constructor(errorCode: number, errorMessage: string | null | undefined, description: string) {
    this.errorCode = errorCode;
    this.errorMessage = errorMessage;
    this.description = description;
  }
}

export const axiosPost = axios.create({
  baseURL: baseUrl,
  method: 'POST',
  headers: { 'content-type': 'application/json', accept: 'application/json' },
  timeout: 20000,
});

export const axiosGet = axios.create({
  baseURL: baseUrl,
  method: 'GET',
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: 'repeat' });
  },
  timeout: 5000,
});

export const axiosPut = axios.create({
  baseURL: baseUrl,
  method: 'PUT',
  headers: { 'content-type': 'application/json', accept: 'application/json' },
  timeout: 20000,
});

export const axiosDelete = axios.create({
  baseURL: baseUrl,
  method: 'DELETE',
  headers: { 'content-type': 'application/json', accept: 'application/json' },
  timeout: 20000,
});

const handleGetReqeust = (config: AxiosRequestConfig) => {
  const _config = addHeader(config);
  return _config;
};

const addHeader = (config: AxiosRequestConfig) => {
  const user = sessionStorage.getItem('user');

  if (user && user !== 'undefined') {
    const { token } = JSON.parse(user);
    const _config = {
      ...config,
      headers: { Authorization: `Bearer ${token}` },
    };
    return _config;
  }

  return config;
};

const handlePostRequest = (config: AxiosRequestConfig) => {
  const _config = addUserData(config);
  return _config;
};

const addUserData = (config: AxiosRequestConfig) => {
  const user = sessionStorage.getItem('user');
  // console.log(user);

  if (user && user !== 'undefined') {
    const { token } = JSON.parse(user);
    const _config = {
      ...config,
      headers: { ...config.headers, Authorization: `Bearer ${token}` },
    };
    return _config;
  }

  return config;
};

const handleError = (error: any) => {
  return Promise.reject(error);
};

// 요청 인터셉터
axiosPost.interceptors.request.use(handlePostRequest);
axiosPut.interceptors.request.use(handlePostRequest);
axiosDelete.interceptors.request.use(handlePostRequest);
axiosGet.interceptors.request.use(handleGetReqeust);

// 응답 인터셉터
axiosPost.interceptors.response.use((res: AxiosResponse<IAxiosResponse<string[]>>) => {
  // http 기본응답
  if (res.status > 299 || res.status < 200) {
    console.error('서버에 호출이 들어가지 않았습니다. url을 확인하세요');
  }

  // 서버에서 호출하는 응답 schema
  if (Number(res.data.status.status_code) > 299 || Number(res.data.status.status_code) < 200) {
    const error = new Error(JSON.stringify(res.data.status));
    // console.log(`axiosErr:`, JSON.parse(error.message));
    return Promise.reject(JSON.parse(error.message));
  }

  return res;
}, handleError);

axiosPut.interceptors.response.use((res: AxiosResponse<IAxiosResponse<string[]>>) => {
  // http 기본응답
  if (res.status > 299 || res.status < 200) {
    console.error('서버에 호출이 들어가지 않았습니다. url을 확인하세요');
  }

  // 서버에서 호출하는 응답 schema
  if (Number(res.data.status.status_code) > 299 || Number(res.data.status.status_code) < 200) {
    const error = new Error(JSON.stringify(res.data.status));
    // console.log(`axiosErr:`, JSON.parse(error.message));
    return Promise.reject(JSON.parse(error.message));
  }

  return res;
}, handleError);

axiosDelete.interceptors.response.use((res: AxiosResponse<IAxiosResponse<string[]>>) => {
  // http 기본응답
  if (res.status > 299 || res.status < 200) {
    console.error('서버에 호출이 들어가지 않았습니다. url을 확인하세요');
  }

  // 서버에서 호출하는 응답 schema
  if (Number(res.data.status.status_code) > 299 || Number(res.data.status.status_code) < 200) {
    const error = new Error(JSON.stringify(res.data.status));
    // console.log(`axiosErr:`, JSON.parse(error.message));
    return Promise.reject(JSON.parse(error.message));
  }

  return res;
}, handleError);

axiosGet.interceptors.response.use((res: AxiosResponse<IAxiosResponse<string[]>>) => {
  // http 기본응답
  if (res.status > 299 || res.status < 200) {
    console.error('서버에 호출이 들어가지 않았습니다. url을 확인하세요');
  }

  // 서버에서 호출하는 응답 schema
  if (Number(res.data.status.status_code) > 299 || Number(res.data.status.status_code) < 200) {
    const error = new Error(JSON.stringify(res.data.status));
    // console.log(`axiosErr:`, JSON.parse(error.message));
    return Promise.reject(JSON.parse(error.message));
  }

  return res;
}, handleError);
