import React from 'react';
import Button from '@mui/material/Button';
import { Box, Container, Stack, Typography } from '@mui/material';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import PageTopGradient from '../../components/PageTopGradient';
import { useTranslation } from 'react-i18next';
import VideoPlayer from '../Manual/components/VideoPlayer';

const tabs = [
  // { title: 'E스포츠 보상', path: '/reward' },
  // { title: '스트리머 보상', path: '/reward/streamer' },
];

const video = {
  src: '/videos/reward/reward_subtitle_banner.mp4',
};

const Reward = () => {
  const { t } = useTranslation(['translation', 'common', 'reward']);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <Container maxWidth='lg' sx={{ textAlign: 'center', position: 'relative' }}>
      <Stack direction='column'>
        {/* <PageTopGradient /> */}
        {/* 로고 */}
        <Box mt={12} mb={1}>
          {/* <Typography mt={16} variant='h2' component='h1'> */}
          {/* {t('표제', { ns: 'reward' })} */}
          {/* </Typography> */}
          <VideoPlayer video={video} muted playing loop pip />
        </Box>
        {/* 탭 */}
        {/* <Box>
          {tabs.map((tab) => {
            const active = pathname === tab.path;
            return (
              <Button
                key={`tab ${tab.title}`}
                variant={active ? 'contained' : 'outlined'}
                sx={{
                  py: 3,
                  px: 6,
                  borderRadius: 2,
                  fontSize: 18,
                  bgcolor: active ? (tab.title === 'E스포츠 보상' ? 'primary.dark' : 'secondary.dark') : '',
                  borderColor: active ? 'none' : 'customBorderColor.light',
                  color: active ? '#fff' : 'text.primary',
                  ml: 2,
                }}
                onClick={() => navigate(tab.path)}
              >
                {tab.title}
              </Button>
            );
          })}
        </Box> */}

        {/* 컨텐츠 */}
        <Box>
          <Outlet />
        </Box>
      </Stack>
    </Container>
  );
};

export default Reward;
