import React, { useState, useRef, useEffect } from 'react';
import { css } from '@emotion/css';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Checkbox, FormControlLabel, styled, Typography } from '@mui/material';
import { SxProps } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

interface Props {
  open: boolean;
  handleCancel: () => void;
  handleClose: (event?: any, reason?: any) => void;
  scroll: 'paper' | 'body' | undefined;
  title: string;
  subtitle?: string;
  content1: React.ReactNode;
  content2?: React.ReactNode;
  label1: string;
  label2?: string;
  label3?: string;
  sx?: SxProps;
  btn1: string;
  btn2: string;
}

export default function CustomLongTextModal({
  open,
  handleCancel,
  handleClose,
  scroll,
  title,
  subtitle,
  content1,
  content2,
  label1,
  label2,
  label3,
  btn1,
  btn2,

  sx,
}: Props) {
  const navigate = useNavigate();
  const [checked, setChecked] = useState([false, false]);

  const handleChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked([event.target.checked, event.target.checked]);
  };

  const handleChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked([event.target.checked, checked[1]]);
  };

  const handleChange3 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked([checked[0], event.target.checked]);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
        classes={{
          root: MUI_PAPER_CSS,
        }}
        sx={{
          ...sx,
        }}
      >
        {/* 최상단 타이틀 */}
        <DialogTitle id='scroll-dialog-title'>
          <Typography variant='h3' sx={{ color: 'white' }}>
            <img src='/images/logo/decakill.svg' alt='logo' style={{ marginRight: '12px' }} />
            {title}
          </Typography>
          <Typography variant='body2' sx={{ mt: 1 }}>
            {subtitle}
          </Typography>
        </DialogTitle>
        {/* 컨텐츠 1 */}
        <Box
          sx={{
            bgcolor: '#25252a',
            padding: '22px 22px 8px 22px',
            borderRadius: '8px',
            marginBottom: '16px',
          }}
        >
          <DialogContent>
            <DialogContentText id='scroll-dialog-description' tabIndex={-1}>
              {content1}
            </DialogContentText>
          </DialogContent>
          <FormControlLabel
            sx={{
              mt: 2,
              '& .MuiFormControlLabel-label': {
                fontSize: 15,
                color: 'text.primary',
                fontWeight: 'bold',
              },
            }}
            control={<Checkbox checked={checked[0]} onChange={handleChange2} />}
            label={label1}
          />
        </Box>
        {/* 컨텐츠 2 */}
        <Box
          sx={{
            bgcolor: '#25252a',
            padding: '22px 22px 8px 22px',
            borderRadius: '8px',
          }}
        >
          <DialogContent>
            <DialogContentText id='scroll-dialog-description' tabIndex={-1}>
              {content2}
            </DialogContentText>
          </DialogContent>
          <FormControlLabel
            sx={{
              mt: 2,
              '& .MuiFormControlLabel-label': {
                fontSize: 15,
                color: 'text.primary',
                fontWeight: 'bold',
              },
            }}
            control={<Checkbox checked={checked[1]} onChange={handleChange3} />}
            label={label2}
          />
        </Box>
        {/* 모두 동의 버튼 */}
        <FormControlLabel
          sx={{
            '& .MuiFormControlLabel-label': {
              fontSize: 15,
              color: 'text.primary',
              fontWeight: 'bold',
            },
          }}
          control={<Checkbox checked={checked[0] && checked[1]} onChange={handleChange1} />}
          label={label3}
        />
        {/* 버튼 */}
        <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', px: 0, py: 0 }}>
          <Button variant='contained' onClick={handleCancel} sx={{ bgcolor: 'customMenuListColor.main' }}>
            {btn1}
          </Button>
          <Button variant='contained' onClick={handleClose} disabled={!checked[0] || !checked[1]}>
            {btn2}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const MUI_PAPER_CSS = css`
  & .MuiPaper-root {
    overflow: hidden;
    background-color: #1c1c21 !important;
    padding: 32px;
    border-radius: 8px;
  }
  & .MuiDialogContentText-root {
    color: #9e9ea0;
    font-size: 15px;
  }

  & .MuiDialogTitle-root {
    text-align: center;
    margin-bottom: 12px;
  }

  & .MuiDialogContent-root {
    padding: 0;
    max-height: 160px;
    &::-webkit-scrollbar {
      width: 4px;
      background-color: transparent;
      border-radius: 9999px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
      border-radius: 9999px;
      margin: 0 4px;
    }

    &::-webkit-scrollbar-button {
      background-color: transparent;
    }

    &::-webkit-scrollbar-corner {
      background-color: transparent;
      border-radius: 9999px;
    }

    &::-webkit-scrollbar-thumb {
      border-top: 2px solid #3e3e42;
      border-bottom: 2px solid #3e3e42;
      border-right: 2px solid #3e3e42;
      border-left: 2px solid #3e3e42;
      background-clip: padding-box;
      border-radius: 9999px;
      background-color: #3e3e42;
    }
  }
`;
