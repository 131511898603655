import LockIcon from '@mui/icons-material/Lock';
import { LoadingButton } from '@mui/lab';
import { Avatar, Box, Button, Stack, Typography } from '@mui/material';
import { FocusEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import CustomModalRenewal from '@components/CustomModalRenewal';
import { InfoHelperText } from '@components/HelperText';
import { InfoTextField } from '@components/TextField';
import { AuthTimer } from '@components/Timer';
import CustomModal from '../../../components/CustomModal';
import CountrySelect from '../components/CountrySelect';

import { userAtom, userProfileAtom } from '../../../recoil/Auth/userAtom';
import MyPageServices, { emailData, FormValues, inputData } from '../../../services/MyPageServices';

const tab1 = [{ title: 'SNS연동' }];
const tab2 = [{ title: '개인정보' }];

const validation = {
  lastName: {
    required: '필수 입력 항목입니다.',
    maxLength: {
      value: 30,
      message: '30자 이하로 입력해주세요.',
    },
  },
  firstName: {
    required: '필수 입력 항목입니다.',
    maxLength: {
      value: 30,
      message: '30자 이하로 입력해주세요.',
    },
  },
  postalCode: {
    required: '필수 입력 항목입니다.',
    maxLength: {
      value: 11,
      message: '11자 이하로 입력해주세요.',
    },
  },
  province: {
    required: '필수 입력 항목입니다.',
    maxLength: {
      value: 50,
      message: '50자 이하로 입력해주세요.',
    },
  },
  city: {
    required: '필수 입력 항목입니다.',
    maxLength: {
      value: 50,
      message: '50자 이하로 입력해주세요.',
    },
  },
  address: {
    required: '필수 입력 항목입니다.',
    maxLength: {
      value: 50,
      message: '50자 이하로 입력해주세요.',
    },
  },
  addressDetail: {
    required: '필수 입력 항목입니다.',
    maxLength: {
      value: 100,
      message: '100자 이하로 입력해주세요.',
    },
  },
  phoneCode: {
    required: '필수 입력 항목입니다.',
    maxLength: {
      value: 15,
      message: '15자 이하로 입력해주세요.',
    },
  },
  phone: {
    required: '필수 입력 항목입니다.',
    minLength: {
      value: 6,
      message: '6자 이상 입력해주세요.',
    },
    maxLength: {
      value: 50,
      message: '50자 이하로 입력해주세요.',
    },
  },
  email: {
    required: '필수 입력 항목입니다.',
    pattern: {
      value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
      message: '이메일 형식이 유효하지 않습니다.',
    },
  },
  code: {
    required: '필수 입력 항목입니다.',
    minLength: {
      value: 6,
      message: '인증코드가 제대로 입력되지 않았습니다.',
    },
  },
};

const defaultValues = {
  firstName: '',
  lastName: '',
  phoneCode: '',
  phone: '',
  postalCode: '',
  province: '',
  city: '',
  address: '',
  region: '',
  addressDetail: '',
  email: '',
  code: '',
} as const;

const MyInfo = () => {
  const { t } = useTranslation(['translation', 'mypage', 'common', 'popup']);
  const userProfile = useRecoilValue(userProfileAtom);
  const user = useRecoilValue(userAtom);
  const [isResetEmail, setIsResetEmail] = useState(false);
  const [isAuthFinishedUser, setIsAuthFinishedUser] = useState<boolean>();

  const {
    handleSubmit,
    control,
    setValue,
    reset: resetForm,
    getValues,
    getFieldState,
    formState,
  } = useForm<FormValues>({
    defaultValues,
  });

  const handleEmailModalOpen = () => setIsResetEmail(true);
  const handleEmailModalClose = () => {
    setIsResetEmail(false);
  };
  const handleClick = (e: FocusEvent<HTMLInputElement>) => {
    if (!isAuthFinishedUser) return;
    handleEmailModalOpen();
    e.target.blur();
  };

  const resetEmailAuth = () => {
    setIsResetEmail(false);
    setIsAuthFinishedUser(false);
    confirmedCodeReset();
    authRequestReset();
    setValue('email', '');
    setValue('code', '');
  };

  const handleClose = () => {
    reset();
  };

  const onSubmit = (data: any, e: any) => {
    e.preventDefault();
    if ((!getValues().code && !isAuthFinishedUser) || (authResult && !confirmCodeData?.confirm.check)) {
      alert('이메일 인증이 완료되지 않았습니다. 인증을 진행해주세요.');
      return;
    }
    submitInfoMutate({
      uid: user?.uid,
      ...data,
    });
  };

  // 개인정보 등록, 수정
  const {
    mutate: submitInfoMutate,
    isLoading: isSavedLoading,
    isError,
    error,
    reset,
    isSuccess,
  } = useMutation((data: FormValues) => MyPageServices.submitMyInfo(data));

  // 인증번호 요청
  const {
    mutate: authByEmail,
    data: authResult,
    isLoading: isAuthRequestLoading,
    isSuccess: isAuthRequestedSuccess,
    reset: authRequestReset,
    status: authRequeustStatus,
  } = useMutation((data: emailData) => MyPageServices.authByEmail(data), {
    onSuccess: (res) => {
      if (res.send) {
        alert(
          '해당 이메일로 인증번호가 발송되었습니다. (메일을 받지 못하신 경우 스팸메일함을 확인하시거나 이메일이 정확한지 확인해주세요.)',
        );
      }
    },
  });

  // 인증번호 확인
  const {
    mutate: confirmCodeMutate,
    data: confirmCodeData,
    isSuccess: isConfirmCodeSuccess,
    isError: isConfirmCodeError,
    error: confirmCodeError,
    reset: confirmedCodeReset,
  } = useMutation((data: inputData) => MyPageServices.confirmEmailCode(data), {
    onSuccess: (res) => {
      const success = res.confirm.check;

      if (success) {
        alert('이메일 인증이 완료되었습니다.');
        setIsAuthFinishedUser(true);
        submitInfoMutate({
          uid: user?.uid,
          ...getValues(),
        });
      } else {
        alert('인증번호가 다릅니다. 정확한 인증번호를 입력해주세요.');
      }
    },
  });

  // 첫 진입 시 기존 데이터 표기
  useEffect(() => {
    if (user) {
      MyPageServices.getInfo({
        uid: user?.uid,
        regdate: user?.regdate,
      })
        .then((data) => {
          const {
            region,
            lastName,
            firstName,
            address,
            addressDetail,
            city,
            phone,
            phoneCode,
            postalCode,
            province,
            email,
          } = data.myInfoData[0];
          [
            { name: 'region', value: region },
            { name: 'lastName', value: lastName },
            { name: 'firstName', value: firstName },
            { name: 'address', value: address },
            { name: 'addressDetail', value: addressDetail },
            { name: 'city', value: city },
            { name: 'phone', value: phone },
            { name: 'phoneCode', value: phoneCode },
            { name: 'postalCode', value: postalCode },
            { name: 'province', value: province },
            { name: 'email', value: email },
            { name: 'code', value: '' },
          ].forEach(({ name, value }) => setValue(name as keyof FormValues, value, { shouldDirty: true }));
          return data;
        })
        .then((data) =>
          data?.myInfoData[0]?.email ? setIsAuthFinishedUser(true) : setIsAuthFinishedUser(false),
        );
    }
  }, []);

  return (
    <Box sx={myInfoContainerSxProps}>
      {/* 탭 */}
      <Box>
        {tab1.map((tab) => {
          return (
            <Box borderBottom={1} sx={tabSxProps}>
              <span style={tabBorderStyling}>{t(`나의정보.${tab.title}`, { ns: 'mypage' })}</span>
            </Box>
          );
        })}
        <Box sx={{ display: 'flex', py: 7, alignItems: 'center' }}>
          <Box sx={{ width: 60, height: 60, mr: 2 }}>
            <Avatar
              variant='square'
              sx={{ width: '100%', height: '100%', borderRadius: 2 }}
              src={userProfile?.profile_image_url}
            />
          </Box>
          <Box>
            <Typography variant='h6'>{userProfile?.display_name}</Typography>
          </Box>
        </Box>
      </Box>
      <Box>
        {tab2.map((tab) => {
          return (
            <Box borderBottom={1} sx={tabSxProps}>
              <span style={tabBorderStyling}>{t(`나의정보.${tab.title}`, { ns: 'mypage' })}</span>
            </Box>
          );
        })}
        {/* 입력 필드 */}
        <Stack mt={6} component='form' onSubmit={handleSubmit(onSubmit)}>
          {/* 국가 선택 */}
          <CountrySelect control={control} />
          {formState.errors.region && (
            <InfoHelperText> {t(`나의정보.미입력에러`, { ns: 'mypage' })}</InfoHelperText>
          )}
          {/* 성, 이름 */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
            <Box sx={{ width: 473 }}>
              <InfoTextField
                type='text'
                id='lastName'
                label={`${t(`나의정보.성`, { ns: 'mypage' })} * `}
                sx={textTypeSxProps}
                rules={validation.lastName}
                control={control}
                name='lastName'
                errors={formState.errors.lastName}
                inputProps={{ readOnly: false }}
              />
            </Box>
            <Box sx={{ width: 473 }}>
              <InfoTextField
                type='text'
                id='firstName'
                label={`${t(`나의정보.이름`, { ns: 'mypage' })} * `}
                sx={textTypeSxProps}
                rules={validation.firstName}
                control={control}
                name='firstName'
                errors={formState.errors.firstName}
              />
            </Box>
          </Box>
          {/* 우편번호 */}
          <Box sx={{ width: 473 }}>
            <InfoTextField
              type='number'
              id='postalCode'
              label={`${t(`나의정보.우편번호`, { ns: 'mypage' })} * `}
              sx={numberTypeSxProps}
              rules={validation.postalCode}
              control={control}
              name='postalCode'
              errors={formState.errors.postalCode}
            />
          </Box>
          {/* 도/시, 시/군/구 */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
            <Box sx={{ width: 473 }}>
              <InfoTextField
                type='text'
                id='province'
                label={`${t(`나의정보.도/시`, { ns: 'mypage' })} * `}
                sx={textTypeSxProps}
                rules={validation.province}
                control={control}
                name='province'
                errors={formState.errors.province}
              />
            </Box>
            <Box sx={{ width: 473 }}>
              <InfoTextField
                type='text'
                id='city'
                label={`${t(`나의정보.시/군/구`, { ns: 'mypage' })} * `}
                sx={textTypeSxProps}
                rules={validation.city}
                control={control}
                name='city'
                errors={formState.errors.city}
              />
            </Box>
          </Box>
          {/* 주소 */}
          <Box sx={{ width: 966, mt: 4 }}>
            <InfoTextField
              type='text'
              id='address'
              label={`${t(`나의정보.주소`, { ns: 'mypage' })} * `}
              sx={textTypeSxProps}
              rules={validation.address}
              control={control}
              name='address'
              errors={formState.errors.address}
            />
          </Box>
          {/* 상세주소 */}
          <Box sx={{ width: 966 }}>
            <InfoTextField
              type='text'
              id='addressDetail'
              label={`${t(`나의정보.상세주소`, { ns: 'mypage' })} * `}
              rules={validation.addressDetail}
              control={control}
              name='addressDetail'
              errors={formState.errors.addressDetail}
              sx={{
                ...textTypeSxProps,
                mt: 4,
              }}
            />
          </Box>
          {/* 국가번호, 전화번호 */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ width: 473 }}>
              <InfoTextField
                type='number'
                id='phoneCode'
                label={`${t(`나의정보.국가번호`, { ns: 'mypage' })} * `}
                sx={numberTypeSxProps}
                rules={validation.phoneCode}
                control={control}
                name='phoneCode'
                errors={formState.errors.phoneCode}
              />
            </Box>
            <Box sx={{ width: 473 }}>
              <InfoTextField
                type='number'
                id='phone'
                label={`${t(`나의정보.전화번호`, { ns: 'mypage' })} * `}
                sx={numberTypeSxProps}
                rules={validation.phone}
                control={control}
                name='phone'
                errors={formState.errors.phone}
                inputProps={{ maxLength: 50 }}
              />
            </Box>
          </Box>
          {/* 이메일 */}
          <Box sx={{ display: 'flex', alignItems: 'flex-start', mt: 4 }}>
            <Box sx={{ width: 473, mr: 4 }}>
              <InfoTextField
                type='text'
                id='email'
                label={`${t(`나의정보.이메일`, { ns: 'mypage' })} * `}
                sx={textTypeSxProps}
                rules={validation.email}
                control={control}
                name='email'
                errors={formState.errors.email}
                onFocus={handleClick}
                inputProps={{
                  readOnly: isAuthFinishedUser,
                }}
              />
            </Box>

            {isAuthFinishedUser ? (
              <Button
                startIcon={<LockIcon />}
                disabled
                variant='contained'
                sx={{
                  ...authBtnSxProps,
                  color: '#666666',
                }}
              >
                인증 완료
              </Button>
            ) : (
              <LoadingButton
                loading={isAuthRequestLoading}
                disabled={!!formState.errors.email}
                variant='contained'
                sx={{
                  ...authBtnSxProps,
                  color: !formState.errors.email ? '#fff' : '#666666',
                  '& .MuiLoadingButton-loadingIndicator': {
                    color: '#fff',
                  },
                  '&:hover': {
                    bgcolor: 'rgba(255, 255, 255, 0.1)',
                  },
                }}
                onClick={() => {
                  if (getValues().code) {
                    setValue('code', '');
                  }
                  authByEmail({ email: getValues().email });
                }}
              >
                인증번호 요청
              </LoadingButton>
            )}
          </Box>
          {/* 인증코드 확인 */}
          {!isAuthFinishedUser && isAuthRequestedSuccess && (
            <>
              <Box sx={{ display: 'flex', alignItems: 'flex-start', mt: 4 }}>
                <Box sx={{ width: 473, mr: 4 }}>
                  <InfoTextField
                    type='text'
                    id='code'
                    label={`${t(`나의정보.인증코드`, { ns: 'mypage' })} * `}
                    sx={textTypeSxProps}
                    rules={validation.code}
                    control={control}
                    name='code'
                    errors={formState.errors.code}
                  />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Button
                    sx={{
                      ...authBtnSxProps,
                      color: !formState.errors.code ? '#fff' : '#666666',
                      pointerEvents: !formState.errors.code ? 'all' : 'none',
                      mr: 2,
                    }}
                    onClick={() =>
                      confirmCodeMutate({
                        email: getValues().email,
                        inputNum: getValues().code,
                      })
                    }
                  >
                    인증하기
                  </Button>
                  {/* 타이머 */}
                  <AuthTimer
                    mm={10}
                    ss={0}
                    reset={() => {
                      alert('인증 시간이 만료되어 인증에 실패했습니다.');
                      confirmedCodeReset();
                      authRequestReset();
                    }}
                  />
                </Box>
              </Box>
            </>
          )}

          {/* 저장 */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 10 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <LoadingButton
                type='submit'
                autoFocus
                loading={isSavedLoading}
                variant='contained'
                sx={{
                  '&.Mui-disabled': {
                    bgcolor: 'primary.main',
                  },
                }}
              >
                {t(`저장`, { ns: 'common' })}
              </LoadingButton>
            </Box>
            {/* 탈퇴하기 */}
            <Button>
              <Link to='/my-page/delete-account'>
                <Typography variant='body2' component='span' sx={{ color: 'text.secondary' }}>
                  {t(`탈퇴하기`, { ns: 'mypage' })}
                </Typography>
              </Link>
            </Button>
          </Box>
        </Stack>
      </Box>

      {/*  에러 / 성공 모달  */}
      {isError && (
        <CustomModal
          title={t('알림', { ns: 'common' })}
          subTitle={t((error as any)?.error_code, { ns: 'error' })}
          btn1={t('확인', { ns: 'common' })}
          open={isError}
          handleClose={handleClose}
          sx={modalSxProps}
        />
      )}
      {isSuccess && (
        <CustomModal
          title={t('알림', { ns: 'common' })}
          subTitle={t('나의정보.저장알림', { ns: 'mypage' })}
          btn1={t('확인', { ns: 'common' })}
          open={isSuccess}
          handleClose={handleClose}
          sx={modalSxProps}
        />
      )}
      {/* 이메일 변경 시 팝업  */}
      {isResetEmail && (
        <CustomModalRenewal
          title={t('알림', { ns: 'common' })}
          btn1={t('취소', { ns: 'common' })}
          btn2={t('내정보팝업.이메일변경버튼', { ns: 'popup' })}
          open={isResetEmail}
          handleClose={handleEmailModalClose}
          handleConfirm={resetEmailAuth}
          sx={{
            '& .MuiPaper-root': {
              padding: '16px 32px 32px 32px',
              bgcolor: 'background.modal',
            },
          }}
        >
          <Typography m={8} variant='body2'>
            {t('내정보팝업.본문1', { ns: 'popup' })}
            <br /> {t('내정보팝업.본문2', { ns: 'popup' })}
          </Typography>
        </CustomModalRenewal>
      )}
    </Box>
  );
};

export default MyInfo;

const myInfoContainerSxProps = {
  borderRadius: 2,
  bgcolor: 'background.modal',
  padding: '32px',
  mb: 4,
};

const textTypeSxProps = {
  width: '100%',
  bgcolor: 'customMenuListColor.main',
  borderRadius: 2,
};

const numberTypeSxProps = {
  mt: 4,
  width: '100%',
  bgcolor: 'customMenuListColor.main',
  borderRadius: 2,
  '& input[type=number]::-webkit-outer-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
  '& input[type=number]::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
};

const modalSxProps = {
  textAlign: 'center',
  '& .MuiPaper-root': {
    bgcolor: 'background.modal',
    padding: '10px 48px',
    borderRadius: 2,
  },
};

const authBtnSxProps = {
  bgcolor: 'customMenuListColor.main',
  py: 4,
  px: 6,
  borderRadius: 2,
};

const tabSxProps = {
  color: 'white',
  fontWeight: 'bold',
  borderBottomColor: 'customMenuListColor.light',
  pb: 4,
};

const tabBorderStyling = {
  borderBottom: '4px solid #af51ff',
  paddingBottom: '10px',
};
