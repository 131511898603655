/** @jsxImportSource @emotion/react */
import { Link, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

// swiper - cra 프로젝트에서는 최신 버전 아닌 6.8.4 버전으로 설치 필요
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import SeoMetaTag from '@components/SeoMetaTag';
import HomeServices from '@services/HomeServices';
import { useRef } from 'react';
import { useQuery } from 'react-query';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

const SLIDE_IMAGES = [
  {
    src: '/images/main/img-main-ns.png',
    title: 'NS Redforce',
    members: [
      {
        name: 'Canna',
        twitchUrl: 'canna',
      },
      {
        name: 'Dread',
        twitchUrl: 'dread_jh',
      },
      {
        name: 'Bdd',
        twitchUrl: 'lol_bdd_990301',
      },
      {
        name: 'Ghost',
        twitchUrl: 'wkddydwns0318',
      },
      {
        name: 'Effort',
        twitchUrl: 'effort',
      },
      {
        name: 'Snowflower',
        twitchUrl: 'lol_snowflower',
      },
    ],
  },

  {
    src: '/images/main/img-main-soondangmu.png',
    title: '순당무',
    members: [
      {
        name: 'Soondangmu',
        twitchUrl: 'liok0485',
      },
    ],
  },
  {
    src: '/images/main/img-main-ambition.png',
    title: '엠비션',
    members: [
      {
        name: 'Ambition',
        twitchUrl: 'lol_ambition',
      },
    ],
  },
];

const arr = [
  ['canna', 'dread_jh', 'lol_bdd_990301', 'wkddydwns0318', 'effort', 'lol_snowflower'],
  'liok0485',
  'lol_ambition',
];

const getRandomPlayer = () => {
  return arr[0][Math.floor(Math.random() * arr[0].length)];
};

SwiperCore.use([Navigation, Autoplay, Pagination]);

function Home() {
  const { t } = useTranslation(['translation', 'main']);
  const prevNavRef = useRef(null);
  const nextNavRef = useRef(null);

  const StyledOnAir = styled('div')({
    '@keyframes bounce': {
      '0%': {
        transform: 'translateY(0)',
      },
      '100%': {
        transform: 'translateY(-5px)',
      },
    },
    animation: 'bounce 0.5s infinite alternate',
    width: '54px',
    mb: 3,
  });

  const { data } = useQuery(['liveStreamer'], () => HomeServices.getLiveStreamer(), {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const DATA = data?.streamerList ?? [];
  // const DATA = MOCK_LIVE_DATA;

  return (
    <Container
      maxWidth='lg'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        '&::before': backgroundStyling,
      }}
    >
      <SeoMetaTag
        title='Decakill | Home'
        description='데카킬과 함께 방송을 더 짜릿하게!'
        keywords='decakill, 데카킬, 데카킬 트위치 익스텐션, 데카킬 랜딩페이지 '
      />
      <Typography variant='h1' sx={{ marginTop: '104px' }}>
        {t('표제', { ns: 'main' })}
      </Typography>
      <Typography variant='body1' sx={{ textAlign: 'center', marginTop: '16px' }}>
        {t('부제', { ns: 'main' })}
      </Typography>

      {/* <Box sx={{ mb: 20 }}>
        <Button
          component={Link}
          variant='contained'
          href={`https://id.twitch.tv/oauth2/authorize?client_id=${TWITCH_CLIENT_ID}&redirect_uri=${window.location.origin}&response_type=code`}
          sx={{
            padding: '12px 48px',
            backgroundImage: 'linear-gradient(100deg, #af51ff, #01fcb5);',
            marginTop: '36px',
            borderRadius: 2,
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Avatar
            src='/images/icon/ico-twitch.png'
            alt='twitchIcon'
            varint='square'
            sx={{
              width: '20px',
              height: '20px',
              mr: 2,
            }}
          />
          <Typography variant='h6'>Play Free with Twitch</Typography>
        </Button>
      </Box> */}
      <Box sx={swiperStyling}>
        <Swiper
          slidesPerView={1}
          loop
          modules={[Autoplay, Navigation, Pagination]}
          className='mySwiper'
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={{
            prevEl: prevNavRef.current,
            nextEl: nextNavRef.current,
          }}
        >
          {/* 농심 */}
          <SwiperSlide style={{ height: '422px' }}>
            <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <img src='/images/main/img-main-ns.png' alt='slideImg' />
              <Box sx={boxStyling}>
                {DATA.length > 0 && DATA.some((item) => arr[0].includes(item.broadcaster_login)) && (
                  <Link
                    href={`https://www.twitch.tv/${getRandomPlayer()}`}
                    underline='none'
                    target='_blank'
                    rel='noopener'
                  >
                    <StyledOnAir>
                      <img src='/images/icon/ico-onair-arrow.svg' alt='onAir' width='100%' />
                    </StyledOnAir>
                  </Link>
                )}
                <Typography variant='h3' mb={3}>
                  NS Redforce
                </Typography>
                <Box sx={linkContentsStyling}>
                  {SLIDE_IMAGES[0].members.map((member) => {
                    return (
                      <>
                        <Link
                          href={`https://www.twitch.tv/${member.twitchUrl}`}
                          underline='none'
                          target='_blank'
                          rel='noopener'
                          style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                        >
                          <Box sx={{ display: 'flex' }}>
                            <Typography variant='body2' sx={{ color: '#fff' }}>
                              {member.name}
                            </Typography>
                            <img src='/images/icon/ico-twitch.svg' alt='twitchIcon' width='100%' />
                          </Box>
                        </Link>
                        <span style={{ margin: '0 16px' }}>|</span>
                      </>
                    );
                  })}
                </Box>
                <Typography variant='body2' sx={{ mt: 2 }}>
                  농심 선수들의 채널에 방문해 데카킬을 즐겨보세요.
                </Typography>
              </Box>
            </Box>
          </SwiperSlide>

          {/* 순당무 */}
          <SwiperSlide style={{ height: '422px' }}>
            <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <img src='/images/main/img-main-soondangmu.png' alt='slideImg' />
              <Box sx={boxStyling}>
                {DATA.length > 0 && DATA.some((item) => item.broadcaster_login === arr[1]) && (
                  <Link
                    href={`https://www.twitch.tv/${arr[1]}`}
                    underline='none'
                    target='_blank'
                    rel='noopener'
                  >
                    <StyledOnAir>
                      <img src='/images/icon/ico-onair-arrow.svg' alt='onAir' width='100%' />
                    </StyledOnAir>
                  </Link>
                )}

                <Box sx={linkContentsStyling}>
                  {SLIDE_IMAGES[1].members.map((member) => {
                    return (
                      <>
                        <Link
                          href={`https://www.twitch.tv/${member.twitchUrl}`}
                          underline='none'
                          target='_blank'
                          rel='noopener'
                          pt
                          style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                        >
                          <Box sx={{ display: 'flex' }}>
                            <Typography variant='h3' sx={{ color: '#fff' }}>
                              {member.name}
                            </Typography>
                            <img src='/images/icon/ico-twitch.svg' alt='twitchIcon' width='100%' />
                          </Box>
                        </Link>
                        <span style={{ margin: '0 16px' }}>|</span>
                      </>
                    );
                  })}
                </Box>
                <Typography variant='body2' sx={{ mt: 2 }}>
                  순당무의 채널에 방문해 데카킬을 즐겨보세요.
                </Typography>
              </Box>
            </Box>
          </SwiperSlide>

          {/* 앰비션 */}
          <SwiperSlide style={{ height: '422px' }}>
            <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <img src='/images/main/img-main-ambition.png' alt='slideImg' />
              <Box sx={boxStyling}>
                {DATA.length > 0 && DATA.some((item) => item.broadcaster_login === arr[2]) && (
                  <Link
                    href={`https://www.twitch.tv/${arr[2]}`}
                    underline='none'
                    target='_blank'
                    rel='noopener'
                  >
                    <StyledOnAir>
                      <img src='/images/icon/ico-onair-arrow.svg' alt='onAir' width='100%' />
                    </StyledOnAir>
                  </Link>
                )}

                <Box sx={linkContentsStyling}>
                  {SLIDE_IMAGES[2].members.map((member) => {
                    return (
                      <>
                        <Link
                          href={`https://www.twitch.tv/${member.twitchUrl}`}
                          underline='none'
                          target='_blank'
                          rel='noopener'
                          pt
                          style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                        >
                          <Box sx={{ display: 'flex' }}>
                            <Typography variant='h3' sx={{ color: '#fff' }}>
                              {member.name}
                            </Typography>
                            <img src='/images/icon/ico-twitch.svg' alt='twitchIcon' width='100%' />
                          </Box>
                        </Link>
                        <span style={{ margin: '0 16px' }}>|</span>
                      </>
                    );
                  })}
                </Box>
                <Typography variant='body2' sx={{ mt: 2 }}>
                  앰비션의 채널에 방문해 데카킬을 즐겨보세요.
                </Typography>
              </Box>
            </Box>
          </SwiperSlide>
        </Swiper>
      </Box>
    </Container>
  );
}

export default Home;

const linkContentsStyling = {
  display: 'flex',
  alignItems: 'center',
  '& >span': {
    color: 'text.secondary',
    ':last-of-type': {
      display: 'none',
    },
  },
};

const boxStyling = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'absolute',
  bottom: -30,
};

const swiperStyling = {
  marginBottom: '100px',
  position: 'relative',
  '& .swiper-wrapper': {
    width: '840px',
    marginBottom: '70px',
  },

  '& .swiper-slide': {
    img: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
  },

  '& .swiper-slide-active': {
    position: 'relative',
    zIndex: 99,
    width: '840px !important',
    height: 429,
  },

  '& .swiper-slide-next': {
    img: {
      maxHeight: 348,
    },
  },

  '& .swiper-slide-prev': {
    img: {
      maxHeight: 348,
    },
  },

  '.swiper-pagination-bullet': {
    background: '#3b3b40',
    width: 24,
    height: 4,
    borderRadius: '99px',
    opacity: '1',

    '&.swiper-pagination-bullet-active': {
      background: '#af51ff',
    },
  },
};
const backgroundStyling = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%,-50%)',
  width: '1532px',
  height: '722px',
  display: 'block',
  content: '""',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
  backgroundImage: `url(${process.env.PUBLIC_URL}/images/background/404-bg.png)`,
};
