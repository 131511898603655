export const streamerGuideVideo = {
  setting: [
    {
      src: '/images/manual/setting.png',
      title: 'setting',
      text: `이후 가입이 승인되면, 사용하실 수 있습니다.`,
    },
  ],
  login: [
    {
      src: '/videos/manual/streamer/login.mp4',
      title: 'sample',
      text: `가입 시 사용한 ID와 PW를 통해 로그인할 수 있습니다.`,
    },
    {
      src: '/videos/manual/streamer/code_login.mp4',
      title: 'sample',
      text: `가입 후 생성한 코드로 로그인할 수 있습니다.`,
    },
  ],
  presentQuestion: [
    {
      src: '/videos/manual/streamer/add_game.mp4',
      title: 'sample',
      text: `먼저 게임 추가를 눌러 게임을 생성해야합니다. \n이후 생성된 게임 페이지에 진입해주세요.`,
    },
    {
      src: '/videos/manual/streamer/add_game_quiz.mp4',
      title: 'sample',
      text: `문제 추가 후 출제 하고자 하는 문제 유형을 결정해주세요.\n예측      
       - 예측은 실시간 방송 상태에 따라 답이 달라질 수 있는 문제를 의미합니다.
       - 예측은 투표율에 따라 정답자의 보상 배당이 달라집니다.\n설문
       - 설문조사는 참여자들에게 설문조사를 출제하고 고정된 보상을 지급하는 문제입니다.
       - 조사하고 싶은 내용이 있을 때 사용할 수 있습니다.\n질문
       - 질문는 정답이 정해져있는 문제를 의미합니다.
       - 보상 역시 문제 출제할 때 고정 값을 추가하여 제공할 수 있습니다.`,
    },
    {
      src: '/videos/manual/streamer/write_quiz.mp4',
      title: 'sample',
      text: `문제 추가 시 아래 내용은 필수로 작성되어야 합니다.
      - 문제 제목 : 최소 1글자에서 최대 50자 사이로 입력 가능합니다.
      - 마감 시간 : 최소 10초 이상에서 최대 600초까지 입력 가능합니다.
      - 답안 개수 : 답안 개수를 선택, 입력할 예상 답안 리스트를 추가해야 합니다.
      - 답안 작성 : 추가한 리스트에 맞게 답안을 추가, 기입해야 합니다.
      \n문제 추가 시 아래 내용은 선택적으로 작성되어야 합니다. 
      - 문제 설명 : 해당문제에 대해 유저가 원할 때 안내용 문구가 출력됩니다.`,
    },
    {
      src: '/videos/manual/streamer/quiz_show.mp4',
      title: 'sample',
      text: `문제 출제 버튼을 눌러서 시청자에게 문제를 출제할 수 있습니다.`,
    },
  ],
  answer: [
    {
      src: '/videos/manual/streamer/quiz_answer.mp4',
      title: 'sample',
      text: `투표가 마감되면, 정답 처리 버튼이 활성화됩니다.\n예측 문제라면, 진행되는 방송을 보고 정답 상황이 나타났을 때 정답처리 버튼을 선택하며,\n해당 답안을 정답으로 유저들에게 전달할 수 있습니다.`,
    },
    {
      src: '/videos/manual/streamer/quiz_all_correct.mp4',
      title: 'sample',
      text: `설문조사인 경우 전체 정답 처리만 활성화 됩니다.\n전체 전답 처리를 통해 설문조사 참여자 모두에게 보상을 지급할 수 있습니다.`,
    },
  ],
  endOfQuestion: [
    {
      src: '/videos/manual/streamer/finish.mp4',
      title: 'sample',
      text: `오늘 Decakill 사용이 마무리되면 꼭 최종 정산 버튼을 눌러야 합니다.\n최종 정산 버튼을 눌러 게임을 마감시켜주면, 유저의 보상 내역이 실제로 반영되며 오늘 Decakill 게임의 결과가 저장됩니다.`,
    },
  ],
  result: [
    {
      src: '/videos/manual/streamer/cancel.mp4',
      title: 'sample',
      text: `만약, 문제를 잘못 출제했다면 긴급종료를 눌러 문제를 취소할 수 있습니다.\n- 문제를 취소할 경우, 해당 문제는 진행되지 않은 것으로 처리되며 참여한 기록 모두 초기화됩니다.
      \n만약, 문제 상황이 빠르게 발생해서 사람들이 예측하기 쉬워졌다면 조기종료를 눌러 timer를 0으로 만들 수 있습니다.\n- 해당 시간까지 참여한 유저의 기록만 저장되며 보상을 지급할 수 있습니다.`,
    },
  ],
  code: [
    {
      src: '/videos/manual/streamer/code_maker.mp4',
      title: 'sample',
      text: `좌측에 있는 코드생성 메뉴를 누르고 들어가면, 관리자 코드를 발급받을 수 있습니다.\n영구 코드의 경우 해당 코드로 여러번 문제 출제 사이트에 로그인할 수 있는 코드입니다.\n일회생 코드의 경우 한번만 접속한 코드로 사용했을 경우 다시 사용할 수 없는 코드입니다.\n코드 생성을 누를 경우 기존 코드는 접속을 금지 시킵니다.\n이후 새로 생성된 코드로만 로그인할 수 있도록 새로운 코드를 암호화하여 생성합니다.\n해당 코드를 통해 나와 함께 문제낼 사람들을 문제출제 사이트에 로그인할 수 있도록 만들 수 있습니다.
      `,
    },
  ],
};

export const audienceGuideVideo = {
  authConsent: [
    {
      src: '/videos/manual/audience/agreement.mp4',
      title: 'sample',
      text: `화면 우측에 있는 데카킬 아이콘을 클릭합니다.\n팝업창에서 사용 권한 동의 버튼을 클릭합니다.\n *사용권한 동의 후 데카킬을 사용할 수 있습니다.`,
    },
  ],
  participateQuiz: [
    {
      src: '/videos/manual/audience/quiz_join.mp4',
      title: 'sample',
      text: `문제창이 팝업되면, 답안을 선택합니다.\n정답을  맞추면, 킬포인트를 획득합니다.`,
    },
    {
      src: '/videos/manual/audience/quiz_answer_change.mp4',
      title: 'sample',
      text: `*문제 마감 시간 내에는 정답을 변경할 수 있습니다.`,
    },
  ],
  kindOfQuiz: [
    {
      src: '/images/manual/quiz_type_notice.png',
      title: 'quiz type',
      text: ``,
    },
  ],
  answer: [
    {
      src: '/videos/manual/audience/quiz_correct.mp4',
      title: 'sample',
      text: `정답을 맞추는 경우, 획득한 킬포인트의 영상이 나옵니다.`,
    },
    {
      src: '/videos/manual/audience/quiz_wrong.mp4',
      title: 'sample',
      text: `정답을 맞추지 못하는 경우, Death 영상이 나옵니다.\n문제에 참여하지 않은 경우, 아무런 영상도 나오지 않습니다.`,
    },
  ],
  confirmRank: [
    {
      src: '/videos/manual/audience/rank.mp4',
      title: 'sample',
      text: `해당 채널에서 현재 참여중인 유저들의 랭킹을 확인합니다.\n문제 참여 후 랭킹을 확인할 수 있습니다.`,
    },
    {
      src: '/images/manual/rank_policy.png',
      title: 'Rank Policy',
      text: '',
    },
  ],
  prediction: [
    {
      src: '/videos/manual/audience/record.mp4',
      title: 'sample',
      text: `내가 참여한 문제 내역을 확인합니다.\n정답/오답 여부, 획득한 킬포인트, 참여 중인 예측 등이 나옵니다.\n문제에 참여하지 않은 경우, <데이터가 없습니다>라는 안내 메세지가 나옵니다.`,
    },
  ],
  rivalRank: [
    {
      src: '/videos/manual/audience/rival_rank.mp4',
      title: 'sample',
      text: `라이벌 그룹을 생성한 경우, 라이벌 그룹 내 랭킹을 확인할 수 있습니다.`,
    },
  ],
  inviteRival: [
    {
      src: '/videos/manual/audience/rival_invite.mp4',
      title: 'sample',
      text: `라이벌 그룹이 없다면 유저를 검색하여 초대보낼 수 있습니다.`,
    },
    {
      src: '/videos/manual/audience/rival_join.mp4',
      title: 'sample',
      text: `누군가 내게 초대를 보냈다면 수락하여 라이벌 그룹에 들어갈 수 있습니다.`,
    },
  ],
  closeRival: [
    {
      src: '/videos/manual/audience/rival_exit.mp4',

      title: 'sample',
      text: `<나가기> 버튼을 클릭하여 현 라이벌 그룹에서 나옵니다.`,
    },
    {
      src: '/videos/manual/audience/rival_quit.mp4',

      title: 'sample',
      text: `만약 라이벌 그룹을 만든 유저라면 소속 유저를 선택하여 내보낼 수 있습니다.`,
    },
  ],
  setting: [
    {
      src: '/videos/manual/audience/setting.mp4',
      title: 'sample',
      text: `문제 자동 출력 기능을 설정합니다.\n이동시킨 문제창의 위치를 초기화할 수 있습니다.`,
    },
  ],
  guide: [
    {
      src: '/videos/manual/audience/guide.mp4',
      title: 'sample',
      text: `가이드 화면에서는 Decakill 의 약식 사용설명을 확인하실 수 있습니다.`,
    },
  ],
  killsAndGems: [
    {
      src: '/images/manual/kills_gems_policy.png',
      title: 'kills and Gems',
      text: '',
    },
  ],
};
