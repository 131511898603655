import { axiosGet, axiosPost } from './axios/axios';
import { CustomStatus } from './RankServices';

export interface getRewardList {
  gameIdx: number;
  leagueIdx: number;
}

interface Status {
  status_code: number;
  auth: string;
  description: string;
  message: string;
  error_code: number;
}

export interface RewardInfo {
  idx: number;
  name: string;
  descriptionUrl: string;
  imageUrl: string;
  dailyLimit: number;
  goodsValue: number;
  goodsRemainValue: number;
  rate: number;
  goodsState: number;
  costData: [
    {
      costTypeIdx: number;
      costTypeName: string;
      costValue: number;
    },
  ];
}

export interface RewardList {
  status: Status;
  scheduleData: {
    name: string;
    startDate: string;
    endDate: string;
    state: number;
  };
  rewardList: RewardInfo[];
}

interface Game {
  idx: number;
  key: string;
}

interface GameInfo {
  idx: number;
  key: string;
  league: Game[];
}

interface GameLeagueInfo {
  status: Status;
  game: GameInfo[];
}

export interface drawProps {
  uid: string;
  gemType: number;
  gem: number;
  rewardGoodsIdx: number;
  randomNumber: number;
  regdate: string;
}

interface DrawResult {
  status: Status;
  drawResult: {
    win: boolean;
    goodsState: number;
  };
}

export type BuyProps = Omit<drawProps, 'randomNumber'>;

interface buyResult {
  status: Status;
  buyResult: {
    buy: boolean;
    goodsState: number;
  };
}

export interface AuthCheck {
  uid: string;
  regdate: string;
  gemType: number;
  gem: number;
  rewardGoodsIdx: number;
}

interface AuthCheckRes {
  status: CustomStatus;
  validation: boolean;
  info: {
    gemType: number;
    userGem: number;
    goodsGem: number;
    goodsImage: string;
    goodsName: string;
    email: string;
  };
}

class RewardServices {
  getGameLeague = (): Promise<GameLeagueInfo> => {
    return new Promise((resolve, reject) => {
      axiosGet({
        url: '/game/list',
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  // 이스포츠 보상리스트
  getRewardList = (data: getRewardList): Promise<RewardList> => {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/reward',
        data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  // 스트리머 보상리스트
  getStreamerReward = (): Promise<RewardList> => {
    return new Promise((resolve, reject) => {
      axiosGet({
        url: '/reward/streamer',
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  // 응모
  draw = (data: drawProps): Promise<DrawResult> => {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/draw',
        data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  // 구매
  buy = (data: BuyProps): Promise<buyResult> => {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/buy',
        data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  // 이메일 인증 유저 여부 확인
  checkIfAuthUser = (data: AuthCheck): Promise<AuthCheckRes> => {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/check',
        data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export default new RewardServices();
