import { axiosDelete, axiosGet, axiosPost, axiosPut } from './axios/axios';
import { CustomStatus } from './RankServices';

interface getGemInfo {
  uid: string;
}

export interface getAllData {
  uid: string;
  channelId: string | null;
}

export interface getYearlyData {
  uid: string;
  channelId: string | null;
  year: number;
}

interface Status {
  auth: string;
  description: string;
  error_code: null | number;
  errorMessage: string;
  message: string;
  status_code: number;
}

export interface GemInfo {
  status: Status;
  gemData: {
    purpleGem: number;
    greenGem: number;
  };
}

interface AllDataAvailable {
  uid: string;
  channelId: string;
  totalGameCount: number;
  maxMultiKill: number;
  maxConsecutiveKill: number;
  sumKillCount: number;
  sumDeathCount: number;
  avgKillCount: number;
  avgDeathCount: number;
}

export interface AllData {
  status: Status;
  userAllGameData: AllDataAvailable | null;
}

interface YearlyDataAvailable {
  uid: string;
  channelId: string;
  totalGameCount: number;
  maxMultiKill: number;
  maxConsecutiveKill: number;
  sumKillCount: number;
  sumDeathCount: number;
}

export interface YearlyData {
  status: Status;
  userYearlyGameData: YearlyDataAvailable | null;
}

export interface FormValues {
  idx?: number;
  uid?: string;
  firstName: string;
  lastName: string;
  phoneCode: string;
  phone: string;
  region: string;
  postalCode: string;
  province: string;
  city: string;
  address: string;
  addressDetail: string;
  email: string;
  code: string;
}

interface SubmitResponse {
  status: Status;
  updateMyInfo: {
    idx: number;
    uid: string;
    firstName: string;
    lastName: string;
    email: string | null;
    phoneCode: string;
    phone: string;
    region: string;
    postalCode: string;
    province: string;
    city: string;
    address: string;
    addressDetail: string;
    del: number;
    regdate: string;
  };
}

export interface getInfo {
  uid: string;
  regdate: string;
}

interface GetInfoResponse {
  status: Status;
  myInfoData: [
    {
      idx: number;
      uid: string;
      firstName: string;
      lastName: string;
      email: string;
      phoneCode: string;
      phone: string;
      region: string;
      postalCode: string;
      province: string;
      city: string;
      address: string;
      addressDetail: string;
      del: number;
      regdate: string;
    },
  ];
}

export interface getTotalPoint {
  uid: string;
  regdate: string;
}

interface AllPointData {
  idx: number;
  channelType: number;
  broadcasterDisplayName: string;
  broadcasterProfileImageUrl: string;
  rewardGoodsName: string;
  rewardGoodsImageUrl: string;
  point: number;
  regdate: string;
}
interface GetTotalPointResponse {
  status: Status;
  allPointData: AllPointData[] | null;
}

export interface getDrawInfo {
  uid: string;
  regdate: string;
}
export interface AllDrawData {
  idx: number;
  rewardGoodsName: string;
  imageUrl: string;
  gemType: number;
  gem: number;
  state: number; //  1당첨 2당첨실패 3당첨취소 4구매 5환불
  stateDetail: number; // 0배송지입력 1배송신청 2배송중 3배송완료 4배송확정 5코드사용대기 6코드사용
  regdate: string;
}
export interface GetDrawResponse {
  status: Status;
  allDrawData: AllDrawData[] | null;
}

export interface deleteAccount {
  uid: string;
  regdate: string;
  cause1: number;
  cause2: string | null;
}

interface DeleteAccountResponse {
  status: Status;
  withdrawUser: {
    uid: string;
    cause1: number;
    cause2: string;
  };
}

interface reasonData {
  idx: number;
  title: string;
}

interface GetdeleteReason {
  status: Status;
  reasonData: reasonData[];
}

export interface exchangeReward {
  uid: string;
  recordIdx: number;
}
interface exchangeRewardResponse {
  status: Status;
  codeData: {
    codeNumber: string;
    goodsImageUrl: string;
    goodsName: string;
  };
}

export interface refund {
  uid: string;
  recordIdx: number | undefined;
}

export interface refundResponse {
  status: Status;
  refundResult: {
    refund: boolean;
  };
}

export interface shipping {
  uid: string;
}

interface addressInfo {
  province: string;
  city: string;
  address: string;
  addressDetail: string;
  postalCode: string;
  region: string;
}

export interface shippingResponse {
  status: Status;
  addressInfoData: addressInfo | null;
}

export interface errObj {
  auth: string;
  description: string;
  errorMessage: null;
  error_code: number;
  message: string;
  status_code: number;
}

export interface getTotalMyData {
  status: Status;
  userInfo: {
    uid: string;
    displayName: string;
    profileImageUrl: string;
  };
  userAllGameData: AllDataAvailable | null;
  userYearlyGameData: YearlyDataAvailable | null;
}

export interface shippingStatus {
  drawRecordIdx: number;
}

export interface UpdateResponse {
  status: Status;
  updateResult: {
    idx: number;
    stateDetail: number;
  };
}
interface fileType {
  origin: string;
  route: string;
}

export interface inquiryData {
  insertUserCSReq: {
    uid: string;
    email: string;
    serviceType: number;
    accountsType: number;
    title: string;
    content: string;
  };
  files: File[];
}

interface inquiryResponse {
  status: Status;
  insertCSResult: {
    idx: 0;
    userId: string;
    email: string;
    title: string;
    content: string;
    file: fileType[];
    regdate: string;
  };
}

export interface emailData {
  email: string;
}

interface authByEmailRes {
  status: CustomStatus;
  send: boolean;
  email: string;
}

export interface inputData {
  email: string;
  inputNum: string;
}

interface confirmEmailRes {
  status: CustomStatus;
  confirm: {
    check: boolean;
    email: string;
  };
}

export interface getPopupInfo extends getInfo {
  recordIdx?: number;
}

interface popUpInfoRes {
  status: CustomStatus;
  info: {
    deadline: string;
    goodsName: string;
    goodsUrl: string;
    email: string;
    goodsIdx?: number;
  };
}

interface resendCodeRes {
  status: CustomStatus;
  resend: boolean;
}

class MyPageServices {
  // 내 데이터
  getGemInfo = (data: getGemInfo): Promise<GemInfo> => {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/gem-info',
        data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  getAllData = (data: getAllData): Promise<AllData> => {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/mypage/my-data/streamer/all',
        data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  getYearlyData = (data: getYearlyData): Promise<YearlyData> => {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/mypage/my-data/streamer/yearly',
        data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  //  내 데이터 통합 API (22.08.04 추가) - 우선 마이페이지에서 테스트
  getMyTotalData = (data: getYearlyData): Promise<getTotalMyData> => {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/mypage/user-data',
        data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  // 내 정보 - 개인정보 등록 및 수정
  submitMyInfo = (data: FormValues): Promise<SubmitResponse> => {
    return new Promise((resolve, reject) => {
      axiosPut({
        url: '/mypage/my-info',
        data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  // 내 정보 - 개인정보 불러오기
  getInfo = (data: getInfo): Promise<GetInfoResponse> => {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/mypage/my-info',
        data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  // 내 정보 - 탈퇴 사유 불러오기
  getDeleteReason = (): Promise<GetdeleteReason> => {
    return new Promise((resolve, reject) => {
      axiosGet({
        url: '/mypage/my-info/cause',
      })
        .then((res) => {
          console.log(res);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  // 내 정보 - 탈퇴하기
  deleteAccount = (data: deleteAccount): Promise<DeleteAccountResponse> => {
    return new Promise((resolve, reject) => {
      axiosDelete({
        url: '/mypage/my-info',
        data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  // 젬 획득 내역
  getTotalPoint = (data: getTotalPoint): Promise<GetTotalPointResponse> => {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/mypage/gem',
        data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  // 젬 사용 내역
  getDrawData = (data: getDrawInfo): Promise<GetDrawResponse> => {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/mypage/draw',
        data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  // 코드 교환 팝업
  exchangeReward = (data: exchangeReward): Promise<exchangeRewardResponse> => {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/mypage/code',
        data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  // 환불 팝업
  refund = (data: refund): Promise<refundResponse> => {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/refund',
        data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err: errObj) => {
          console.log(err);
          reject(err);
        });
    });
  };

  // 배송지 확인 팝업
  confirmShippingAddress = (data: shipping): Promise<shippingResponse> => {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/mypage/address',
        data,
      })
        .then((res) => {
          // console.log(res);
          resolve(res.data);
        })
        .catch((err: errObj) => {
          reject(err);
        });
    });
  };

  // 배송지 입력 -> 배송 준비중으로 상태업데이트
  changeShippingStatus = (data: shippingStatus): Promise<UpdateResponse> => {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/state',
        data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  // 1:1 문의 작성
  sendInquiry = (data: inquiryData): Promise<inquiryResponse> => {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/cs',
        data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err: errObj) => {
          reject(err);
        });
    });
  };

  // 내 정보 - 이메일 인증번호 요청
  authByEmail = (data: emailData): Promise<authByEmailRes> => {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/mypage/email',
        data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  // 내 정보 - 이메일 인증번호 확인
  confirmEmailCode = (data: inputData): Promise<confirmEmailRes> => {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/mypage/email/number',
        data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  // 코드 교환 팝업 renewal
  getCodePopupInfo = (data: getPopupInfo): Promise<popUpInfoRes> => {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/mypage/re-code/pop-up',
        data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  // 코드 교환 팝업 내부 - 이메일 재전송 코드
  resendCodeByEmail = (data: getPopupInfo): Promise<resendCodeRes> => {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/mypage/re-code',
        data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export default new MyPageServices();
