import { Helmet } from 'react-helmet-async';

interface MetaDataProps {
  title: string;
  description: string;
  keywords: string;
  image?: string;
}

const SeoMetaTag = ({ title, description, keywords, image }: MetaDataProps) => {
  return (
    <Helmet>
      <meta name='description' content={description} />
      <meta name='keywords' content={keywords} />
      <title>{title}</title>
      {/* open graph */}
      <meta property='og:type' content='website' />
      <meta property='og:site_name' content={title} />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />
      <meta property='og:url' content={window.location.origin + window.location.pathname} />
      {image && <meta property='og:image' content={image} />}
      {/* twitter */}
      <meta name='twitter:card' content='image' />
      <meta name='twitter:title' content={title} />
      <meta name='twitter:description' content={description} />
      {image && <meta name='twitter:image' content={image} />}
    </Helmet>
  );
};

export default SeoMetaTag;
