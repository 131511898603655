// 챌린저 1%
// 마스터 1-6%
// 다이아몬드 6-15%
// 플래티넘 15-30%
// 골드 30-60%
// 실버 60-100%

export const getTierByRankingRatio = (tier: number) => {
  let imgUrl = '';
  let rank = '';

  if (tier <= 1) {
    imgUrl = `/images/tier/ico-tier-challenger.svg`;
    rank = 'Challenger';
  }
  if (tier > 1 && tier <= 6) {
    imgUrl = `/images/tier/ico-tier-master.svg`;
    rank = 'Master';
  }

  if (tier > 6 && tier <= 15) {
    imgUrl = `/images/tier/ico-tier-diamond.svg`;
    rank = 'Diamond';
  }

  if (tier > 15 && tier <= 30) {
    imgUrl = `/images/tier/ico-tier-platinum.svg`;
    rank = 'Platinum';
  }

  if (tier > 30 && tier <= 60) {
    imgUrl = `/images/tier/ico-tier-gold.svg`;
    rank = 'Gold';
  }

  if (tier > 60) {
    imgUrl = `/images/tier/ico-tier-silver.svg`;
    rank = 'Silver';
  }

  return {
    imgUrl,
    rank,
  };
};
