import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

interface P {
  mm: string | number;
  ss: string | number;
  reset?: () => void;
}

const AuthTimer: React.FC<P> = ({ mm, ss, reset }) => {
  const [minutes, setMinutes] = useState(Number(mm));
  const [seconds, setSeconds] = useState(Number(ss));

  const handleReset = () => {
    if (!reset) return;
    reset();
  };
  useEffect(() => {
    const timer = setInterval(() => {
      if (Number(seconds) > 0) setSeconds((seconds) => seconds - 1);
      if (Number(seconds) === 0) {
        if (Number(minutes) === 0) {
          clearInterval(timer);
          handleReset();
        } else {
          setMinutes((minutes) => minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [minutes, seconds]);

  return (
    <Typography variant='body2' sx={{ color: 'primary.main' }}>
      {`${minutes < 10 ? `0${minutes}` : minutes} : ${seconds < 10 ? `0${seconds}` : seconds} 초 남음`}
    </Typography>
  );
};

export default AuthTimer;
