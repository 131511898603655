import { Avatar, FormHelperText } from '@mui/material';

interface P {
  children: React.ReactNode;
}
const InfoHelperText: React.FC<P> = ({ children }) => {
  return (
    <FormHelperText sx={containerProps}>
      <Avatar src='/images/icon/ico-warning-blank.svg' alt='warning' sx={avatarProps} />
      {children}
    </FormHelperText>
  );
};

export default InfoHelperText;

const containerProps = {
  color: 'text.helper',
  display: 'flex',
  alignItems: 'center',
};

const avatarProps = {
  width: '16px',
  height: '16px',
  mr: 1,
};
