import ClearIcon from '@mui/icons-material/Clear';
import { InputAdornment, SxProps, TextField, TextFieldProps } from '@mui/material';

interface P extends Omit<TextFieldProps, 'InputProps'> {
  clearInput: () => void;
}

const SearchInput: React.FC<P> = ({ onChange, value, clearInput, sx, ...props }) => {
  return (
    <TextField
      onChange={onChange}
      autoComplete='off'
      value={value}
      InputProps={{
        sx: {
          color: 'text.primary',
        },
        endAdornment: value ? (
          <InputAdornment
            position='end'
            onClick={clearInput}
            sx={{
              '& .MuiSvgIcon-root': {
                fill: '#fff',
              },
            }}
          >
            <ClearIcon />
          </InputAdornment>
        ) : (
          <></>
        ),
      }}
      sx={inputSx}
      {...props}
    />
  );
};

const inputSx: SxProps = {
  width: '324px',
  bgcolor: '#25252a',
  borderRadius: 2,
  mr: 1,
  '& .MuiOutlinedInput-input': {
    padding: '9px 20px',
  },
  '& .MuiOutlinedInput-root.Mui-focused': {
    '& > fieldset': {
      borderColor: '#8c40cc',
    },
  },
  '& .MuiOutlinedInput-root:hover': {
    '& > fieldset': {
      borderColor: '#8c40cc',
    },
  },
};

export default SearchInput;
