import {
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  DialogContent,
  DialogContentText,
  Stack,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import CustomModal from '../../../components/CustomModal';
import CustomModalRenewal from '../../../components/CustomModalRenewal';
import { pathMyPage } from '../../../lib/pagePath';
import { userAtom } from '../../../recoil/Auth/userAtom';
import MyPageServices, {
  errObj,
  getDrawInfo,
  GetDrawResponse,
  getPopupInfo,
  refund,
  refundResponse,
  shipping,
} from '../../../services/MyPageServices';

const tabs = [{ title: '젬사용내역', subTitle: '상단알림' }];

const getState = (state: number) => {
  let status = '';
  switch (state) {
    case 1:
      status = '당첨';
      break;

    case 2:
      status = '당첨실패';
      break;

    case 3:
      status = '당첨취소';
      break;

    case 4:
      status = '구매완료';
      break;

    case 5:
      status = '환불완료';
      break;

    default:
      return status;
  }
  return status;
};

const getRefundGoodsData = (data: GetDrawResponse | undefined, goodsIdx: number | undefined) => {
  if (!data || !goodsIdx) return null;
  const refundGoodsData = data?.allDrawData?.filter((item) => item.idx === goodsIdx);
  return refundGoodsData;
};

const ApplicationHistory = () => {
  const { t } = useTranslation(['translation', 'mypage', 'common', 'popup', 'error']);
  const navigate = useNavigate();
  const user = useRecoilValue(userAtom);
  const [isRefundOpen, setIsRefundOpen] = useState(false);
  const [isRefundSuccess, setIsRefundSuccess] = useState(false);
  const [isCodeOpen, setIsCodeOpen] = useState(false);
  const [refundGoodsId, setRefundGoodsId] = useState<number | undefined>();
  const [shippingGoodsId, setShippingGoodsId] = useState<number | undefined>();
  const [codeIdx, setCodeIdx] = useState<number | undefined>();
  const [copied, setCopied] = useState(false);

  const handleClickOpen = (idx: number) => {
    setRefundGoodsId(idx);
    setIsRefundOpen(true);
  };

  const handleClose = () => {
    setIsRefundSuccess(false);
    setIsRefundOpen(false);
    setIsCodeOpen(false);
    if (isRefundError) {
      refundReset();
    }
    if (isConfirmError || isAddressExist) {
      confirmReset();
    }
  };

  const {
    mutate: getDrawMutate,
    data: DrawData,
    isLoading,
    isError: isDrawError,
    error: drawDataError,
  } = useMutation((data: getDrawInfo) => MyPageServices.getDrawData(data));

  const { mutate: getCodePopupMutate, data: codePopupData } = useMutation(
    (data: getPopupInfo) => MyPageServices.getCodePopupInfo(data),
    {
      onSuccess: (res) => {
        setIsCodeOpen(true);
      },
    },
  );

  const {
    mutate: resendCodeMutate,
    data: resendResult,
    isLoading: isResendLoading,
  } = useMutation((data: getPopupInfo) => MyPageServices.resendCodeByEmail(data), {
    onSuccess: (res) => {
      if (res.resend) {
        alert('해당 코드는 이메일로 발송되었습니다.');
        setIsCodeOpen(false);
      }
    },
  });

  const {
    mutate: refundMutate,
    data: refundData,
    reset: refundReset,
    isError: isRefundError,
    error: refundError,
  } = useMutation((data: refund) => MyPageServices.refund(data), {
    onSuccess: (res: refundResponse) => {
      console.log(res);
      if (res.refundResult.refund) {
        setIsRefundSuccess(true);
      }
      getDrawMutate({
        uid: user!.uid,
        regdate: user!.regdate,
      });
    },
  });

  const {
    mutate: confirmAddressMutate,
    data: addressData,
    isSuccess: isAddressExist,
    isError: isConfirmError,
    error: confirmError,
    reset: confirmReset,
  } = useMutation((data: shipping) => MyPageServices.confirmShippingAddress(data), {
    onSuccess: (res) => {
      console.log(res);
    },
  });

  useEffect(() => {
    if (user) {
      getDrawMutate({
        uid: user?.uid,
        regdate: user?.regdate,
      });
    }
  }, []);

  return (
    <Box sx={{ borderRadius: 2, bgcolor: 'background.modal', padding: '32px' }}>
      <Box>
        {tabs.map((tab) => {
          return (
            <Box
              key={`tab ${tab.title}`}
              borderBottom={1}
              sx={{
                display: 'flex',
                alignItems: 'center',
                borderBottomColor: 'customMenuListColor.light',
              }}
            >
              <Typography
                variant='h6'
                sx={{ mr: 2, borderBottom: '4px solid #af51ff', paddingBottom: '8px' }}
              >
                {t(`사용내역.${tab.title}`, { ns: 'mypage' })}
              </Typography>
              <Typography
                variant='body2'
                sx={{
                  paddingBottom: '8px',
                  fontSize: '13px',
                }}
              >
                {t(`사용내역.${tab.subTitle}`, { ns: 'mypage' })}
              </Typography>
            </Box>
          );
        })}
      </Box>

      {/* 컨텐츠 */}
      {/* 응모/구매내역 없음 */}
      {isDrawError && (drawDataError as errObj)?.error_code === 1022 ? (
        <Box sx={{ minHeight: 410, py: 50, textAlign: 'center' }}>
          {t(`사용내역.내역없음`, { ns: 'mypage' })}
        </Box>
      ) : isLoading ? (
        // 로딩중
        <Box sx={{ minHeight: 410, py: 50, textAlign: 'center' }}>
          <CircularProgress sx={{ color: 'primary.main' }} disableShrink />
        </Box>
      ) : (
        <Box sx={{ minHeight: 410 }}>
          {DrawData?.allDrawData?.map((el) => {
            const { idx, state: state1, stateDetail: state2, regdate } = el;
            return (
              <Stack
                key={`draw ${idx}${regdate}`}
                direction='row'
                spacing={7}
                sx={{
                  padding: '30px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  borderBottom: '1px solid',
                  borderColor: '#3e3e42',
                }}
              >
                <Stack direction='row' sx={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                  {/* state  */}
                  <Chip
                    label={t(`사용내역.${getState(el.state)}`, { ns: 'mypage' })}
                    sx={{
                      mr: 4,
                      padding: '8px 20px',
                      bgcolor:
                        el.state === 1 || el.state === 4 ? 'primary.main' : 'customMenuListColor.light',
                      color: 'white',
                      borderRadius: 1,
                      fontWeight: 'bold',
                      fontSize: 13,
                      '& .MuiChip-label': {
                        padding: '2px 0',
                      },
                    }}
                  />
                  {/* stateDetail */}
                  <Box
                    sx={{
                      flex: 1,
                      textAlign: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    {/* 코드 상품 */}
                    {(state1 === 1 || state1 === 4) && (state2 === 5 || state2 === 6) ? (
                      <>
                        <Button
                          onClick={() => {
                            setCodeIdx(el.idx);
                            getCodePopupMutate({ uid: user!.uid, regdate: user!.regdate, recordIdx: el.idx });
                          }}
                          sx={{ fontSize: 13 }}
                        >
                          {t(`사용내역.코드확인`, { ns: 'mypage' })}
                        </Button>
                        {/* <Button
                          onClick={() => handleClickOpen(idx)}
                          sx={{
                            color: 'text.primary',
                            borderRadius: 0,
                            borderTop: '1px solid',
                            borderColor: 'customMenuListColor.light',
                            fontSize: 13,
                          }}
                        >
                          {t(`사용내역.환불요청`, { ns: 'mypage' })}
                        </Button> */}
                      </>
                    ) : // 당첨,구매,배송지 미입력
                    (state1 === 1 || state1 === 4) && state2 === 0 ? (
                      <>
                        <Button
                          onClick={() => {
                            confirmAddressMutate({ uid: user!.uid });
                            setShippingGoodsId(idx);
                          }}
                          sx={{
                            fontSize: 13,
                          }}
                        >
                          {t(`사용내역.배송지입력`, { ns: 'mypage' })}
                        </Button>
                        <Button
                          onClick={() => handleClickOpen(idx)}
                          sx={{
                            color: 'text.primary',
                            borderRadius: 0,
                            borderTop: '1px solid',
                            borderColor: 'customMenuListColor.light',
                            fontSize: 13,
                          }}
                        >
                          {t(`사용내역.환불요청`, { ns: 'mypage' })}
                        </Button>
                      </>
                    ) : // 당첨,구매,배송준비중
                    (state1 === 1 || state1 === 4) && state2 === 1 ? (
                      <>
                        <Typography
                          sx={{
                            color: 'primary.light',
                            fontSize: 13,
                          }}
                        >
                          {t(`사용내역.배송준비중`, { ns: 'mypage' })}
                        </Typography>
                      </>
                    ) : (state1 === 1 || state1 === 4) && state2 === 2 ? (
                      // 당첨,구매, 배송중
                      <>
                        <Typography
                          sx={{
                            color: 'primary.light',
                            fontSize: 13,
                          }}
                        >
                          {t(`사용내역.배송중`, { ns: 'mypage' })}
                        </Typography>
                      </>
                    ) : (state1 === 1 || state1 === 4) && state2 === 3 ? (
                      // 당첨,구매, 배송완료
                      <>
                        <Typography
                          sx={{
                            color: 'primary.light',
                            fontSize: 13,
                          }}
                        >
                          {t(`사용내역.배송완료`, { ns: 'mypage' })}
                        </Typography>
                      </>
                    ) : (
                      // 당첨 취소
                      state1 === 3 && (
                        <Typography variant='subtitle1'>
                          {t(`사용내역.배송지미입력`, { ns: 'mypage' })}
                        </Typography>
                      )
                    )}
                  </Box>
                </Stack>
                {/* 경품 정보 */}
                <Box sx={{ display: 'flex', alignItems: 'center', flex: 2 }}>
                  <Avatar
                    src={`https://image.decakill.com${el?.imageUrl}`}
                    variant='square'
                    alt='profile'
                    sx={{ width: 60, height: 60, borderRadius: 2, mr: 4 }}
                  />
                  <Typography sx={{ fontSize: 15, fontWeight: 'bold', color: 'white' }}>
                    {el.rewardGoodsName}
                  </Typography>
                </Box>
                <Stack
                  direction='row'
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1 }}
                >
                  <Box sx={{ textAlign: 'center', mr: 10 }}>
                    <Typography variant='subtitle2'>{t(`사용내역.사용날짜`, { ns: 'mypage' })}</Typography>
                    <Typography variant='body2' sx={{ fontWeight: 'bold', color: 'white' }}>
                      {el.regdate.slice(2, 10).replaceAll('-', '.')}
                    </Typography>
                  </Box>
                  <Box sx={{ textAlign: 'center' }}>
                    <Typography variant='subtitle2'>{t(`사용내역.소모젬`, { ns: 'mypage' })}</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Avatar
                        src={`/images/icon/ico-${el.gemType === 2 ? 'streamer-pt' : 'league-pt'}.svg`}
                        alt='pointIcon'
                        sx={{ width: 15, height: 21, mr: 2 }}
                      />
                      <Typography
                        variant='body2'
                        sx={{
                          color: el.gemType === 2 ? 'secondary.main' : 'primary.light',
                        }}
                      >
                        {el.gem.toLocaleString()}
                      </Typography>
                    </Box>
                  </Box>
                </Stack>
              </Stack>
            );
          })}

          {/* 교환 코드 팝업 */}
          {!isRefundOpen && codePopupData?.info && (
            <CustomModalRenewal
              title={t(`교환코드.타이틀`, { ns: 'popup' })}
              btn1={t(`확인`, { ns: 'common' })}
              btn2={t(`교환코드.이메일전송`, { ns: 'popup' })}
              needsLoadingBtn={isResendLoading}
              open={isCodeOpen}
              handleClose={handleClose}
              handleConfirm={() =>
                resendCodeMutate({
                  uid: user!.uid,
                  regdate: user!.regdate,
                  recordIdx: codeIdx,
                })
              }
            >
              <Typography variant='body2' sx={{ mt: 4 }}>
                {`해당 코드는 ${codePopupData?.info.deadline.slice(0, 10)} 까지만 유효합니다.`}
              </Typography>
              <DialogContent sx={{ my: 2, px: 0 }}>
                <DialogContentText
                  id='alert-dialog-description'
                  sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                >
                  <Box
                    sx={{
                      width: '180px',
                      height: '180px',
                      position: 'relative',
                      zIndex: 999,
                      '&::after': backgroundStyling,
                    }}
                  >
                    <img
                      src={`https://image.decakill.com${codePopupData?.info.goodsUrl}`}
                      alt='exchangeResult'
                      width='100%'
                      height='100%'
                    />
                  </Box>
                  <Typography variant='body2' sx={{ my: 4 }}>
                    {codePopupData?.info.goodsName}
                  </Typography>
                  <Typography variant='h6'>교환 코드를 수령하실 이메일을 확인해주세요.</Typography>
                  <Box
                    sx={{
                      borderRadius: 2,
                      width: '100%',
                      bgcolor: 'customMenuListColor.main',
                      mt: 5,
                      p: 2,
                      textAlign: 'left',
                    }}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }} borderBottom={1}>
                      <Typography variant='h6'>이메일</Typography>
                      <Chip
                        label={t(`교환코드.변경`, { ns: 'popup' })}
                        onClick={() => {
                          alert('이메일 변경을 위해 나의 정보 페이지로 이동합니다.');
                          navigate('/my-page/info');
                        }}
                        sx={{
                          padding: '8px',
                          height: '28px',
                          bgcolor: 'background.default',
                          color: 'white',
                          borderRadius: 1,
                          fontWeight: 'bold',
                          fontSize: 15,
                          '& .MuiChip-label': {
                            padding: '2px 0',
                          },
                        }}
                      />
                    </Box>
                    <Typography sx={{ p: 2 }} variant='body2'>
                      {codePopupData?.info.email}
                    </Typography>
                  </Box>
                </DialogContentText>
              </DialogContent>
            </CustomModalRenewal>
          )}

          {/* 환불요청 시 기본 팝업 */}
          {isRefundOpen && refundGoodsId && (
            <CustomModalRenewal
              title={t(`환불팝업.환불안내`, { ns: 'popup' })}
              btn1={t(`취소`, { ns: 'common' })}
              btn2={t(`환불팝업.확인`, { ns: 'popup' })}
              open={isRefundOpen}
              handleClose={handleClose}
              handleConfirm={() => {
                refundMutate({
                  uid: user!.uid,
                  recordIdx: refundGoodsId,
                });
                handleClose();
              }}
            >
              <Typography variant='body2'>{t(`환불팝업.본문`, { ns: 'popup' })}</Typography>
              <DialogContent sx={{ my: 3 }}>
                <DialogContentText
                  id='alert-dialog-description'
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box
                    sx={{
                      width: '180px',
                      height: '180px',
                      position: 'relative',
                      zIndex: 999,
                      '&::after': backgroundStyling,
                    }}
                  >
                    <img
                      src={`https://image.decakill.com${
                        (getRefundGoodsData(DrawData, refundGoodsId) as any)[0].imageUrl
                      }`}
                      alt='refundResult'
                      width='100%'
                      height='100%'
                    />
                  </Box>
                  <Typography variant='body2' color='primary.light' sx={{ mt: 2 }}>
                    {(getRefundGoodsData(DrawData, refundGoodsId) as any)[0].rewardGoodsName}
                  </Typography>
                </DialogContentText>
              </DialogContent>
            </CustomModalRenewal>
          )}

          {/* 환불 불가 - 코드 / 배송상품 */}
          {isRefundError && (
            <CustomModal
              title={t(`환불팝업.환불불가`, { ns: 'popup' })}
              subTitle={t(`${(refundError as errObj)?.error_code}`, { ns: 'error' })}
              btn1={t(`확인`, { ns: 'common' })}
              open={isRefundError}
              handleClose={handleClose}
            />
          )}

          {/* 환불 성공 팝업*/}
          {isRefundSuccess && (
            <CustomModal
              title={t(`환불팝업.환불성공`, { ns: 'popup' })}
              subTitle={t(`환불팝업.환불성공본문`, { ns: 'popup' })}
              btn1={t(`확인`, { ns: 'common' })}
              open={isRefundSuccess}
              handleClose={handleClose}
            />
          )}

          {/* 배송지 미입력 안내 */}
          {isConfirmError && (confirmError as errObj)?.error_code === 1018 && (
            <CustomModalRenewal
              title={t(`배송지입력팝업.타이틀`, { ns: 'popup' })}
              btn1={t(`배송지입력팝업.나중에`, { ns: 'popup' })}
              btn2={t(`확인`, { ns: 'common' })}
              open={isConfirmError}
              handleClose={handleClose}
              handleConfirm={() => navigate(`${pathMyPage}/info`)}
            >
              <Typography variant='body2'>
                {t(`배송지입력팝업.본문1`, { ns: 'popup' })} <br />
                {t(`배송지입력팝업.본문2`, { ns: 'popup' })}
              </Typography>
              <DialogContent sx={{ my: 3 }}>
                <DialogContentText
                  id='alert-dialog-description'
                  sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                >
                  <Box
                    sx={{
                      width: '180px',
                      height: '180px',
                      position: 'relative',
                      zIndex: 999,
                      '&::after': backgroundStyling,
                    }}
                  >
                    <img
                      src={`/images/missing-shipping-address.png`}
                      alt='add address'
                      width='100%'
                      height='100%'
                    />
                  </Box>
                </DialogContentText>
              </DialogContent>
            </CustomModalRenewal>
          )}

          {/* 입력한 배송지 확인 & 배송신청하기 팝업 */}
          {isAddressExist && (
            <CustomModalRenewal
              title={t(`배송지확인팝업.타이틀`, { ns: 'popup' })}
              btn1={t(`배송지확인팝업.나중에`, { ns: 'popup' })}
              btn2={t(`배송지확인팝업.신청하기`, { ns: 'popup' })}
              open={isAddressExist}
              handleClose={handleClose}
              handleConfirm={() =>
                // 배송준비중으로 stateDetail변경
                MyPageServices.changeShippingStatus({
                  drawRecordIdx: shippingGoodsId!,
                }).then((res) => {
                  if (res.status.status_code === 200 && res.updateResult.stateDetail === 1) {
                    handleClose();
                    // refresh
                    getDrawMutate({
                      uid: user!.uid,
                      regdate: user!.regdate,
                    });
                  }
                })
              }
            >
              <Typography variant='body2'>
                {t(`배송지확인팝업.본문1`, { ns: 'popup' })} <br />
                {t(`배송지확인팝업.본문2`, { ns: 'popup' })}
              </Typography>
              <Box
                sx={{
                  my: 6,
                  p: 5,
                  bgcolor: 'customMenuListColor.light',
                  borderRadius: 2,
                  maxWidth: '336px',
                }}
              >
                <Typography
                  variant='body2'
                  sx={{
                    color: '#fff',
                    maxHeight: '100px',
                    overflowY: 'hidden',
                  }}
                >
                  {`${addressData?.addressInfoData?.province} ${addressData?.addressInfoData?.city} ${addressData?.addressInfoData?.address} 
                  ${addressData?.addressInfoData?.addressDetail} ${addressData?.addressInfoData?.postalCode}`}
                </Typography>
              </Box>
            </CustomModalRenewal>
          )}
        </Box>
      )}
    </Box>
  );
};

export default ApplicationHistory;

const backgroundStyling = {
  position: 'absolute',
  zIndex: -1,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%,-50%)',
  width: '336px',
  height: '231px',
  display: 'block',
  content: '""',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
  backgroundImage: `url(${process.env.PUBLIC_URL}/images/background/popup-bg2.png)`,
};
