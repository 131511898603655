import { useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { useSetRecoilState } from 'recoil';
import { errorAtom } from './recoil/errors/errorsAtom';
import Routes from './Routes';

function App() {
  const setError = useSetRecoilState(errorAtom);

  const ErrorHandler = useCallback(
    (err: any) => {
      if (err.status) {
        setError(err);
      }
    },
    [setError],
  );

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        onError: ErrorHandler,
      },
      mutations: {
        onError: ErrorHandler,
      },
    },
  });
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <Helmet>
        {/* 디폴트 메타태그 설정 */}
        <title>Decakill</title>
        <meta name='description' content='데카킬과 함께 방송을 더 짜릿하게!' />
        <meta property='og:image' content='/images/logo/decakill.png' />
        <meta name='twitter:image' content='/images/logo/decakill.png' />
      </Helmet>
      <Routes />
    </QueryClientProvider>
  );
}

export default App;
