import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import CustomModal from '../../../components/CustomModal';
import React, { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { userAtom, userProfileAtom } from '../../../recoil/Auth/userAtom';
import MyPageServices, { deleteAccount } from '../../../services/MyPageServices';
import { useTranslation } from 'react-i18next';

const tabs = [{ title: '탈퇴하기' }];

const DeleteAccount = () => {
  const { t } = useTranslation(['translation', 'mypage', 'common', 'popup', 'error']);
  const navigate = useNavigate();
  const user = useRecoilValue(userAtom);
  const setUser = useResetRecoilState(userAtom);
  const setUserProfile = useResetRecoilState(userProfileAtom);
  const [open, setOpen] = useState(false);

  const [radioNo, setRadioNo] = useState(1);
  const [cause, setCause] = useState('');

  // 탈퇴하기
  const {
    mutate: deleteAccountMutate,
    data,
    isError: isDeleteAccError,
    error: deleteAccError,
    isSuccess: isDeleteAccSuccess,
    reset,
  } = useMutation((data: deleteAccount) => MyPageServices.deleteAccount(data));

  // 탈퇴사유 불러오기
  const { data: reasonData, isLoading } = useQuery(['deleteReason'], () => MyPageServices.getDeleteReason(), {
    refetchOnWindowFocus: false,
    select: (res) => {
      return res.reasonData;
    },
  });

  // 젬 정보
  const {
    mutate: getGemInfoMutate,
    data: gemData,
    isLoading: isGemLoading,
    error,
    isError,
  } = useMutation((uid: string) => MyPageServices.getGemInfo({ uid }), {});

  const handleClickOpen = () => {
    if (radioNo === 6 && !cause) {
      alert(t('나의정보.탈퇴사유입력창', { ns: 'mypage' }));
      return null;
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (isDeleteAccSuccess) {
      reset();
    }
  };

  const deleteAccount = () => {
    if (user) {
      deleteAccountMutate({
        uid: user?.uid,
        regdate: user?.regdate,
        cause1: radioNo,
        cause2: radioNo === 6 ? cause : null,
      });
    }
    handleClose();
  };

  const handleRadio = (event: any) => {
    setRadioNo(Number(event.target.value));
  };

  const handleCause = (event: any) => {
    setCause(event.target.value);
  };

  useEffect(() => {
    if (user) {
      getGemInfoMutate(user?.uid);
    }
  }, []);

  return (
    <Box sx={{ borderRadius: 2, bgcolor: 'background.modal', padding: '32px' }}>
      <Box>
        {tabs.map((tab) => {
          return (
            <Box
              key={`tab ${tab.title}`}
              borderBottom={1}
              sx={{
                color: 'white',
                fontWeight: 'bold',
                borderBottomColor: 'customMenuListColor.light',
                pb: 4,
              }}
            >
              <span style={{ borderBottom: '4px solid #af51ff', paddingBottom: '10px' }}>
                {t(`나의정보.${tab.title}`, { ns: 'mypage' })}
              </span>
            </Box>
          );
        })}
      </Box>
      {/* 젬 정보 */}
      <Box sx={{ py: 6 }}>
        <Typography variant='h6'>{t(`나의정보.탈퇴알림1`, { ns: 'mypage' })}</Typography>
        <Typography variant='body2' sx={{ lineHeight: 1.6, mt: 2 }}>
          {t(`나의정보.탈퇴알림2`, { ns: 'mypage' })}
        </Typography>
      </Box>
      <Stack spacing={4} direction='row' sx={{ mb: 5 }}>
        {/* 이스포츠젬 */}
        <Box
          sx={{
            flex: 1,
            bgcolor: 'primary.dark',
            padding: '28px 32px',
            borderRadius: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Avatar src='/images/icon/ico-league-pt.svg' alt='esportGem' />
            <Typography
              color='white'
              sx={{ fontSize: 15, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
              {t(`나의데이터.퍼플젬`, { ns: 'mypage' })}
            </Typography>
          </Box>
          <Box>
            {isGemLoading ? (
              <Typography variant='body2' sx={{ color: 'primary.light' }}>
                <CircularProgress sx={{ color: 'primary.light' }} disableShrink size={16} />
              </Typography>
            ) : (
              <Typography variant='h3' sx={{ color: 'primary.light' }}>
                {gemData?.gemData.purpleGem.toLocaleString()}
              </Typography>
            )}
          </Box>
        </Box>
        {/* 스트리머젬 */}
        <Box
          sx={{
            flex: 1,
            bgcolor: 'secondary.dark',
            padding: '28px 32px',
            borderRadius: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Avatar src='/images/icon/ico-streamer-pt.svg' alt='esportGem' />
            <Typography
              color='white'
              sx={{ fontSize: 15, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
              {t(`나의데이터.그린젬`, { ns: 'mypage' })}
            </Typography>
          </Box>
          <Box>
            {isGemLoading ? (
              <Typography variant='body2'>
                <CircularProgress sx={{ color: 'secondary.main' }} disableShrink size={16} />
              </Typography>
            ) : (
              <Typography variant='h3' sx={{ color: 'secondary.main' }}>
                {gemData?.gemData.greenGem.toLocaleString()}
              </Typography>
            )}
          </Box>
        </Box>
      </Stack>
      {/* 탈퇴하기 */}
      <Box>
        <Typography variant='h6'>{t(`나의정보.탈퇴사유제목`, { ns: 'mypage' })}</Typography>
        <Typography variant='body2' mt={2}>
          {t(`나의정보.탈퇴사유소제목`, { ns: 'mypage' })}
        </Typography>

        <RadioGroup name='radio-buttons-group' sx={{ mt: 3 }} defaultValue='1'>
          {reasonData?.map((el: any) => {
            return (
              <Box key={el.idx + el.title}>
                <FormControlLabel
                  value={el.idx}
                  control={<Radio size='small' sx={{ padding: 0, mx: 2 }} />}
                  label={t(`나의정보.${el.idx}`, { ns: 'mypage' })}
                  onChange={handleRadio}
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      fontSize: 15,
                      color: 'text.primary',
                    },
                  }}
                />
              </Box>
            );
          })}
        </RadioGroup>
        <TextField
          required={radioNo === 6}
          onChange={handleCause}
          inputProps={{
            readOnly: radioNo !== 6,
          }}
          inputRef={(input) => input && radioNo === 6 && input.focus()}
          id='outlined-multiline-static'
          sx={{
            mt: 3,
            bgcolor: 'customMenuListColor.main',
            borderRadius: 2,
            '& .MuiOutlinedInput-root ': {
              padding: 0,
            },
            '.MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            '& .MuiOutlinedInput-input': {
              padding: '20px',
              fontSize: 15,
            },
          }}
          multiline
          fullWidth
          rows={4}
          placeholder={t(`나의정보.탈퇴사유입력창`, { ns: 'mypage' })}
        />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 6 }}>
          <Button
            variant='contained'
            sx={{ bgcolor: 'customMenuListColor.main' }}
            onClick={() => navigate(-1)}
          >
            {t(`취소`, { ns: 'common' })}
          </Button>
          <Button
            variant='contained'
            onClick={handleClickOpen}
            sx={{
              bgcolor: !radioNo && !cause ? 'customMenuListColor.main' : 'primary.main',
            }}
          >
            {t(`나의정보.탈퇴하기`, { ns: 'mypage' })}
          </Button>
          {/* 탈퇴 확인 모달창 */}
          <CustomModal
            title={t(`알림`, { ns: 'common' })}
            subTitle={t(`나의정보.탈퇴팝업1`, { ns: 'mypage' })}
            subTitle2={t(`나의정보.탈퇴팝업2`, { ns: 'mypage' })}
            subTitle3={t(`나의정보.탈퇴팝업3`, { ns: 'mypage' })}
            open={open}
            handleClose={handleClose}
            handleConfirm={deleteAccount}
            btn1={t(`취소`, { ns: 'common' })}
            btn2={t(`나의정보.탈퇴하기`, { ns: 'mypage' })}
          />

          {/* 에러 */}
          {isDeleteAccError && (
            <CustomModal
              title={t(`알림`, { ns: 'common' })}
              subTitle={t((deleteAccError as any)?.error_code, { ns: 'error' })}
              btn1={t(`확인`, { ns: 'common' })}
              open={isDeleteAccError}
              handleClose={handleClose}
              sx={{
                textAlign: 'center',
                '& .MuiPaper-root': {
                  bgcolor: 'background.modal',
                  padding: '10px 48px',
                  borderRadius: 2,
                },
              }}
            />
          )}
          {/* 성공 */}
          {isDeleteAccSuccess && (
            <CustomModal
              title={t(`알림`, { ns: 'common' })}
              subTitle={t(`나의정보.탈퇴완료`, { ns: 'mypage' })}
              btn1={t(`확인`, { ns: 'common' })}
              open={isDeleteAccSuccess}
              handleClose={() => {
                setUser();
                setUserProfile();
                navigate('/');
              }}
              sx={{
                textAlign: 'center',
                '& .MuiPaper-root': {
                  bgcolor: 'background.modal',
                  padding: '10px 48px',
                  borderRadius: 2,
                },
              }}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default DeleteAccount;
