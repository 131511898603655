import { Box, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

const tabs = [
  { title: '전체 랭킹', path: '/rank/streamer' },
  { title: '데일리 랭킹', path: '/rank/streamer/daily' },
];

const Streamer = () => {
  const { t } = useTranslation(['translation', 'rank']);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <Box sx={{ textAlign: 'center', position: 'relative' }}>
      <Stack direction='column'>
        {/* 로고 */}
        <Box mt={12} mb={1} sx={{ display: 'flex', justifyContent: 'center' }}>
          {tabs.map((tab) => {
            const active = pathname === tab.path;
            return (
              <Button
                key={`tab ${tab.title}`}
                variant={active ? 'contained' : 'outlined'}
                sx={{
                  padding: '16px',
                  width: '100%',
                  backgroundColor: active ? 'primary.dark' : 'background.modal',
                  border: '1px solid transparent',
                  color: active ? '#fff' : 'text.primary',
                }}
                onClick={() => navigate(tab.path)}
              >
                {t(`${tab.title}`, { ns: 'rank' })}
              </Button>
            );
          })}
        </Box>

        {/* 컨텐츠 */}
        <Box>
          <Outlet />
        </Box>
      </Stack>
    </Box>
  );
};

export default Streamer;
