import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

const RouteChangeTracker = () => {
  const location = useLocation();

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);
      ReactGA.send({
        hitType: 'pageview',
        path: location.pathname,
        location: location.pathname,
        title: location.pathname,
      });
    }
  }, [location]);

  return <></>;
};

export default RouteChangeTracker;
