import CustomSelectBox from '@components/customSelectBox';
import styled from '@emotion/styled/macro';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import DataBox from '@pages/MyPage/components/DataBox';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useRecoilValue } from 'recoil';

import SeoMetaTag from '@components/SeoMetaTag';
import { getTierByRankingRatio } from '@lib/Rank/getTierByRanking';
import CustomModal from '../../../components/CustomModal';
import { userAtom, userProfileAtom } from '../../../recoil/Auth/userAtom';
import { getAllData, getYearlyData } from '../../../services/MyPageServices';
import RankServices, { getRank } from '../../../services/RankServices';
import { getKDA } from './DaliyRank';

const tabs = [{ title: '기록실' }];
const YEARS = ['2021', '2022'];

// 검색어 하이라이팅
const getMatchedText = (data, input) => {
  if (input !== '' && data.includes(input)) {
    const parts = data.split(new RegExp(`(${input})`, `gi`));
    return (
      <>
        {parts.map((part, index) =>
          part.toLowerCase() === input.toLowerCase() ? (
            <Box key={`${part}${index}`} sx={{ color: 'primary.light', display: 'inline-block' }}>
              {part}
            </Box>
          ) : (
            part
          ),
        )}
      </>
    );
  }
  return data;
};

function StreamerRank() {
  const { t } = useTranslation(['translation', 'common', 'rank', 'popup']);
  const user = useRecoilValue(userAtom);
  const userProfile = useRecoilValue(userProfileAtom);

  // 테이블 State
  const [streamerInfo, setStreamerInfo] = useState('totalData'); // 앰비션님 default 처리 예정
  const [yearInfo, setYearInfo] = useState('2022');

  // 검색창 State
  const [inputValue, setInputValue] = useState();
  const [settledValue, setSettledValue] = useState();

  // 개인팝업 State
  const [streamer, setStreamer] = useState('totalData');
  const [year, setYear] = useState('2022');
  const [userId, setUserId] = useState();
  const [open, setOpen] = useState(false);

  // 스트리머 리스트 가져오기
  const { data: streamerData } = useQuery(['streamerList'], () => RankServices.getStreamerList(), {
    refetchOnWindowFocus: false,
  });

  // 전체 랭킹 데이터 가져오기
  const {
    mutate: getAllRankMutate,
    data,
    isLoading,
    isError: isRankError,
    error: rankError,
  } = useMutation((data: getRank) => RankServices.getStreamerRanking(data));

  // 유저 데이터
  const {
    mutate: getUserInfoMutate,
    data: userData,
    isError: isUserError,
    error: userError,
  } = useMutation((data: getUserInfo) => RankServices.getStreamerUserInfo(data));

  // 전체 기록
  const {
    mutate: getAllDataMutate,
    data: allData,
    isError: isAlldataError,
    error: allDataError,
    reset: allDataReset,
  } = useMutation((data: getAllData) => RankServices.getStreamerAllData(data));

  // 연도별 기록
  const {
    mutate: getYearlyDataMutate,
    data: yearlyData,
    isError: isYearDataError,
    error: yearDataError,
    reset: yearlyDataReset,
  } = useMutation((data: getYearlyData) => RankServices.getStreamerYearlyData(data));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    allDataReset();
    yearlyDataReset();
  };

  const handleSearch = () => {
    if (!inputValue) return;

    getAllRankMutate({
      uid: user?.uid,
      channelId: streamerInfo !== 'totalData' ? streamerInfo : null,
      year: Number(yearInfo),
      keyword: settledValue,
    });
  };

  const clearInput = () => {
    setInputValue('');
    setSettledValue('');
    getAllRankMutate({
      uid: user?.uid,
      channelId: streamerInfo !== 'totalData' ? streamerInfo : null,
      year: Number(yearInfo),
      keyword: null,
    });
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  useEffect(() => {
    if (!streamerInfo || !yearInfo) return;
    getAllRankMutate({
      uid: user?.uid,
      channelId: streamerInfo !== 'totalData' ? streamerInfo : null,
      year: Number(yearInfo), // default: 2022
      keyword: null,
    });
  }, [user, streamerInfo, yearInfo]);

  // 팝업
  useEffect(() => {
    getAllDataMutate({
      uid: userId,
      channelId: streamer !== 'totalData' ? streamer : null,
    });
  }, [streamer]);

  useEffect(() => {
    getYearlyDataMutate({
      uid: userId,
      channelId: streamer !== 'totalData' ? streamer : null,
      year: Number(year), // default: 2022
    });
  }, [streamer, year]);

  useEffect(() => {
    handleSearch();
  }, [settledValue]);

  useEffect(() => {
    if (open) return;
    setStreamer('totalData');
    setYear('2022');
  }, [open]);

  return (
    <Box sx={{ marginTop: '40px', mb: 16, width: '1176px' }}>
      <SeoMetaTag
        title='Decakill | Leaderboard'
        description='데카킬 스트리머 랭킹페이지 입니다.'
        keywords='데카킬 스트리머 랭킹, decakill streamer ranking'
      />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ display: 'flex' }}>
          {/* 스트리머 필터 */}
          <CustomSelectBox
            placeholder={t('스트리머', { ns: 'common' })}
            data={streamerInfo}
            setData={setStreamerInfo}
            sx={{
              marginRight: 2,
              width: 190,
              '&.MuiInputBase-root': {
                bgcolor: '#25252a',
              },
            }}
            filledSx={{
              '&.MuiFormLabel-filled ~ div': {
                color: 'white',
                border: '1px solid',
                borderColor: '#8c40cc',
              },
            }}
          >
            <MenuItem value='totalData'>전체 스트리머</MenuItem>
            {streamerData?.streamerList?.map((item) => {
              return (
                <MenuItem key={`item ${item.idx}`} value={item.broadcastId}>
                  {item.displayName}
                </MenuItem>
              );
            })}
          </CustomSelectBox>
          {/* 연도 필터 */}
          <CustomSelectBox
            placeholder={t('연도', { ns: 'common' })}
            data={yearInfo}
            setData={setYearInfo}
            sx={{
              width: 190,
              '&.MuiInputBase-root': {
                bgcolor: '#25252a',
              },
            }}
            filledSx={{
              '&.MuiFormLabel-filled ~ div': {
                color: 'white',
                border: '1px solid',
                borderColor: '#8c40cc',
              },
            }}
          >
            {YEARS.map((item) => {
              return (
                <MenuItem key={`item ${item}`} value={item}>
                  {item}
                </MenuItem>
              );
            })}
          </CustomSelectBox>
        </Box>
        {/* 검색창 */}
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
          <TextField
            autoComplete='off'
            onChange={handleInputChange}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                setSettledValue(inputValue);
              }
            }}
            value={inputValue}
            placeholder={t('유저명', { ns: 'common' })}
            InputProps={{
              sx: {
                color: 'text.primary',
              },
              disableUnderline: true,
              endAdornment: inputValue && (
                <InputAdornment
                  position='end'
                  onClick={clearInput}
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fill: '#fff',
                    },
                  }}
                >
                  <ClearIcon />
                </InputAdornment>
              ),
            }}
            sx={{
              width: '324px',
              bgcolor: '#25252a',
              borderRadius: 2,
              mr: 1,
              '& .MuiOutlinedInput-input': {
                padding: '9px 20px',
              },
              '& .MuiOutlinedInput-root.Mui-focused': {
                '& > fieldset': {
                  borderColor: '#8c40cc',
                },
              },
              '& .MuiOutlinedInput-root:hover': {
                '& > fieldset': {
                  borderColor: '#8c40cc',
                },
              },
            }}
          />
          <Button
            variant='contained'
            sx={{
              fontWeight: 'bold',
              color: inputValue ? '#fff' : 'text.primary',
              bgcolor: inputValue ? 'primary.main' : 'customMenuListColor.main',
              padding: '8px 20px',
              borderRadius: 2,
              ml: 1,
            }}
            onClick={() => {
              setSettledValue(inputValue);
            }}
          >
            {t('검색', { ns: 'common' })}
          </Button>
        </Box>
      </Box>
      {/* 랭킹 테이블 */}
      <Box
        sx={{
          marginTop: '20px',
          borderTop: '1px solid #707070',
          width: '100%',
          overflow: 'auto',
          maxHeight: '1000px',
          position: 'relative',
          // scroll hidden
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          '&::-webkit-scrollbar-corner': {
            display: 'none',
          },
        }}
      >
        {!data && (!streamerInfo || !yearInfo) ? (
          <Box
            direction='row'
            sx={{
              p: 60,
              my: 2,
              borderRadius: 2,
              bgcolor: '#1c1c21',
            }}
          >
            {t('알림5', { ns: 'rank' })}
          </Box>
        ) : (
          <HomeTable isLoggedIn={user}>
            {/* header */}
            <Stack direction='row' sx={{ p: 4, position: 'sticky', top: 0, bgcolor: '#0e0e13', zIndex: 1 }}>
              <Box width='10%'>{t('테이블헤더.순위', { ns: 'rank' })}</Box>
              <Box
                width='12.7%'
                sx={{
                  flex: 1,
                }}
              >
                {t('테이블헤더.티어', { ns: 'rank' })}
              </Box>
              <Box width='28.3%'>{t('테이블헤더.닉네임', { ns: 'rank' })}</Box>
              <Box width='8.5%'>{t('테이블헤더.참여횟수', { ns: 'rank' })}</Box>
              <Box width='8.5%'>{t('테이블헤더.킬', { ns: 'rank' })}</Box>
              <Box width='8.5%'>{t('테이블헤더.콤보킬', { ns: 'rank' })}</Box>
              <Box width='8.5%'>{t('테이블헤더.멀티킬', { ns: 'rank' })}</Box>
              <Box width='8.5%'>{t('테이블헤더.데스', { ns: 'rank' })}</Box>
              <Box width='8.5%'>{t('테이블헤더.KD', { ns: 'rank' })}</Box>
            </Stack>
            {/* 내 랭킹 - 최상단 배치 */}
            {user && data?.myRankingData && (
              <Stack
                direction='row'
                sx={{
                  p: 4,
                  my: 2,
                  borderRadius: 2,
                  color: 'white',
                  display: 'flex',
                  alignItems: 'center',
                  background: 'linear-gradient(93deg, #613389 1%, #0b8966)',
                  '&:hover': {
                    background: 'linear-gradient(93deg, #6E36A0 1%, #069C74)',
                    cursor: 'pointer',
                  },
                }}
                onClick={() => {
                  setUserId(user?.uid);
                  getUserInfoMutate({ uid: user?.uid });
                  getAllDataMutate({
                    uid: user?.uid,
                    channelId: streamer !== 'totalData' ? streamer : null,
                  });
                  getYearlyDataMutate({
                    uid: user?.uid,
                    channelId: streamer !== 'totalData' ? streamer : null,
                    year: 2022,
                  });
                  handleClickOpen();
                }}
              >
                <Box width='10%'>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Chip
                      label='MY'
                      sx={{
                        padding: '8px',
                        height: '28px',
                        bgcolor: 'background.default',
                        color: 'white',
                        borderRadius: 1,
                        fontWeight: 'bold',
                        mr: 2,
                        fontSize: 13,
                        '& .MuiChip-label': {
                          padding: '2px 0',
                        },
                      }}
                    />
                    {data?.myRankingData?.rankData ? (
                      <Typography variant='h6' sx={{ color: '#fff' }}>
                        {data?.myRankingData?.rankData[0]?.ranking}
                      </Typography>
                    ) : (
                      '-'
                    )}
                  </Box>
                </Box>
                <Box
                  width='12.7%'
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1 }}
                >
                  {data?.myRankingData?.rankData ? (
                    <>
                      <Box width={24}>
                        <img
                          width='100%'
                          height='100%'
                          style={{ marginRight: 4 }}
                          alt='tier'
                          src={getTierByRankingRatio(data?.myRankingData?.rankData[0]?.rankingRatio).imgUrl}
                        />
                      </Box>
                      <Typography variant='body2' sx={{ color: '#fff', fontWeight: 'bold' }}>
                        {getTierByRankingRatio(data?.myRankingData?.rankData[0]?.rankingRatio).rank}
                      </Typography>
                    </>
                  ) : (
                    '-'
                  )}
                </Box>
                <Box width='28.3%' sx={{ display: 'flex', justifyContent: 'flex-start', ml: 3 }}>
                  {data?.myRankingData?.uid && (
                    <>
                      <Avatar
                        src={userProfile?.profile_image_url}
                        alt='profile'
                        variant='square'
                        sx={{
                          width: 24,
                          height: 24,
                          borderRadius: 1,
                        }}
                      />
                      <Typography
                        variant='h6'
                        sx={{
                          ml: 3,
                          color: '#fff',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                        }}
                      >
                        {userProfile?.display_name}
                      </Typography>
                    </>
                  )}
                </Box>
                <Box width='8.5%' sx={{ fontWeight: 'bold', color: '#fff' }}>
                  {(data?.myRankingData?.rankData && data?.myRankingData?.rankData[0]?.gameCount) ?? '-'}
                </Box>
                <Box width='8.5%' sx={{ fontWeight: 'bold', color: '#fff' }}>
                  {(data?.myRankingData?.rankData && data?.myRankingData?.rankData[0]?.kill) ?? '-'}
                </Box>

                <Box width='8.5%' sx={{ fontWeight: 'bold', color: '#fff' }}>
                  {(data?.myRankingData?.rankData && data?.myRankingData?.rankData[0]?.comboKill) ?? '-'}
                </Box>
                <Box width='8.5%' sx={{ fontWeight: 'bold', color: '#fff' }}>
                  {/* {data?.myRankingData?.rankData?.multiKill ?? '-'} */}
                  {data?.myRankingData?.rankData
                    ? data?.myRankingData?.rankData[0]?.multiKill >= 10
                      ? 10
                      : data?.myRankingData?.rankData[0]?.multiKill
                    : '-'}
                </Box>
                <Box width='8.5%' sx={{ fontWeight: 'bold', color: '#fff' }}>
                  {(data?.myRankingData?.rankData && data?.myRankingData?.rankData[0]?.death) ?? '-'}
                </Box>
                <Box width='8.5%' sx={{ fontWeight: 'bold', color: '#fff' }}>
                  {data?.myRankingData?.rankData && data?.myRankingData?.rankData[0]
                    ? getKDA(data?.myRankingData?.rankData[0])
                    : '-'}
                </Box>
              </Stack>
            )}
            {/* 전체 랭킹 배치 - 로그인 여부 상관없이 표기 검색 데이터 */}
            {isLoading ? (
              <Box
                direction='row'
                sx={{
                  p: 60,
                  mt: 2,

                  borderRadius: 2,
                  bgcolor: '#1c1c21',
                }}
              >
                <CircularProgress sx={{ color: 'primary.main' }} disableShrink />
              </Box>
            ) : isRankError && rankError.error_code === 1045 ? (
              // 검색 유저 없는 경우
              <Box
                direction='row'
                sx={{
                  p: 60,
                  mt: 2,

                  borderRadius: 2,
                  bgcolor: '#1c1c21',
                }}
              >
                {t('알림4', { ns: 'rank' })}
              </Box>
            ) : isRankError && rankError.error_code === 1061 ? (
              // 두 글자 미만 검색인 경우
              <Box
                direction='row'
                sx={{
                  p: 60,
                  mt: 2,

                  borderRadius: 2,
                  bgcolor: '#1c1c21',
                }}
              >
                {t('알림3', { ns: 'rank' })}
              </Box>
            ) : (isRankError && rankError.error_code === 0) ||
              data?.rankingData === null ||
              data?.rankingData.length === 0 ? (
              // 전체 랭킹 없는 경우
              <Box
                direction='row'
                sx={{
                  p: 60,
                  mt: 2,
                  borderRadius: 2,
                  bgcolor: '#1c1c21',
                }}
              >
                {t('랭킹없음', { ns: 'rank' })}
              </Box>
            ) : (
              data?.rankingData?.map((el) => {
                const {
                  uid,
                  comboKill,
                  displayName,
                  death,
                  gameCount,
                  kill,
                  multiKill,
                  ranking,
                  rankingRatio,
                  profileImageUrl,
                } = el;
                return (
                  <Stack
                    direction='row'
                    key={displayName + uid}
                    onClick={() => {
                      setUserId(uid);
                      getUserInfoMutate({ uid });
                      getAllDataMutate({
                        uid,
                        channelId: streamer !== 'totalData' ? streamer : null,
                      });
                      getYearlyDataMutate({
                        uid,
                        channelId: streamer !== 'totalData' ? streamer : null,
                        year: 2022,
                      });
                      handleClickOpen();
                    }}
                    sx={{
                      p: 4,
                      my: 2,
                      borderRadius: 2,
                      bgcolor: '#1c1c21',
                      display: 'flex',
                      alignItems: 'center',
                      backgroundImage:
                        // 내가 100위권 안에 드는 경우 백그라운드 강조
                        data?.myRankingData?.rankData && data?.myRankingData?.rankData[0]?.uid === uid
                          ? 'linear-gradient(93deg, #613389 1%, #0b8966)'
                          : 'none',
                      '&:hover': {
                        background:
                          data?.myRankingData?.rankData && data?.myRankingData?.rankData[0]?.uid === uid
                            ? 'linear-gradient(93deg, #6E36A0 1%, #069C74)'
                            : '#25252a',
                        cursor: 'pointer',
                      },
                    }}
                  >
                    <Box
                      width='10%'
                      sx={{ color: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                      {data?.myRankingData?.rankData && data?.myRankingData?.rankData[0]?.uid === uid && (
                        <Chip
                          label='MY'
                          sx={{
                            padding: '8px',
                            height: '28px',
                            bgcolor: 'background.default',
                            color: 'white',
                            borderRadius: 1,
                            fontWeight: 'bold',
                            mr: 2,
                            fontSize: 13,
                            '& .MuiChip-label': {
                              padding: '2px 0',
                            },
                          }}
                        />
                      )}
                      <Typography variant='h6' sx={{ color: '#fff' }}>
                        {ranking}
                      </Typography>
                    </Box>
                    <Box
                      width='12.7%'
                      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1 }}
                    >
                      <Box width={24}>
                        <img
                          width='100%'
                          height='100%'
                          style={{ marginRight: 4 }}
                          alt='tier'
                          src={getTierByRankingRatio(rankingRatio).imgUrl}
                        />
                      </Box>
                      <Typography variant='body2' sx={{ color: '#fff' }}>
                        {getTierByRankingRatio(rankingRatio).rank}
                      </Typography>
                    </Box>
                    <Box width='28.3%' sx={{ display: 'flex', justifyContent: 'flex-start', ml: 3 }}>
                      {displayName === null ? (
                        <></>
                      ) : (
                        <>
                          <Avatar
                            src={profileImageUrl}
                            alt='profile'
                            variant='square'
                            sx={{
                              width: 24,
                              height: 24,
                              borderRadius: 1,
                            }}
                          />
                          <Typography
                            variant='body2'
                            sx={{
                              ml: 3,
                              color: '#fff',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                            }}
                          >
                            {getMatchedText(displayName, settledValue)}
                          </Typography>
                        </>
                      )}
                    </Box>
                    <Box width='8.5%' sx={{ color: '#fff' }}>
                      {gameCount}
                    </Box>
                    <Box width='8.5%' sx={{ color: '#fff' }}>
                      {kill}
                    </Box>
                    <Box width='8.5%' sx={{ color: '#fff' }}>
                      {comboKill}
                    </Box>
                    <Box width='8.5%' sx={{ color: '#fff' }}>
                      {multiKill >= 10 ? 10 : multiKill}
                    </Box>
                    <Box width='8.5%' sx={{ color: '#fff' }}>
                      {death}
                    </Box>
                    <Box width='8.5%' sx={{ color: '#fff' }}>
                      {death !== 0 && kill / death ? (kill / death).toFixed(2) : (kill * 1.2).toFixed(2)}
                    </Box>
                  </Stack>
                );
              })
            )}
            {/*  각 유저 데이터 팝업창 */}
            {
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
                sx={{
                  textAlign: 'center',
                  '& .MuiPaper-root': {
                    bgcolor: 'background.modal',
                    width: '609px',
                    borderRadius: 2,
                  },
                }}
              >
                <DialogTitle
                  id='alert-dialog-title'
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderBottom: '1px solid',
                    borderBottomColor: 'customMenuListColor.light',
                    backgroundImage: `url(${process.env.PUBLIC_URL}/images/background/popup-bg.png)`,
                    backgroundSize: 'cover',
                  }}
                >
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Avatar src={userData?.userInfo.profileImageUrl} alt='profile' variant='square' />
                    <Typography ml={4} variant='h6'>
                      {userData?.userInfo.displayName}
                    </Typography>
                  </Box>
                  <ClearIcon onClick={handleClose} sx={{ cursor: 'pointer' }} />
                </DialogTitle>

                <DialogContent>
                  <Box sx={{ bgcolor: 'background.modal', padding: '32px 0' }}>
                    {tabs.map((tab) => {
                      return (
                        <Box
                          borderBottom={1}
                          sx={{
                            color: 'white',
                            fontWeight: 'bold',
                            textAlign: 'left',
                            borderBottomColor: 'customMenuListColor.light',
                            pb: 4,
                          }}
                        >
                          <span style={{ borderBottom: '4px solid #af51ff', paddingBottom: '9px' }}>
                            {t(`나의데이터.${tab.title}`, { ns: 'mypage' })}
                          </span>
                        </Box>
                      );
                    })}
                  </Box>
                  <Stack>
                    <Box
                      sx={{
                        pb: 2,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Typography variant='h6'>{t(`나의데이터.전체기록`, { ns: 'mypage' })}</Typography>
                      <CustomSelectBox
                        label={t('스트리머', { ns: 'common' })}
                        placeholder={t('스트리머', { ns: 'common' })}
                        data={streamer}
                        setData={setStreamer}
                        sx={{
                          width: 172,
                        }}
                      >
                        <MenuItem value='totalData'>전체 스트리머</MenuItem>
                        {streamerData?.streamerList?.map((item) => {
                          return (
                            <MenuItem key={`item ${item.idx}`} value={item.broadcastId}>
                              {item.displayName}
                            </MenuItem>
                          );
                        })}
                      </CustomSelectBox>
                    </Box>
                    <Stack direction='row' spacing={3}>
                      <DataBox
                        title={t(`나의데이터.총참여횟수`, { ns: 'mypage' })}
                        content={allData?.userAllGameData?.totalGameCount}
                        subTitle={t(`게임`, { ns: 'common' })}
                        textAlign='left'
                      />
                      <DataBox
                        title={t(`나의데이터.최다멀티킬`, { ns: 'mypage' })}
                        content={allData?.userAllGameData?.maxMultiKill}
                        subTitle={t(`킬`, { ns: 'common' })}
                        textAlign='left'
                      />
                      <DataBox
                        title={t(`나의데이터.최다연속킬`, { ns: 'mypage' })}
                        content={allData?.userAllGameData?.maxConsecutiveKill}
                        subTitle={t(`연속킬`, { ns: 'common' })}
                        textAlign='left'
                      />
                    </Stack>
                    <Stack direction='row' spacing={3} mt={2}>
                      <DataBox
                        title={t(`나의데이터.누적킬`, { ns: 'mypage' })}
                        content={allData?.userAllGameData?.sumKillCount}
                        subTitle={t(`킬`, { ns: 'common' })}
                        textAlign='left'
                      />
                      <DataBox
                        title={t(`나의데이터.누적데스`, { ns: 'mypage' })}
                        content={allData?.userAllGameData?.sumDeathCount}
                        subTitle={t(`데스`, { ns: 'common' })}
                        textAlign='left'
                      />
                    </Stack>
                  </Stack>
                  {/* 평균 킬 카운트, 데스 카운트 */}
                  <Stack spacing={3} sx={{ my: 3 }}>
                    <Box
                      border={1}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        flex: 1,
                        borderColor: '#343438',
                        padding: '18px 24px',
                        borderRadius: 2,
                      }}
                    >
                      <Typography variant='subtitle2'>{t(`나의데이터.평균킬`, { ns: 'mypage' })}</Typography>
                      <Typography variant='body2' color='primary.light' fontWeight='bold'>
                        {allData?.userAllGameData?.avgKillCount
                          ? allData?.userAllGameData?.avgKillCount
                          : '-'}
                      </Typography>
                    </Box>
                    <Box
                      border={1}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        flex: 1,
                        borderColor: '#343438',
                        padding: '18px 24px',
                        borderRadius: 2,
                      }}
                    >
                      <Typography variant='subtitle2'>
                        {t(`나의데이터.평균데스`, { ns: 'mypage' })}
                      </Typography>
                      <Typography variant='body2' color='primary.light' fontWeight='bold'>
                        {allData?.userAllGameData?.avgDeathCount
                          ? allData?.userAllGameData?.avgDeathCount
                          : '-'}
                      </Typography>
                    </Box>
                  </Stack>

                  {/* 연도 기록 */}
                  <Stack>
                    <Box
                      sx={{
                        pb: 2,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Typography variant='h6'>{t(`나의데이터.연도기록`, { ns: 'mypage' })}</Typography>
                      <CustomSelectBox
                        label={t(`연도`, { ns: 'common' })}
                        placeholder={t(`연도`, { ns: 'common' })}
                        data={year}
                        setData={setYear}
                        sx={{
                          width: 172,
                        }}
                      >
                        {YEARS.map((item) => {
                          return (
                            <MenuItem key={`item ${item}`} value={item}>
                              {item}
                            </MenuItem>
                          );
                        })}
                      </CustomSelectBox>
                    </Box>
                    <Stack direction='row' spacing={2}>
                      <DataBox
                        title={t(`나의데이터.총참여횟수`, { ns: 'mypage' })}
                        content={yearlyData?.userYearlyGameData?.totalGameCount}
                        subTitle={t(`게임`, { ns: 'common' })}
                        textAlign='left'
                      />
                      <DataBox
                        title={t(`나의데이터.최다멀티킬`, { ns: 'mypage' })}
                        content={yearlyData?.userYearlyGameData?.maxMultiKill}
                        subTitle={t(`킬`, { ns: 'common' })}
                        textAlign='left'
                      />
                      <DataBox
                        title={t(`나의데이터.최다연속킬`, { ns: 'mypage' })}
                        content={yearlyData?.userYearlyGameData?.maxConsecutiveKill}
                        subTitle={t(`연속킬`, { ns: 'common' })}
                        textAlign='left'
                      />
                    </Stack>
                    <Stack direction='row' spacing={2} mt={2}>
                      <DataBox
                        title={t(`나의데이터.누적킬`, { ns: 'mypage' })}
                        content={yearlyData?.userYearlyGameData?.sumKillCount}
                        subTitle={t(`킬`, { ns: 'common' })}
                        textAlign='left'
                      />
                      <DataBox
                        title={t(`나의데이터.누적데스`, { ns: 'mypage' })}
                        content={yearlyData?.userYearlyGameData?.sumDeathCount}
                        subTitle={t(`데스`, { ns: 'common' })}
                        textAlign='left'
                      />
                    </Stack>
                  </Stack>
                </DialogContent>
              </Dialog>
            }
          </HomeTable>
        )}

        {/*  탈퇴 유저 알림 */}
        {userError?.error_code === 1060 &&
          (allDataError?.error_code === 1046 || yearDataError?.error_code === 1046) && (
            <CustomModal
              title={t(`알림`, { ns: 'common' })}
              subTitle={t(`알림2`, { ns: 'rank' })}
              btn1={t(`확인`, { ns: 'common' })}
              open={isAlldataError}
              handleClose={handleClose}
              handleConfirm={handleClose}
            />
          )}
      </Box>
      {/* 최하단 문구 */}
      {/* <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 5 }}>
        <ErrorOutlineIcon color='primary' sx={{ width: 16 }} />
        <Typography variant='body2' color='primary.light' ml={2}>
          {t(`알림1`, { ns: 'rank' })}
        </Typography>
      </Box> */}
    </Box>
  );
}

export default StreamerRank;

const HomeTable = styled.div`
  width: 100%;
  border-top-color: #707070;
  border-collapse: separate;
  border-spacing: 0px 4px;

  > thead > tr {
    position: sticky;
    top: 0;
    background-color: #0e0e13;
    :nth-child(-n + 1) {
      border-top: 1px solid #707070;
    }
    padding: 16px;
    width: 100%;
    height: 56px;

    th {
      font-size: 12px;
      font-weight: bold;
      text-align: center;
      color: #817e90;
      vertical-align: middle;
    }
  }
  > tbody {
    max-height: 900px;
    overflow: auto;
  }
  > tbody > tr {
    :nth-child(-n + 1) {
      background-image: ${({ isLoggedIn }) =>
        isLoggedIn ? 'linear-gradient(93deg, #613389 1%, #0b8966)' : 'none'};
    }
    line-height: 3;
    letter-spacing: normal;
    color: #fff;
    text-align: center;
    color: #ffffff;
    vertical-align: middle;
    background-color: #1c1c21;
    height: 56px;
    width: 100%;
    td {
      line-height: 2.4;
      letter-spacing: normal;
      color: #fff;
      text-align: center;
      color: #ffffff;
      vertical-align: middle;
      width: 50px;
      :nth-of-type(1) {
        width: 10%;
      }
    }
  }
`;
