const colorToken = {
  // 보라색
  purple: {
    500: '#bf73ff',
    600: '#af51ff',
    700: '#8c40cc',
    800: '#462066',
    900: '#351a4d',
  },
  // 초록색
  green: {
    500: '#66fdd2',
    600: '#01fcb5',
    700: '#00c990',
    800: '#0b8966',
    900: '#0c4c3c',
  },
  // 주황색
  orange: {
    100: '#ff5858',
  },
  // 그레이색이야
  grey: {
    50: '#3b3b40',
    100: '#9e9ea0',
    200: '#707070',
    300: '#555559',
    400: '#3e3e42',
    500: '#343438',
    600: '#25252a',
    700: '#292831',
    800: '#1c1c21',
  },
  // 블랙
  black: {
    100: '#0e0e13',
    200: '#070709',
  },
};

export default colorToken;
