import { Box, Typography } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import { audienceGuide, streamerGuide } from '../../../lib/Guide/tabs';
import { audienceGuideVideo, streamerGuideVideo } from '../../../lib/Guide/videoList';
import VideoPlayer from './VideoPlayer';

const ManualLayout = () => {
  const { path } = useParams();
  const { pathname } = useLocation();
  const guide = pathname?.includes('streamer') ? streamerGuide : audienceGuide;
  const tabs = [{ title: guide[path as keyof typeof guide] }];

  console.log(streamerGuideVideo[path as keyof typeof streamerGuideVideo]);
  console.log(audienceGuideVideo[path as keyof typeof audienceGuideVideo]);

  return (
    <Box sx={{ padding: '32px' }}>
      {/* 탭 */}
      <Box>
        {tabs?.map((tab) => {
          return (
            <Box
              key={`tab ${tab.title}`}
              borderBottom={1}
              sx={{
                display: 'flex',
                alignItems: 'center',
                borderBottomColor: 'customMenuListColor.light',
              }}
            >
              <Typography
                variant='h6'
                sx={{ mr: 2, borderBottom: '4px solid #af51ff', paddingBottom: '8px' }}
              >
                {/* {t(`사용내역.${tab.title}`, { ns: 'mypage' })} */}
                {tab.title}
              </Typography>
            </Box>
          );
        })}
      </Box>
      {/* 컨텐츠 */}
      <Box sx={{ width: '825px' }}>
        {pathname.includes('streamer')
          ? streamerGuideVideo[path as keyof typeof streamerGuideVideo]?.map((video, idx) => {
              return (
                <>
                  {video.src.includes('images') ? (
                    <Box sx={{ textAlign: 'left' }} mt={5}>
                      <img src={video.src} alt={video.title ?? 'streamer manual'} width='100%' />
                      {video.text && (
                        <Typography variant='body2' sx={{ textAlign: 'left', lineHeight: '24px' }}>
                          <pre>{video.text}</pre>
                        </Typography>
                      )}
                    </Box>
                  ) : (
                    <Box mt={6} mb={2}>
                      <VideoPlayer
                        key={video.src + idx}
                        video={video}
                        controls
                        muted
                        playing
                        loop
                        pip
                        stopOnUnmount={false}
                        fallback={<img src='/images/manual/setting.png' alt='fallback' />}
                      />
                    </Box>
                  )}
                </>
              );
            })
          : audienceGuideVideo[path as keyof typeof audienceGuideVideo]?.map((video, idx) => {
              return (
                <>
                  {video.src.includes('images') ? (
                    <Box sx={{ textAlign: 'left' }} mt={5}>
                      <img src={video.src} alt={video.title ?? 'audience manual'} width='100%' />
                      {video.text && (
                        <Typography variant='body2' sx={{ textAlign: 'left', lineHeight: '24px' }}>
                          <pre>{video.text}</pre>
                        </Typography>
                      )}
                    </Box>
                  ) : (
                    <Box mt={6} mb={2}>
                      <VideoPlayer
                        key={video.src + idx}
                        video={video}
                        controls
                        muted
                        playing
                        loop
                        pip
                        stopOnUnmount={false}
                        fallback={<img src='/images/manual/fallbackImg.png' alt='fallback' />}
                      />
                    </Box>
                  )}
                </>
              );
            })}
      </Box>
    </Box>
  );
};

export default ManualLayout;
