import { Box, Container, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import VideoPlayer from '../Manual/components/VideoPlayer';

const tabs = [
  { title: 'E스포츠 랭킹', path: '/rank/esports' },
  { title: '스트리머 랭킹', path: '/rank' },
];

const video = {
  src: '/videos/reward/rank_subtitle_banner.mp4',
};
const Rank = () => {
  const { t } = useTranslation(['translation', 'rank']);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  // /rank로 진입하는 경우 바로 /rank/streamer로 리다이렉팅
  const subRoute = pathname.split('/')[2];
  if (!subRoute) {
    return <Navigate to='/rank/streamer' />;
  }

  return (
    <Container maxWidth='lg' sx={{ textAlign: 'center', position: 'relative' }}>
      <Stack direction='column'>
        {/* <PageTopGradient /> */}
        {/* 로고 */}
        <Box mt={12} mb={1}>
          {/* <Typography mt={16} variant='h2' component='h1'>
            {t('표제', { ns: 'rank' })}
         
          </Typography> */}
          <VideoPlayer video={video} muted playing loop pip />
        </Box>
        {/* 탭  - 임시 주석처리 22.08.16*/}
        <Box>
          {/* {tabs.map((tab) => {
            const active = pathname === tab.path;
            return (
              <Button
                key={`tab ${tab.title}`}
                variant={active ? 'contained' : 'outlined'}
                sx={{
                  py: 3,
                  px: 6,
                  borderRadius: 2,
                  fontSize: 18,
                  bgcolor: active ? (tab.title === 'E스포츠 랭킹' ? 'primary.dark' : 'secondary.dark') : '',
                  borderColor: active ? 'none' : 'customBorderColor.light',
                  color: active ? '#fff' : 'text.primary',
                  ml: 2,
                }}
                onClick={() => navigate(tab.path)}
              >
                {t(`${tab.title}`, { ns: 'rank' })}
              </Button>
            );
          })} */}
        </Box>

        {/* 컨텐츠 */}
        <Box>
          <Outlet />
        </Box>
      </Stack>
    </Container>
  );
};

export default Rank;
