import { Box, Button, Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const NotFound404Renewal = () => {
  const { t } = useTranslation(['translation', 'error', 'common']);

  const navigate = useNavigate();
  return (
    <Container
      maxWidth={false}
      sx={{
        width: '100vw',
        height: '100vh',
        bgcolor: 'background.default',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          width: 424,
          height: 300,
          color: '#fff',
          position: 'relative',
          textAlign: 'center',
          '&::before': backgroundStyling,
        }}
      >
        <Typography
          variant='h1'
          color='primary.main'
          sx={{
            fontFamily: 'Poppins',
            fontSize: 100,
          }}
        >
          404
        </Typography>
        <Typography variant='h3' color='#fff'>
          {t('404.타이틀', { ns: 'error' })}
        </Typography>
        <Typography variant='body2' mt={2} color='text.primary'>
          {t('404.본문', { ns: 'error' })}
          <br />
          {t('404.본문2', { ns: 'error' })}
        </Typography>
        <Box mt={9}>
          <Button
            variant='contained'
            sx={{ mr: 5, bgcolor: 'customMenuListColor.main' }}
            onClick={() => navigate(-1)}
          >
            {t('404.뒤로가기', { ns: 'error' })}
          </Button>
          <Button variant='contained' onClick={() => navigate('/')}>
            {t('404.메인으로', { ns: 'error' })}
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

const backgroundStyling = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%,-50%)',
  width: '1532px',
  height: '722px',
  display: 'block',
  content: '""',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
  backgroundImage: `url(${process.env.PUBLIC_URL}/images/background/404-bg.png)`,
  mixBlendMode: 'luminosity',
};

export default NotFound404Renewal;
