import { getRandomNumber } from '@lib/getRandomNumber';
import LockIcon from '@mui/icons-material/Lock';
import { Avatar, Box, Button, Chip, Stack, Typography } from '@mui/material';
import { BuyProps, RewardInfo, RewardList } from '@services/RewardServices';
import { t } from 'i18next';
import { useRecoilValue } from 'recoil';
import { userAtom } from '../../recoil/Auth/userAtom';

interface P {
  rewardData?: RewardList;
  reward: RewardInfo;
  onClick: (
    data: {
      randomNumber?: number;
    } & BuyProps,
    rate: number,
  ) => void;
}

const GoodsItem: React.FC<P> = ({ rewardData, reward, onClick }) => {
  const user = useRecoilValue(userAtom);
  return (
    <Box sx={rewardGoodsSxProps}>
      {reward.rate === 1 && (
        <Chip
          label={`${t('구매가능', { ns: 'reward' })}`}
          sx={{
            ...buyGoodsLabelSxProps,
            opacity: rewardData?.scheduleData.state === 3 ? '0.5' : '1',
          }}
        />
      )}

      <Box sx={{ padding: '24px 40px', mt: 5 }}>
        <Avatar
          variant='square'
          src={`https://image.decakill.com${reward?.imageUrl}`}
          alt='productImg'
          sx={goodsImgSxProps}
        />
      </Box>
      <Box sx={goodsDetailSxProps}>
        <Typography
          variant='h6'
          sx={{
            opacity: rewardData?.scheduleData.state === 3 ? '0.5' : '1',
          }}
        >
          {reward.name}
        </Typography>
        <Typography variant='body2'>
          {reward.goodsRemainValue}
          {`${t('갯수', { ns: 'common' })} ${t('남음', { ns: 'reward' })}`} /&nbsp;
          {`${t('총', { ns: 'common' })}`}&nbsp;
          {reward.goodsValue}
          {`${t('갯수', { ns: 'common' })}`}
        </Typography>
      </Box>
      <Stack direction='row' spacing={2} sx={priceBtnContainerSxProps}>
        {
          //  state:3 (마감)인 경우 disabled 및 기간 만료, 배경 딤 처리 추가
          rewardData?.scheduleData?.state === 3 ? (
            <Button
              disableRipple
              variant='contained'
              sx={{
                ...expiredBtnSxProps,
                opacity: rewardData?.scheduleData.state === 3 ? '0.5' : '1',
              }}
            >
              {`${t('기간종료', { ns: 'reward' })}`}
            </Button>
          ) : (
            reward.costData?.map((el) => {
              const { costTypeIdx, costValue } = el;

              return (
                <>
                  <Button
                    // state:1 (대기)인 경우 disabled 및 응모 lock
                    key={`costBtn ${el.costValue}`}
                    startIcon={
                      rewardData?.scheduleData?.state === 1 && (
                        <LockIcon
                          sx={{
                            color: 'customMenuListColor.light',
                          }}
                        />
                      )
                    }
                    // state:1(대기) 또는 3(마감)인 경우 버튼 disabled 처리
                    disabled={rewardData?.scheduleData?.state === 1 || rewardData?.scheduleData?.state === 3}
                    onClick={() => {
                      if (user) {
                        onClick(
                          {
                            uid: user!.uid,
                            gemType: costTypeIdx,
                            gem: costValue,
                            rewardGoodsIdx: reward.idx,
                            randomNumber: getRandomNumber(1, 1 / reward.rate) ?? undefined,
                            regdate: user!.regdate,
                          },
                          reward.rate,
                        );
                      } else {
                        alert(`${t('alert', { ns: 'reward' })}`);
                      }
                    }}
                    variant='contained'
                    sx={{ bgcolor: 'background.default', width: '100%', borderRadius: 2 }}
                  >
                    <Avatar
                      sx={{
                        width: 24,
                        height: 24,
                      }}
                      alt='gem'
                      src={`/images/icon/ico-${el.costTypeIdx === 2 ? 'streamer' : 'league'}-pt.svg`}
                    />
                    <Typography
                      variant='body2'
                      sx={{
                        color: el.costTypeIdx === 2 ? 'secondary.main' : 'primary.main',
                      }}
                    >
                      {el.costValue.toLocaleString()}
                    </Typography>
                  </Button>
                </>
              );
            })
          )
        }
      </Stack>
    </Box>
  );
};

export default GoodsItem;

const rewardGoodsSxProps = {
  position: 'relative',
  width: 'calc(25% - 8px)',
  mb: 2,
  ':not(:last-of-type)': {
    mr: 2,
  },
  bgcolor: 'background.modal',
  backgroundImage: `url(${process.env.PUBLIC_URL}/images/background/reward-bg-gradient.png)`,
  backgroundSize: 'cover',
  borderRadius: 2,
};

const buyGoodsLabelSxProps = {
  position: 'absolute',
  top: '12px',
  left: '16px',
  backgroundImage: 'linear-gradient(109deg, #613389 3%, #0b8966 100%);',
  color: 'white',
  borderRadius: 1,
  fontWeight: 'bold',
  fontSize: 13,
  '&.MuiChip-root': {
    height: '20px',
  },
  '& .MuiChip-label': {
    padding: '2px',
  },
};

const goodsImgSxProps = {
  width: 199,
  height: 199,
  '& .MuiAvatar-img': {
    objectFit: 'contain',
  },
};

const goodsDetailSxProps = {
  p: 6,
  borderTop: '1px solid',
  borderColor: 'customMenuListColor.light',
  textAlign: 'left',
};

const priceBtnContainerSxProps = {
  p: 6,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

const expiredBtnSxProps = {
  bgcolor: 'background.default',
  width: '100%',
  borderRadius: 2,
};
