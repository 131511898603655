import { axiosGet } from './axios/axios';
import { CustomStatus } from './RankServices';

interface StreamerList {
  broadcaster_id: string;
  broadcaster_login: string;
}

interface LiveStreamerResp {
  status: CustomStatus;
  streamerList: StreamerList[];
}
class HomeServices {
  getLiveStreamer = (): Promise<LiveStreamerResp> => {
    return new Promise((resolve, reject) => {
      axiosGet({
        url: '/live',
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export default new HomeServices();
