import { getTierByRankingRatio } from '@lib/Rank/getTierByRanking';
import { Avatar, Box, Chip, Stack, Typography } from '@mui/material';
import { getKDA } from '@pages/Rank/screens/DaliyRank';
import { RankData } from '@services/RankServices';
import { useRecoilValue } from 'recoil';
import { userAtom, userProfileAtom } from '../../recoil/Auth/userAtom';

interface P {
  data: RankData[] | null;
  onClick?: () => void;
}

const MyRank: React.FC<P> = ({ data, onClick }) => {
  const user = useRecoilValue(userAtom);
  const userProfile = useRecoilValue(userProfileAtom);

  return (
    <>
      {data?.map((data) => {
        console.log(data);
        return (
          <Stack
            direction='row'
            sx={{
              p: 4,
              my: 2,
              borderRadius: 2,
              color: 'white',
              display: 'flex',
              alignItems: 'center',
              background: 'linear-gradient(93deg, #613389 1%, #0b8966)',
              '&:hover': {
                background: 'linear-gradient(93deg, #6E36A0 1%, #069C74)',
              },
            }}
            onClick={onClick}
          >
            <Box width='10%'>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Chip
                  label='MY'
                  sx={{
                    padding: '8px',
                    height: '28px',
                    bgcolor: 'background.default',
                    color: 'white',
                    borderRadius: 1,
                    fontWeight: 'bold',
                    mr: 2,
                    fontSize: 13,
                    '& .MuiChip-label': {
                      padding: '2px 0',
                    },
                  }}
                />
                {data?.ranking ? (
                  <Typography variant='h6' sx={{ color: '#fff' }}>
                    {data?.ranking}
                  </Typography>
                ) : (
                  '-'
                )}
              </Box>
            </Box>
            <Box
              width='12.7%'
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1 }}
            >
              {data?.ranking ? (
                <>
                  <Box width={24}>
                    <img
                      width='100%'
                      height='100%'
                      style={{ marginRight: 4 }}
                      alt='tier'
                      src={getTierByRankingRatio(data?.rankingRatio).imgUrl}
                    />
                  </Box>
                  <Typography variant='body2' sx={{ color: '#fff', fontWeight: 'bold' }}>
                    {getTierByRankingRatio(data?.rankingRatio).rank}
                  </Typography>
                </>
              ) : (
                '-'
              )}
            </Box>
            <Box width='28.3%' sx={{ display: 'flex', justifyContent: 'flex-start', ml: 3 }}>
              {user?.uid && (
                <>
                  <Avatar
                    src={userProfile?.profile_image_url}
                    alt='profile'
                    variant='square'
                    sx={{
                      width: 24,
                      height: 24,
                      borderRadius: 1,
                    }}
                  />
                  <Typography
                    variant='h6'
                    sx={{
                      ml: 3,
                      color: '#fff',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                    }}
                  >
                    {userProfile?.display_name}
                  </Typography>
                </>
              )}
            </Box>
            <Box width='8.5%' sx={{ fontWeight: 'bold', color: '#fff' }}>
              {data?.gameCount ?? '-'}
            </Box>
            <Box width='8.5%' sx={{ fontWeight: 'bold', color: '#fff' }}>
              {data?.kill ?? '-'}
            </Box>

            <Box width='8.5%' sx={{ fontWeight: 'bold', color: '#fff' }}>
              {data?.comboKill ?? '-'}
            </Box>
            <Box width='8.5%' sx={{ fontWeight: 'bold', color: '#fff' }}>
              {/* {data?.multiKill ?? '-'} */}
              {data?.multiKill ? (data?.multiKill >= 10 ? 10 : data?.multiKill) : '-'}
            </Box>
            <Box width='8.5%' sx={{ fontWeight: 'bold', color: '#fff' }}>
              {data?.death ?? '-'}
            </Box>
            <Box width='8.5%' sx={{ fontWeight: 'bold', color: '#fff' }}>
              {data ? getKDA(data) : '-'}
            </Box>
          </Stack>
        );
      })}
      {!data && (
        <Stack
          direction='row'
          sx={{
            p: 4,
            my: 2,
            borderRadius: 2,
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            background: 'linear-gradient(93deg, #613389 1%, #0b8966)',
            '&:hover': {
              background: 'linear-gradient(93deg, #6E36A0 1%, #069C74)',
            },
          }}
          onClick={onClick}
        >
          <Box width='10%'>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Chip
                label='MY'
                sx={{
                  padding: '8px',
                  height: '28px',
                  bgcolor: 'background.default',
                  color: 'white',
                  borderRadius: 1,
                  fontWeight: 'bold',
                  mr: 2,
                  fontSize: 13,
                  '& .MuiChip-label': {
                    padding: '2px 0',
                  },
                }}
              />
              -
            </Box>
          </Box>
          <Box
            width='12.7%'
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1 }}
          >
            -
          </Box>
          <Box width='28.3%' sx={{ display: 'flex', justifyContent: 'flex-start', ml: 3 }}>
            {user?.uid && (
              <>
                <Avatar
                  src={userProfile?.profile_image_url}
                  alt='profile'
                  variant='square'
                  sx={{
                    width: 24,
                    height: 24,
                    borderRadius: 1,
                  }}
                />
                <Typography
                  variant='h6'
                  sx={{
                    ml: 3,
                    color: '#fff',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                >
                  {userProfile?.display_name}
                </Typography>
              </>
            )}
          </Box>
          <Box width='8.5%' sx={{ fontWeight: 'bold', color: '#fff' }}>
            -
          </Box>
          <Box width='8.5%' sx={{ fontWeight: 'bold', color: '#fff' }}>
            -
          </Box>
          <Box width='8.5%' sx={{ fontWeight: 'bold', color: '#fff' }}>
            -
          </Box>
          <Box width='8.5%' sx={{ fontWeight: 'bold', color: '#fff' }}>
            -
          </Box>
          <Box width='8.5%' sx={{ fontWeight: 'bold', color: '#fff' }}>
            -
          </Box>
          <Box width='8.5%' sx={{ fontWeight: 'bold', color: '#fff' }}>
            -
          </Box>
        </Stack>
      )}
    </>
  );
};

export default MyRank;
