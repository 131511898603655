import { atom, AtomEffect, selector } from 'recoil';

type User = {
  regdate: string;
  token: string;
  uid: string;
};

type UserProfile = {
  broadcaster_type: string;
  created_at: string;
  description: string;
  display_name: string;
  id: string;
  login: string;
  offline_image_url: string;
  profile_image_url: string;
  type: string;
  view_count: number;
};

export type Token = {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  token_type: string;
};

const SessionEffect =
  (key: string): AtomEffect<User | any | undefined> =>
  ({ setSelf, onSet }) => {
    const sessionUser = sessionStorage.getItem(key);

    if (sessionUser && sessionUser !== 'undefined') {
      setSelf(JSON.parse(sessionUser));
    }

    onSet((newValue, oldValue, isReset) => {
      // eslint-disable-next-line no-unused-expressions
      isReset ? sessionStorage.removeItem(key) : sessionStorage.setItem(key, JSON.stringify(newValue));
    });
  };

export const userAtom = atom<User | undefined>({
  key: 'user',
  default: undefined,
  effects: [SessionEffect('user'), () => {}],
});

export const userProfileAtom = atom<UserProfile | undefined>({
  key: 'userProfile',
  default: undefined,
  effects: [SessionEffect('userProfile'), () => {}],
});

export const twitchTokenAtom = atom<Token | undefined>({
  key: 'twitchToken',
  default: undefined,
  effects: [SessionEffect('twithToken'), () => {}],
});

// export const userTokenAtom = selector<string | undefined>({
//   key: 'userToken',
//   get: ({ get }) => {
//     const user = get(userAtom);
//     return user ? user.token : undefined;
//   },
//   set: ({ get, set }, newValue) => {
//     const user = get(userAtom);
//     if (user) {
//       set(userAtom, { ...user, token: newValue as string });
//     }
//   },
// });

// export const userLanguageAtom = selector<string>({
//   key: 'userLanguage',
//   get: ({ get }) => {
//     const user = get(userAtom);
//     return user ? user.lang : 'ko';
//   },
//   set: ({ get, set }, newValue) => {
//     const user = get(userAtom);
//     if (user) {
//       set(userAtom, { ...user, lang: newValue as string });
//     }
//   },
// });
