import { Avatar, Box, CircularProgress, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useRecoilValue } from 'recoil';
import { userAtom } from '../../../recoil/Auth/userAtom';
import MyPageServices, { errObj, getTotalPoint } from '../../../services/MyPageServices';

const tabs = [{ title: '젬획득내역', subTitle: '상단알림' }];

const displayStatus = (state1: string | null, state2: string | null, state3: number) => {
  const status = {
    statement: '',
    color: '',
  };
  if (state1 && state2 === null) {
    if (state3 === 1) {
      status.statement = 'E스포츠';
      status.color = 'primary.main';
    } else if (state3 === 2) {
      status.statement = '스트리머';
      status.color = 'secondary.light';
    }
  } else if (state1 === null && state2) {
    status.statement = '환불';
    status.color = 'text.primary';
  }
  return status;
};

const AcquisitionHistory = () => {
  const user = useRecoilValue(userAtom);
  const { t } = useTranslation(['translation', 'mypage', 'common', 'error']);

  const {
    mutate: getTotalPointMutate,
    data,
    isLoading,
    error,
    isError,
  } = useMutation((data: getTotalPoint) => MyPageServices.getTotalPoint(data));

  useEffect(() => {
    if (user) {
      getTotalPointMutate({
        uid: user?.uid,
        regdate: user?.regdate,
      });
    }
  }, []);

  return (
    <Box sx={{ borderRadius: 2, bgcolor: 'background.modal', padding: '32px' }}>
      <Box>
        {tabs.map((tab) => {
          return (
            <Box
              borderBottom={1}
              sx={{
                display: 'flex',
                alignItems: 'center',
                borderBottomColor: 'customMenuListColor.light',
              }}
            >
              <Typography
                variant='h6'
                sx={{ mr: 2, borderBottom: '4px solid #af51ff', paddingBottom: '8px' }}
              >
                {t(`획득내역.${tab.title}`, { ns: 'mypage' })}
              </Typography>
              <Typography
                variant='body2'
                sx={{
                  paddingBottom: '8px',
                  fontSize: '13px',
                }}
              >
                {t(`획득내역.${tab.subTitle}`, { ns: 'mypage' })}
              </Typography>
            </Box>
          );
        })}
      </Box>
      {/* 컨텐츠 */}
      {isError && (error as errObj)?.error_code === 1023 ? (
        <Box sx={{ minHeight: 410, textAlign: 'center', py: 50 }}>
          {t(`획득내역.내역없음`, { ns: 'mypage' })}
        </Box>
      ) : isLoading ? (
        <Box sx={{ minHeight: 410, textAlign: 'center', py: 50 }}>
          <CircularProgress sx={{ color: 'primary.main' }} disableShrink />
        </Box>
      ) : (
        <Box sx={{ minHeight: 410 }}>
          {data?.allPointData?.map((el) => {
            return (
              <Stack
                direction='row'
                sx={{
                  padding: '30px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  borderBottom: '1px solid',
                  borderColor: '#3e3e42',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ width: 60, height: 60, borderRadius: 2, mr: 6 }}>
                    <Avatar
                      src={
                        el?.broadcasterProfileImageUrl
                          ? el?.broadcasterProfileImageUrl
                          : `https://image.decakill.com${el?.rewardGoodsImageUrl}`
                      }
                      variant='square'
                      alt='profile'
                      sx={{ width: '100%', height: '100%' }}
                    />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography
                      sx={{
                        fontSize: 13,
                        fontWeight: 'bold',
                        color: displayStatus(el.broadcasterDisplayName, el.rewardGoodsName, el.channelType)
                          .color,
                      }}
                    >
                      {t(
                        `획득내역.${
                          displayStatus(el.broadcasterDisplayName, el.rewardGoodsName, el.channelType)
                            .statement
                        }`,
                        { ns: 'mypage' },
                      )}
                    </Typography>
                    <Typography sx={{ fontSize: 15, fontWeight: 'bold', color: 'white' }}>
                      {el.broadcasterDisplayName && el.rewardGoodsName === null
                        ? el.broadcasterDisplayName
                        : el.rewardGoodsName}
                    </Typography>
                  </Box>
                </Box>
                <Stack
                  direction='row'
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    flexBasis: '200px',
                  }}
                >
                  <Box sx={{ textAlign: 'center', mr: 10 }}>
                    <Typography variant='subtitle2'>{t(`획득내역.획득날짜`, { ns: 'mypage' })}</Typography>
                    <Typography variant='body2' sx={{ fontWeight: 'bold', color: 'white' }}>
                      {el.regdate.slice(2, 10).replaceAll('-', '.')}
                    </Typography>
                  </Box>
                  <Box sx={{ textAlign: 'center' }}>
                    <Typography variant='subtitle2'>{t(`획득내역.획득젬`, { ns: 'mypage' })}</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center' }}>
                      <Avatar
                        src={`/images/icon/ico-${el.channelType === 1 ? 'league-pt' : 'streamer-pt'}.svg`}
                        alt='pointIcon'
                        sx={{ width: 15, height: 21, mr: 2 }}
                      />
                      <Typography
                        variant='body2'
                        sx={{
                          color: el.channelType === 1 ? 'primary.light' : 'secondary.main',
                        }}
                      >
                        {el.point.toLocaleString()}
                      </Typography>
                    </Box>
                  </Box>
                </Stack>
              </Stack>
            );
          })}
        </Box>
      )}
    </Box>
  );
};

export default AcquisitionHistory;
