import RouteChangeTracker from '@components/RouteChangeTracker';
import { pathGuide, pathMyPage, pathPrivacyPolicy, pathRank, pathReward, pathTerms } from '@lib/pagePath';
import Home from '@pages/Home/HomeContent';
import ManualLayout from '@pages/Manual/components/ManualLayout';
import AudienceManual from '@pages/Manual/screens/AudienceManual';
import StreamerManual from '@pages/Manual/screens/StreamerManual';
import MyPage from '@pages/MyPage/MyPage';
import AcquisitionHistory from '@pages/MyPage/screens/AcquisitionHistory';
import ApplicationHistory from '@pages/MyPage/screens/ApplicationHistory';
import DeleteAccount from '@pages/MyPage/screens/DeleteAccount';
import Inquiry from '@pages/MyPage/screens/Inquiry';
import MyData from '@pages/MyPage/screens/MyData';
import MyInfo from '@pages/MyPage/screens/MyInfo';
import NotFound404Renewal from '@pages/NotFound/NotFound404Renewal';
import Rank from '@pages/Rank/Rank';
import DaliyRank from '@pages/Rank/screens/DaliyRank';
import StreamerRank from '@pages/Rank/screens/StreamerRank';
import Streamer from '@pages/Rank/Streamer';
import Reward from '@pages/Reward/Reward';
import StreamerReward from '@pages/Reward/screens/StreamerReward';
import PrivacyPolicy from '@pages/Terms/PrivacyPolicy';
import StreamerTerms from '@pages/Terms/screens/StreamerTerms';
import UserTerms from '@pages/Terms/screens/UserTerms';
import Terms from '@pages/Terms/Terms';
import { BrowserRouter as Router, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import Layout from './Layout';
import { audienceGuide, streamerGuide } from './lib/Guide/tabs';
import Manual from './Pages/Manual/Manual';
import { userAtom } from './recoil/Auth/userAtom';

function Routor() {
  const user = useRecoilValue(userAtom);

  return (
    <Router>
      <RouteChangeTracker />
      <Routes>
        <Route element={<Layout />}>
          <Route index element={<Home />} />
          {/* 랭킹 */}
          <Route path={pathRank} element={<Rank />}>
            {/* <Route index element={<Streamer />} /> */}
            {/* 스트리머 랭킹 */}
            <Route path='streamer' element={<Streamer />}>
              <Route index element={<StreamerRank />} />
              <Route path='daily' element={<DaliyRank />} />
            </Route>
            {/* 이스포츠 랭킹  - 임시 주석처리 */}
            {/* <Route path='esports' element={<Esports />}>
              <Route index element={<ESportsRank />} />
              <Route path='daily' element={<EsportsDailyRank />} />
            </Route> */}
          </Route>
          {/* 스토어 */}
          <Route path={pathReward} element={<Reward />}>
            <Route index element={<StreamerReward />} />
            {/* <Route index element={<CommingSoon />} /> */}
            {/* <Route  path='esports'element={<ESportsReward />} /> */}
            {/* <Route index element={<ESportsReward />} /> */}
          </Route>
          {/* 마이페이지 */}
          <Route
            path={pathMyPage}
            element={
              <ProtectedRoute user={user}>
                <MyPage />
              </ProtectedRoute>
            }
          >
            <Route index element={<MyData />} />
            <Route path='info' element={<MyInfo />} />
            <Route path='application' element={<ApplicationHistory />} />
            <Route path='acquisition' element={<AcquisitionHistory />} />
            <Route path='delete-account' element={<DeleteAccount />} />
            <Route path='inquiry' element={<Inquiry />} />
          </Route>
          {/* 사용자 가이드 */}
          <Route path={pathGuide} element={<Manual />}>
            <Route index element={<StreamerManual tabs={streamerGuide} />} />
            <Route path='streamer' element={<StreamerManual tabs={streamerGuide} />}>
              <Route index element={<ManualLayout />} />
              <Route path=':path' element={<ManualLayout />} />
            </Route>
            <Route path='audience' element={<AudienceManual tabs={audienceGuide} />}>
              <Route index element={<ManualLayout />} />
              <Route path=':path' element={<ManualLayout />} />
            </Route>
          </Route>
          {/* 이용약관 */}
          <Route path={pathTerms} element={<Terms />}>
            <Route index element={<StreamerTerms />} />
            <Route path='user' element={<UserTerms />} />
          </Route>
          {/* 개인정보 취급방침 */}
          <Route path={pathPrivacyPolicy} element={<PrivacyPolicy />} />
        </Route>
        {/* 404 */}
        <Route path='*' element={<NotFound404Renewal />} />

        {/* test */}
      </Routes>
    </Router>
  );
}

export default Routor;

const ProtectedRoute = ({ user, children, redirectPath = '/' }) => {
  if (!user) {
    return <Navigate to={redirectPath} replace />;
  }
  return children ?? <Outlet />;
};
