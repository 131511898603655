import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Container,
  Stack,
  Typography,
  styled,
  Select,
  CircularProgress,
  MenuItem,
} from '@mui/material';
import { useMutation, useQuery } from 'react-query';
import MyPageServices, { getAllData, getYearlyData } from '../../../services/MyPageServices';
import { useRecoilValue } from 'recoil';
import { userAtom } from '../../../recoil/Auth/userAtom';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import DataBox from '../components/DataBox';

import { useTranslation } from 'react-i18next';
// import CustomSelectBox from '../../../components/CustomSelectBox';
import CustomSelectBox from '../../../components/customSelectBox';
import RankServices from '../../../services/RankServices';

const tabs = [{ title: '기록실' }];

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 218,
    padding: '12px',
    color: '#9e9ea0',
    backgroundColor: '#1c1c21',
    textAlign: 'center',
    fontSize: '13px',
    lineHeight: 1.6,
  },
});

const LEAGUES = [
  {
    league: 'LCK',
    channelId: '123123123',
  },
];

const LEAGUES_ARR = ['LCK'];

const YEARS = ['2021', '2022'];

const MyData = () => {
  const { t } = useTranslation(['translation', 'mypage', 'common', 'popup']);
  const [league, setLeague] = useState('LCK');
  const [streamer, setStreamer] = useState('totalData');
  const [year, setYear] = useState('2022');

  const user = useRecoilValue(userAtom);

  // 스트리머 리스트 가져오기
  const { data: streamerData } = useQuery(['streamerList'], () => RankServices.getStreamerList(), {
    refetchOnWindowFocus: false,
  });

  // 젬
  const {
    mutate: getGemInfoMutate,
    data: gemData,
    isLoading: isGemLoading,
  } = useMutation((uid: string) => MyPageServices.getGemInfo({ uid }));

  // 전체 기록
  const {
    mutate: getAllDataMutate,
    data: allData,
    isLoading: isAllDataLoading,
  } = useMutation((data: getAllData) => MyPageServices.getAllData(data));

  // 연도별 기록
  const {
    mutate: getYearlyDataMutate,
    data: yearlyData,
    isLoading: isYearDataLoading,
  } = useMutation((data: getYearlyData) => MyPageServices.getYearlyData(data));

  useEffect(() => {
    if (user) {
      getGemInfoMutate(user?.uid);
    }
  }, []);

  useEffect(() => {
    if (user) {
      getAllDataMutate({
        uid: user?.uid,
        channelId: streamer !== 'totalData' ? streamer : null,
      });
    }
  }, [streamer]);

  useEffect(() => {
    if (user) {
      getYearlyDataMutate({
        uid: user?.uid,
        channelId: streamer !== 'totalData' ? streamer : null,
        year: Number(year), // default: 2022
      });
    }
  }, [streamer, year]);

  return (
    <Box sx={{ padding: '0 0 16px 0' }}>
      <Typography variant='subtitle1' sx={{ textAlign: 'end' }}>
        {t('나의데이터.알림', { ns: 'mypage' })}
      </Typography>
      {/* 컬러 박스 */}
      <Stack spacing={4} direction='row' sx={{ mt: 2, mb: 5 }}>
        {/* 이스포츠젬 */}
        <Box
          sx={{
            flex: 1,
            bgcolor: 'primary.dark',
            padding: '20px 32px',
            borderRadius: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Avatar src='/images/icon/ico-league-pt.svg' alt='esportGem' sx={{ width: 52, height: 52 }} />
            <Typography
              color='white'
              sx={{
                fontSize: 15,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              {t('나의데이터.퍼플젬', { ns: 'mypage' })}
              <CustomWidthTooltip
                title={t('나의데이터.퍼플젬툴팁', { ns: 'mypage' })}
                arrow
                placement='top'
                sx={{
                  maxWidth: 218,
                  '& .MuiTooltip-arrow': {
                    color: 'background.modal',
                  },
                }}
              >
                <Avatar
                  src='/images/icon/ico-info-02.png'
                  alt='infoIcon'
                  sx={{
                    width: '20px',
                    height: '20px',
                    ml: 1,
                  }}
                />
              </CustomWidthTooltip>
            </Typography>
          </Box>
          <Box>
            {isGemLoading ? (
              <Typography variant='body2' sx={{ color: 'primary.light' }}>
                <CircularProgress sx={{ color: 'primary.light' }} disableShrink size={16} />
              </Typography>
            ) : (
              <Typography variant='h3' sx={{ color: 'primary.light' }}>
                {gemData?.gemData.purpleGem.toLocaleString()}
              </Typography>
            )}
          </Box>
        </Box>
        {/* 스트리머젬 */}
        <Box
          sx={{
            flex: 1,
            bgcolor: 'secondary.dark',
            padding: '20px 32px',
            borderRadius: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Avatar src='/images/icon/ico-streamer-pt.svg' alt='esportGem' sx={{ width: 52, height: 52 }} />
            <Typography
              color='white'
              sx={{
                fontSize: 15,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              {t('나의데이터.그린젬', { ns: 'mypage' })}
              <CustomWidthTooltip
                title={t('나의데이터.그린젬툴팁', { ns: 'mypage' })}
                arrow
                placement='top'
                sx={{
                  '& .MuiTooltip-arrow': {
                    color: 'background.modal',
                  },
                }}
              >
                <Avatar
                  src='/images/icon/ico-info-02.png'
                  alt='infoIcon'
                  sx={{
                    width: '20px',
                    height: '20px',
                    ml: 1,
                  }}
                />
              </CustomWidthTooltip>
            </Typography>
          </Box>
          <Box>
            {isGemLoading ? (
              <Typography variant='body2' sx={{ color: 'secondary.main' }}>
                <CircularProgress sx={{ color: 'secondary.main' }} disableShrink size={16} />
              </Typography>
            ) : (
              <Typography variant='h3' sx={{ color: 'secondary.main' }}>
                {gemData?.gemData.greenGem.toLocaleString()}
              </Typography>
            )}
          </Box>
        </Box>
      </Stack>

      {/* 탭 */}
      <Box sx={{ bgcolor: 'background.modal', padding: '32px', borderRadius: 2 }}>
        {tabs.map((tab) => {
          return (
            <Box
              borderBottom={1}
              sx={{
                color: 'white',
                fontWeight: 'bold',
                borderBottomColor: 'customMenuListColor.light',
                pb: 4,
              }}
            >
              <span style={{ borderBottom: '4px solid #af51ff', paddingBottom: '10px' }}>
                {t(`나의데이터.${tab.title}`, { ns: 'mypage' })}
              </span>
            </Box>
          );
        })}
        {
          // isAllDataLoading || isYearDataLoading ? (
          //   <Box sx={{ minHeight: 384, position: 'relative' }}>
          //     <CircularProgress
          //       sx={{
          //         color: 'primary.main',
          //         position: 'absolute',
          //         top: '50%',
          //         left: '50%',
          //       }}
          //       disableShrink
          //     />
          //   </Box>
          // )
          // :
          <Box
            sx={{
              filter: isAllDataLoading || isYearDataLoading ? 'blur(5px)' : 'blur(0px)',
              transition: 'filter 0.3s',
            }}
          >
            {/* 전체 기록 */}
            <Stack mt={6}>
              <Box sx={{ pb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant='h6'>{t(`나의데이터.전체기록`, { ns: 'mypage' })}</Typography>
                {/* <CustomSelectBox
                  label={t('리그', { ns: 'common' })}
                  placeholder={t('리그', { ns: 'common' })}
                  data={league}
                  setData={setLeague}
                  sx={{
                    width: 172,
                  }}
                >
                  {LEAGUES_ARR.map((item) => {
                    return (
                      <MenuItem key={`item ${item}`} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </CustomSelectBox> */}
                <CustomSelectBox
                  label={t('스트리머', { ns: 'common' })}
                  placeholder={t('스트리머', { ns: 'common' })}
                  data={streamer}
                  setData={setStreamer}
                  sx={{
                    width: 172,
                  }}
                >
                  <MenuItem value='totalData'>전체 스트리머</MenuItem>
                  {streamerData?.streamerList?.map((item) => {
                    return (
                      <MenuItem key={`item ${item.idx}`} value={item.broadcastId}>
                        {item.displayName}
                      </MenuItem>
                    );
                  })}
                </CustomSelectBox>
              </Box>
              <Stack direction='row' spacing={3}>
                <DataBox
                  title={t(`나의데이터.총참여횟수`, { ns: 'mypage' })}
                  content={allData?.userAllGameData?.totalGameCount}
                  subTitle={t(`게임`, { ns: 'common' })}
                />
                <DataBox
                  title={t(`나의데이터.최다멀티킬`, { ns: 'mypage' })}
                  content={allData?.userAllGameData?.maxMultiKill}
                  subTitle={t(`킬`, { ns: 'common' })}
                />
                <DataBox
                  title={t(`나의데이터.최다연속킬`, { ns: 'mypage' })}
                  content={allData?.userAllGameData?.maxConsecutiveKill}
                  subTitle={t(`연속킬`, { ns: 'common' })}
                />
                <DataBox
                  title={t(`나의데이터.누적킬`, { ns: 'mypage' })}
                  content={allData?.userAllGameData?.sumKillCount}
                  subTitle={t(`킬`, { ns: 'common' })}
                />
                <DataBox
                  title={t(`나의데이터.누적데스`, { ns: 'mypage' })}
                  content={allData?.userAllGameData?.sumDeathCount}
                  subTitle={t(`데스`, { ns: 'common' })}
                />
              </Stack>
            </Stack>
            {/* 평균 킬 카운트, 데스 카운트 */}
            <Stack spacing={3} direction='row' sx={{ my: 3 }}>
              <Box
                border={1}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  flex: 1,
                  borderColor: '#343438',
                  padding: '18px 24px',
                  borderRadius: 2,
                }}
              >
                <Typography variant='subtitle2'>{t(`나의데이터.평균킬`, { ns: 'mypage' })}</Typography>
                <Typography variant='body2' color='primary.light'>
                  {allData?.userAllGameData?.avgKillCount ? allData?.userAllGameData?.avgKillCount : '-'}
                </Typography>
              </Box>
              <Box
                border={1}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  flex: 1,
                  borderColor: '#343438',
                  padding: '18px 24px',
                  borderRadius: 2,
                }}
              >
                <Typography variant='subtitle2'>{t(`나의데이터.평균데스`, { ns: 'mypage' })}</Typography>
                <Typography variant='body2' color='primary.light'>
                  {allData?.userAllGameData?.avgDeathCount ? allData?.userAllGameData?.avgDeathCount : '-'}
                </Typography>
              </Box>
            </Stack>
            {/* 연도 기록 */}
            <Stack>
              <Box sx={{ pb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant='h6'>{t(`나의데이터.연도기록`, { ns: 'mypage' })}</Typography>
                <CustomSelectBox
                  label={t('연도', { ns: 'common' })}
                  placeholder={t('연도', { ns: 'common' })}
                  data={year}
                  setData={setYear}
                  sx={{
                    width: 172,
                  }}
                >
                  {YEARS.map((item) => {
                    return (
                      <MenuItem key={`item ${item}`} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </CustomSelectBox>
              </Box>
              <Stack direction='row' spacing={3}>
                <DataBox
                  title={t(`나의데이터.총참여횟수`, { ns: 'mypage' })}
                  content={yearlyData?.userYearlyGameData?.totalGameCount}
                  subTitle={t(`게임`, { ns: 'common' })}
                />
                <DataBox
                  title={t(`나의데이터.최다멀티킬`, { ns: 'mypage' })}
                  content={yearlyData?.userYearlyGameData?.maxMultiKill}
                  subTitle={t(`킬`, { ns: 'common' })}
                />
                <DataBox
                  title={t(`나의데이터.최다연속킬`, { ns: 'mypage' })}
                  content={yearlyData?.userYearlyGameData?.maxConsecutiveKill}
                  subTitle={t(`연속킬`, { ns: 'common' })}
                />
                <DataBox
                  title={t(`나의데이터.누적킬`, { ns: 'mypage' })}
                  content={yearlyData?.userYearlyGameData?.sumKillCount}
                  subTitle={t(`킬`, { ns: 'common' })}
                />
                <DataBox
                  title={t(`나의데이터.누적데스`, { ns: 'mypage' })}
                  content={yearlyData?.userYearlyGameData?.sumDeathCount}
                  subTitle={t(`데스`, { ns: 'common' })}
                />
              </Stack>
            </Stack>
          </Box>
        }
      </Box>
    </Box>
  );
};

export default MyData;
