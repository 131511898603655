import React from 'react';
import { Box, Typography } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import { termsForStreamer } from '../../../lib/termsAndConditions/terms';
import remarkGfm from 'remark-gfm';

const StreamerTerms = () => {
  return (
    <Box sx={MDXSx}>
      <ReactMarkdown children={termsForStreamer} remarkPlugins={[remarkGfm]} />
    </Box>
  );
};

export default StreamerTerms;

const MDXSx = {
  textAlign: 'left',
  width: '1000px',
  margin: '32px auto',
  bgcolor: 'background.modal',
  padding: '32px',
  borderRadius: 2,
  fontSize: 15,
  fontWeight: 'regular',
  color: 'text.primary',
  lineHeight: '24px',

  h2: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#fff',
    margin: '12px 0',
  },

  h3: {
    fontSize: 15,
    fontWeight: 'bold',
    color: '#fff',
    margin: '12px 0',
  },

  ul: {
    listStyle: 'disc',
    listStylePosition: 'inside',
    '& >li>ul': {
      paddingLeft: 5,
      listStyle: 'circle',
      listStylePosition: 'inside',
      '& >li>ul': {
        paddingLeft: 5,
        listStyle: 'square',
        listStylePosition: 'inside',
      },
    },
  },

  ol: {
    listStyle: 'decimal',
    listStylePosition: 'inside',
    '& >li>ol': {
      paddingLeft: 5,
      listStyle: 'circle',
      listStylePosition: 'inside',
      '& >li>ol': {
        paddingLeft: 5,
        listStyle: 'square',
        listStylePosition: 'inside',
      },
    },
  },

  table: {
    width: '1175px',

    '& >thead>tr>th': {
      padding: '12px 0',
      bgcolor: 'customMenuListColor.main',
      border: '1px solid #3e3e42',
    },
    '& >tbody>tr>td': {
      padding: '12px 0',
      border: '1px solid #3e3e42',
    },
  },
};
