import { Box, BoxProps, Typography } from '@mui/material';
import React from 'react';

interface P extends BoxProps {
  title: string;
  content: number | undefined;
  subTitle: string;
}

const DataBox: React.FC<P> = ({ title, content, subTitle, ...props }) => {
  return (
    <Box
      sx={{
        flex: 1,
        borderRadius: 2,
        padding: '20px 24px',
        bgcolor: 'customMenuListColor.main',
      }}
      {...props}
    >
      <Typography variant='subtitle2'>{title}</Typography>
      <Typography variant='h4' component='p' sx={{ color: 'white' }}>
        {content === undefined ? '-' : content} {subTitle}
      </Typography>
    </Box>
  );
};

export default DataBox;
