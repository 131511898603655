import { GemBox, GoodsItem, ScheduleNotice } from '@components/Reward';
import SeoMetaTag from '@components/SeoMetaTag';
import {
  Box,
  CircularProgress,
  DialogContent,
  DialogContentText,
  ListSubheader,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import CustomModalRenewal from '../../../components/CustomModalRenewal';
import { userAtom } from '../../../recoil/Auth/userAtom';
import MyPageServices, { errObj } from '../../../services/MyPageServices';
import RewardServices, { BuyProps, drawProps } from '../../../services/RewardServices';

const StreamerReward = () => {
  const { t } = useTranslation(['translation', 'reward', 'common', 'popup']);
  const navigate = useNavigate();
  const user = useRecoilValue(userAtom);
  const [league, setLeague] = useState(1);
  const { pathname } = useLocation();
  // const [gameIdx, setGameIdx] = useState(1); 게임 인덱스
  const [open, setOpen] = useState(false);
  const [isBuySuccess, setIsBuySuccess] = useState(false);
  const [isAuthErrorExist, setIsAuthErrorExist] = useState(false);

  // 브라우저 언어 감지 (경품 리스트 노출 제한 - 임시)
  const userBrowserLang = sessionStorage.getItem('i18nextLng')?.includes('ko') ? 'ko' : 'en';

  // const handleChange = (event: any) => {
  //   setLeague(event.target.value);
  // };

  // 응모 버튼 클릭 시
  const handleClick = (
    data: {
      randomNumber?: number;
    } & BuyProps,
    rate: number,
  ) => {
    if (rate >= 1) {
      // rate가 1인 경우 - 구매하기 팝업
      RewardServices.checkIfAuthUser(data)
        .then((res) => {
          // 이메일 인증한 유저 : 구매 API로 연결
          if (res.validation) {
            buyMutate(data);
          }
        })
        .catch((err: errObj) => {
          // 인증 안한 유저: popup - redirect to myinfo
          if (err.error_code === 1018) {
            setIsAuthErrorExist(true);
          }
        });
    } else {
      // rate가 1 미만인 경우 - 응모하기
      drawMutate(data as drawProps);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setIsBuySuccess(false);
    if (drawError) resetDraw();
    if (buyError) resetBuy();
    if (isAuthErrorExist) setIsAuthErrorExist(false);
  };

  // 리그 셀렉트박스 리스트 받아오기 (임시 필터 주석처리)
  // const { data: gameLeagueList } = useQuery(['gameLeagueInfo'], () => RewardServices.getGameLeague(), {
  //   refetchOnWindowFocus: false,
  // });

  // 젬 정보
  const {
    mutate: getGemInfoMutate,

    data: gemData,
    isLoading: isGemLoading,
    isError: isGemError,
    error: gemError,
    reset: resetGem,
  } = useMutation((uid: string) => MyPageServices.getGemInfo({ uid }), {
    retry: 1,
  });

  // 스트리머 보상 경품 리스트
  const {
    data: rewardData,
    isError: isRewardError,
    error: rewardError,
    isLoading: isRewardListLoading,
    isFetching: isRewardListFetching,
    refetch,
  } = useQuery(['streamerReward'], () => RewardServices.getStreamerReward(), {
    refetchOnWindowFocus: false,
  });

  // 응모하기
  const {
    mutate: drawMutate,
    data: drawData,
    isError: isDrawError,
    error: drawError,
    reset: resetDraw,
  } = useMutation((data: drawProps) => RewardServices.draw(data), {
    onSuccess: (res) => {
      setOpen(true);
      if (res.drawResult.win) {
        refetch();
      }
      getGemInfoMutate(user!.uid);
    },
  });

  // 구매하기
  const {
    mutate: buyMutate,
    data: buyData,
    isError: isBuyError,
    error: buyError,
    reset: resetBuy,
    isLoading: isBuyLoading,
  } = useMutation((data: BuyProps) => RewardServices.buy(data), {
    onSuccess: (res) => {
      setIsBuySuccess(true);
      if (res.buyResult.buy) {
        refetch();
      }
      getGemInfoMutate(user!.uid);
    },
  });

  useEffect(() => {
    if (user) {
      getGemInfoMutate(user?.uid);
    }
  }, [user]);

  const renderSelectGroup = (el: any) => {
    const items = el.league.map((league: any) => {
      return (
        <MenuItem
          key={league.key + league.idx}
          // value={[el.idx, league.idx]} [gameIdx, leagueIdx]
          value={league.idx}
          sx={{
            '& .MuiPaper-root ': {
              bgcolor: 'customMenuListColor.main',
            },
          }}
        >
          {league.key}
        </MenuItem>
      );
    });

    return [
      <ListSubheader sx={{ width: 190 }}>{el.key.replaceAll('_', ' ').toUpperCase()}</ListSubheader>,
      items,
    ];
  };

  return (
    <Box sx={{ marginTop: '40px', mb: 16, width: '1176px' }}>
      <SeoMetaTag
        title='Decakill | Reward'
        description='데카킬 스토어 페이지입니다.'
        keywords='데카킬 스토어, 데카킬 리워드, 데카킬 보상페이지, decakill reward'
      />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        {/* 젬 정보 */}
        {user && <GemBox isLoading={isGemLoading} data={gemData} />}
      </Box>

      <Box>
        {/* 보상품 리스트가 없거나 브라우저 언어가 한국어가 아닌 경우 */}
        {(isRewardError &&
          ((rewardError as errObj)?.error_code === 0 ||
            (rewardError as errObj)?.error_code === 1048 ||
            (rewardError as errObj)?.error_code === 1047)) ||
        userBrowserLang === 'en' ? (
          <Box sx={noticeSxProps}>{t('알림1', { ns: 'reward' })}</Box>
        ) : isRewardListLoading && !(rewardData as any)?.rewardList ? (
          <Box sx={loadingSxProps}>
            <CircularProgress sx={{ color: 'primary.main' }} disableShrink />
          </Box>
        ) : (
          <>
            {/* 스케쥴 알림 */}
            {rewardData && <ScheduleNotice data={rewardData} />}
            {/* 상품 리스트 */}
            <Stack
              direction='row'
              sx={{
                ...goodsContainerSxProps,
                mixBlendMode: rewardData?.scheduleData?.state === 3 ? 'luminosity' : 'normal',
              }}
            >
              {rewardData?.rewardList?.map((reward) => {
                return (
                  <GoodsItem
                    key={`${reward.idx}`}
                    rewardData={rewardData}
                    reward={reward}
                    onClick={handleClick}
                  />
                );
              })}
            </Stack>
            {/*  응모 - 당첨 또는 실패 모달창 */}
            {drawData?.status.status_code === 200 && (
              <CustomModalRenewal
                title={
                  drawData?.drawResult.win
                    ? t('응모하기.당첨성공', { ns: 'popup' })
                    : t('응모하기.당첨실패', { ns: 'popup' })
                }
                btn1={
                  drawData?.drawResult.win
                    ? t('응모하기.나중에', { ns: 'popup' })
                    : t('확인', { ns: 'common' })
                }
                btn2={drawData?.drawResult.win ? t('응모하기.수령', { ns: 'popup' }) : undefined}
                open={open}
                handleClose={handleClose}
                handleConfirm={() => navigate('/my-page/application')}
                sx={{
                  '& .MuiPaper-root': {
                    bgcolor: 'background.modal',
                    padding: '32px',
                    minWidth: '400px',
                    borderRadius: 2,
                  },
                  '& #alert-dialog-title': {
                    fontSize: 24,
                  },
                }}
              >
                <Typography variant='body2'>
                  {drawData?.drawResult.win
                    ? t('응모하기.당첨성공본문', { ns: 'popup' })
                    : t('응모하기.당첨실패본문1', { ns: 'popup' })}
                  <br />
                  {drawData?.drawResult.win ? `` : t('응모하기.당첨실패본문2', { ns: 'popup' })}
                </Typography>
                <DialogContent sx={{ my: 3, px: 0 }}>
                  <DialogContentText
                    id='alert-dialog-description'
                    sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                  >
                    <Box
                      sx={{
                        backgroundImage: drawData?.drawResult.win
                          ? `url(${process.env.PUBLIC_URL}/images/background/popup-bg2.png)`
                          : 'none',
                        backgroundSize: drawData?.drawResult.win ? 'cover' : '',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center center',
                        width: drawData?.drawResult.win ? '180px' : '336px',
                        height: drawData?.drawResult.win ? '180px' : '180px',
                      }}
                    >
                      <img
                        src={`/images/product-img-${drawData?.drawResult.win ? `success` : `fail`}.png`}
                        alt='drawResult'
                        width='100%'
                        height='100%'
                      />
                    </Box>
                  </DialogContentText>
                </DialogContent>
              </CustomModalRenewal>
            )}
            {/* 구매 - 성공 모달창  */}
            {buyData?.buyResult.buy && (
              <CustomModalRenewal
                open={isBuySuccess}
                title={t('구매하기.타이틀', { ns: 'popup' })}
                handleClose={handleClose}
                handleConfirm={() => navigate('/my-page/application')}
                btn1={t('구매하기.나중에', { ns: 'popup' })}
                btn2={t('구매하기.확인', { ns: 'popup' })}
              >
                <Typography variant='body2'>
                  {t('구매하기.본문1', { ns: 'popup' })} <br />
                  {t('구매하기.본문2', { ns: 'popup' })}
                  <br />
                  {t('구매하기.본문3', { ns: 'popup' })}
                  <br />
                </Typography>
                <DialogContent sx={{ my: 3 }}>
                  <DialogContentText
                    id='alert-dialog-description'
                    sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                  >
                    <Box
                      sx={{
                        backgroundImage: `url(${process.env.PUBLIC_URL}/images/background/popup-bg2.png)`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center center',
                        width: '180px',
                        height: '180px',
                      }}
                    >
                      <img
                        src={`/images/product-img-success.png`}
                        alt='buyResult'
                        width='100%'
                        height='100%'
                      />
                    </Box>
                  </DialogContentText>
                </DialogContent>
              </CustomModalRenewal>
            )}
            {/* 응모하기 에러 처리 */}
            {isDrawError && (
              <CustomModalRenewal
                btn1={t('확인', { ns: 'common' })}
                open={isDrawError}
                handleClose={handleClose}
                sx={{
                  '& .MuiPaper-root': {
                    padding: '16px 32px 32px 32px',
                    bgcolor: 'background.modal',
                  },
                }}
              >
                <Typography m={8} variant='body2'>
                  {t((drawError as any)?.error_code, { ns: 'error' })}
                </Typography>
              </CustomModalRenewal>
            )}
            {/* 구매하기 에러 처리  */}
            {isBuyError && (
              <CustomModalRenewal
                btn1={t('확인', { ns: 'common' })}
                open={isBuyError}
                handleClose={handleClose}
                sx={{
                  '& .MuiPaper-root': {
                    padding: '16px 32px 32px 32px',
                    bgcolor: 'background.modal',
                  },
                }}
              >
                <Typography m={8} variant='body2'>
                  {t((buyError as any)?.error_code, { ns: 'error' })}
                </Typography>
              </CustomModalRenewal>
            )}

            {/* 구매 로딩 처리 */}
            {isBuyLoading && (
              <CustomModalRenewal
                open={isBuyLoading}
                sx={{
                  '& .MuiPaper-root': {
                    padding: '16px 32px 32px 32px',
                    bgcolor: 'transparent',
                  },
                }}
              >
                <CircularProgress sx={{ color: 'primary.main' }} disableShrink />
              </CustomModalRenewal>
            )}
            {/* 인증여부 체크 팝업 */}
            {isAuthErrorExist && (
              <CustomModalRenewal
                title={t('구매하기.인증타이틀', { ns: 'popup' })}
                btn1={t('구매하기.구매취소', { ns: 'popup' })}
                btn2={t('구매하기.이메일인증', { ns: 'popup' })}
                open={isAuthErrorExist}
                handleClose={handleClose}
                handleConfirm={() => navigate('/my-page/info')}
                sx={{
                  '& .MuiPaper-root': {
                    padding: '16px 32px 32px 32px',
                    bgcolor: 'background.modal',
                  },
                }}
              >
                <Typography m={8} variant='body2'>
                  {t('구매하기.인증본문1', { ns: 'popup' })} <br />
                  {t('구매하기.인증본문2', { ns: 'popup' })} <br />
                  {t('구매하기.인증본문3', { ns: 'popup' })}
                </Typography>
              </CustomModalRenewal>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default StreamerReward;

const noticeSxProps = {
  bgcolor: 'background.modal',
  padding: '240px',
  borderRadius: 2,
  fontSize: 15,
  mt: 4,
};

const loadingSxProps = {
  p: 60,
  my: 2,
  borderRadius: 2,
  bgcolor: '#1c1c21',
};

const goodsContainerSxProps = {
  display: 'flex',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
};
