import { Box, Button, Stack, Typography } from '@mui/material';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import PageTopGradient from '../../components/PageTopGradient';

const TABS = [
  {
    title: '스트리머 가이드',
    path: '/manual/streamer',
  },
  {
    title: '시청자 가이드',
    path: '/manual/audience',
  },
];

const Manual = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const subRoute = pathname.split('/')[2];
  if (!subRoute) {
    return <Navigate to='/manual/streamer/setting' />;
  }

  return (
    <Box sx={{ textAlign: 'center', position: 'relative' }}>
      <Stack direction='column'>
        <PageTopGradient />
        {/* 로고 */}
        <Box mb={16}>
          <Typography mt={16} variant='h2' component='h1'>
            {/* {t('표제', { ns: 'rank' })} */}
            가이드
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          {TABS.map((tab, idx) => {
            const active = pathname.includes(tab.path);
            return (
              <Button
                key={tab.title}
                variant={active ? 'contained' : 'outlined'}
                onClick={() => navigate(`${tab.path}/${idx === 0 ? 'setting' : 'authConsent'}`)}
                sx={{
                  padding: '16px',
                  width: '550px',
                  backgroundColor: active ? 'primary.dark' : 'background.modal',
                  border: '1px solid transparent',
                  color: active ? '#fff' : 'text.primary',
                }}
              >
                <Typography variant='h5'>{tab.title}</Typography>
              </Button>
            );
          })}
        </Box>
        <Outlet />
      </Stack>
    </Box>
  );
};

export default Manual;
