import SeoMetaTag from '@components/SeoMetaTag';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Button } from '@mui/material';
import { Link, Outlet, useLocation } from 'react-router-dom';

type Tabs = {
  [x: string]: string;
};

const StreamerManual = ({ tabs }: { tabs: Tabs }) => {
  const { pathname } = useLocation();
  const pathArr = pathname.split('/');
  const lastPathname = pathArr[pathArr.length - 1];

  return (
    <Box maxWidth='1240px' sx={{ margin: '20px auto' }}>
      <SeoMetaTag
        title='Decakill | Guide'
        description='스트리머 가이드'
        keywords='스트리머 가이드, 데카킬 이용가이드, decakill guide'
      />{' '}
      <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
        {/* tabs */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            bgcolor: 'background.modal',
            padding: '16px',
            mr: 5,
            borderRadius: 2,
          }}
        >
          {Object.keys(tabs)?.map((tab, idx) => {
            const active = lastPathname === tab;
            return (
              <Link to={tab} state={{ idx }}>
                <Button
                  endIcon={<ArrowForwardIosIcon />}
                  sx={{
                    color: active ? '#fff' : 'text.primary',
                    width: '158px',
                    padding: '12px 16px',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  {/* {t(`탭.${tab.title}`, { ns: 'mypage' })} */}
                  {tabs[tab]}
                </Button>
              </Link>
            );
          })}
        </Box>
        {/* Contents */}
        <Box sx={{ flex: 1, bgcolor: 'background.modal', borderRadius: 2 }}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default StreamerManual;
