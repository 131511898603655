import React from 'react';
import { Box } from '@mui/material';

const PageTopGradient = () => <Box sx={backgroundStyling} />;

const backgroundStyling = {
  position: 'absolute',
  top: 0,
  left: '50%',
  transform: 'translateX(-50%)',
  width: '720px',
  height: '166px',
  display: 'block',
  zIndex: -1,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
  backgroundImage: `url(${process.env.PUBLIC_URL}/images/background/main-bg.png)`,
};

export default PageTopGradient;
