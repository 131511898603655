export const streamerGuide = {
  setting: '사용 설정',
  login: '로그인',
  presentQuestion: '문제 출제',
  answer: '정답 처리',
  endOfQuestion: '문제 정산',
  result: '문제 종료',
  code: '관리코드 발급',
};

export const audienceGuide = {
  authConsent: '권한 동의',
  participateQuiz: '퀴즈 참여',
  kindOfQuiz: '퀴즈 종류',
  answer: '정답 연출',
  confirmRank: '랭킹 확인',
  prediction: '예측 현황',
  rivalRank: '라이벌 랭킹',
  inviteRival: '라이벌 초대',
  closeRival: '라이벌 나가기',
  setting: '설정 화면',
  guide: '가이드 화면',
  killsAndGems: '킬&젬 계산규칙',
};
