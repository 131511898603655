import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { FormLabel, InputBase, styled as MUIstyled, SxProps } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import React from 'react';

interface basicSelectProps {
  label?: string;
  placeholder?: string;
  children: React.ReactNode;
  data: string | undefined;
  sx?: SxProps;
  filledSx?: SxProps;
  setData: React.Dispatch<React.SetStateAction<string>>;
  onChange?: (event: any) => void;
  [x: string]: any;
}

export default function CustomSelectBox({
  placeholder,
  children,
  data,
  setData,
  sx,
  filledSx,
  onChange,
  ...props
}: basicSelectProps) {
  const handleChange = (event: SelectChangeEvent) => {
    console.log(event.target.value);
    setData(event.target.value);
  };

  return (
    <FormControl>
      <StyledFormLabel sx={filledSx} />
      <Select
        MenuProps={{
          MenuListProps: {
            disablePadding: true,
          },
          PaperProps: {
            sx: {
              bgcolor: '#25252a',
              '& .MuiListSubheader-root': {
                bgcolor: '#25252a',
                color: '#fff',
              },
              '& .MuiMenuItem-root': {
                margin: '8px',
                padding: '8px 12px',
              },
              '& .MuiMenuItem-root:hover': {
                bgcolor: '#3b3b40',
                borderRadius: '8px',
              },
              '& .MuiMenuItem-root.Mui-selected:hover': {
                bgcolor: '#3b3b40',
              },
              scrollbarWidth: '1px',
            },
          },
        }}
        sx={{
          textAlign: 'left',
          borderRadius: 2,
          '.MuiSvgIcon-root': {
            color: '#fff',
          },
          '& .MuiSelect-select': {
            padding: '12px !important',
            border: 'none',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },

          ...sx,
        }}
        value={data}
        input={<StyledInputBase />}
        onChange={handleChange}
        displayEmpty
        // renderValue={(value) => (value?.length ? value : placeholder)}
        IconComponent={KeyboardArrowDownIcon}
        {...props}
      >
        {children}
      </Select>
    </FormControl>
  );
}

const StyledInputBase = MUIstyled(InputBase)`
    border-radius:8px;
    text-align:left;
    font-size:15px;
`;

const StyledFormLabel = MUIstyled(FormLabel)`
    display: none;

    &.MuiFormLabel-root.Mui-focused {
      color: white;
    }
`;
