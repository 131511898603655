import { AppBar, Box, Button, DialogProps, Stack, SxProps, Toolbar, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import QueryString from 'qs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { useMutation, useQuery } from 'react-query';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import remarkGfm from 'remark-gfm';
import { pathGuide } from '../lib/pagePath';
import { TWITCH_CLIENT_ID } from '../Pages/config';
import { twitchTokenAtom, userAtom, userProfileAtom } from '../recoil/Auth/userAtom';
import AuthServices, { LoginInfo } from '../services/AuthServices';
import TwitchServices from '../services/TwitchServices';
import CustomLongTextModal from './CustomLongTextModal';

// mdx
import { privacyPolicy } from '../lib/termsAndConditions/privacyPolicy';
import { termsForStreamer, termsForUser } from '../lib/termsAndConditions/terms';

const pages = [
  // { title: '이스포츠랭킹', path: '/rank/esports' },
  { title: '스트리머랭킹', path: '/rank/streamer' },
  // { title: '보상', path: '/reward' },
];

const settings = [
  { title: '마이페이지', path: '/my-page' },
  { title: '로그아웃', path: '/' },
];

const TermsForUser = () => <ReactMarkdown children={termsForUser} remarkPlugins={[remarkGfm]} />;
const TermsForStreamer = () => <ReactMarkdown children={termsForStreamer} remarkPlugins={[remarkGfm]} />;
const PrivacyPolicy = () => <ReactMarkdown children={privacyPolicy} remarkPlugins={[remarkGfm]} />;

const MuiHeader = () => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const [user, setUser] = useRecoilState(userAtom);
  const [userProfile, setUserProfile] = useRecoilState(userProfileAtom);
  const setTwitchToken = useSetRecoilState(twitchTokenAtom);
  const twitchTokenValue = useRecoilValue(twitchTokenAtom);
  const [open, setOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation(['translation', 'main', 'common', 'popup']);

  const [openTerm, setOpenTerm] = useState(false); // 약관동의 모달
  const [scroll, setScroll] = useState<DialogProps['scroll']>('paper');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const logout = () => {
    setUser(undefined);
    setUserProfile(undefined);
    handleClose();
  };

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const queryStringData = QueryString.parse(search, { ignoreQueryPrefix: true });

  // 1. 쿼리스트링 데이터의 code로 access token, refresh token 받아오기
  const { mutate: getTokenMutate, data: tokenData } = useMutation(
    (code: string) =>
      TwitchServices.getTwitchUserByCode({
        client_id: TWITCH_CLIENT_ID,
        client_secret: `${process.env.REACT_APP_TWITCH_CLIENT_SECRET}`,
        code,
        grant_type: 'authorization_code',
        redirect_uri: `${window.location.origin}`,
      }),
    {
      onSuccess: (res: any) => {
        //  받아온 토큰정보를 전역에 저장
        setTwitchToken(res);

        // 기존에 로그인을 시도했던 url로 redirecting하기
        const param = searchParams.get('state');
        const state = param && decodeURIComponent(param);
        navigate(state || '/');
      },
    },
  );

  // 2. 1에서 받아온 토큰 정보로 유저 정보 받기
  const { data: userInfo } = useQuery(
    ['userInfo'],
    () => TwitchServices.getTwitchUserByToken(tokenData as object),
    {
      enabled: Boolean(tokenData), // trigger 역할, tokenData가 있을때만 이 usequery가 실행됨!!
      onSuccess: (res: any) => {
        // 받아온 유저정보를 전역에 저장
        setUserProfile(res?.data?.data[0]);
        loginMutate({ uid: res?.data?.data[0].id, twitchToken: twitchTokenValue?.access_token });
      },
      refetchOnWindowFocus: false,
    },
  );

  //  3. 받은 유저정보를 이용해서 로그인 진행,
  //  3-1. 가입된 유저라면 바로 랭킹 페이지로 이동
  //  3-2. 미가입된 유저라면 바로 회원가입 ->로그인을 시킨 후 랭킹페이지로 이동

  const {
    mutate: loginMutate,
    isLoading,
    isError: isLoginError,
    error,
    reset: loginReset,
  } = useMutation((data: LoginInfo) => AuthServices.login(data), {
    onSuccess: (res: any) => {
      // 로그인 성공 시 유저 정보를 전역에 저장
      if (res.data.status.error_code === null) {
        setUser(res.data.loginUser[0]);
      }
      if (res.data.status.error_code === 3) {
        // 로그인 실패 시 회원가입 진행
        // 회원가입 진행 전 약관 동의 모달 open  (모두 동의 받은 유저에 한해서만 가입 진행)
        setOpenTerm(true);
      }
    },
  });

  const { mutate: signUpMutate } = useMutation(
    () =>
      AuthServices.signUp({
        uid: userInfo.data.data[0].id,
        login: userInfo.data.data[0].login,
        displayName: userInfo.data.data[0].display_name,
        profileImageUrl: userInfo.data.data[0].profile_image_url,
        platform: userInfo.data.data[0].platform ?? 'twitch',
        description: userInfo.data.data[0].description,
        createdAt: userInfo.data.data[0].created_at,
      }),
    {
      onSuccess: (res: any) => {
        // 회원가입 이후 바로 로그인 처리
        if (res?.data?.status?.status_code === 200) {
          loginMutate({ uid: res?.data?.signUpUser?.uid, twitchToken: twitchTokenValue?.access_token });
          alert(t('alert', { ns: 'main' }));
        }
      },
    },
  );

  useEffect(() => {
    if (queryStringData.code) {
      getTokenMutate(queryStringData.code as string);
    }
  }, [queryStringData.code]);

  return (
    <Box
      sx={{
        bgcolor: 'background.appBar',
        borderBottomColor: 'customBorderColor.main',
      }}
    >
      <AppBar
        position='static'
        sx={{
          bgcolor: 'background.appBar',
          borderBottomColor: 'customBorderColor.main',
          maxWidth: 1920,
          margin: '0 auto',
          padding: '0 20px',
        }}
      >
        <Stack>
          <Toolbar disableGutters>
            {/* 로고 */}
            <Box
              sx={{
                flex: 1,
              }}
            >
              <Typography variant='h6' noWrap component='a' href='/'>
                <img src='/images/logo/decakill.svg' alt='logo' />
              </Typography>
            </Box>
            {/* 탭 */}
            <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
              {pages.map((page) => {
                const active = pathname.includes(page.path);
                return (
                  <Box
                    sx={{
                      width: 'auto',
                      mr: 2,
                      borderBottom: 4,
                      borderBottomColor: active ? 'customBorderColor.dark' : 'transparent',
                    }}
                  >
                    <Button
                      key={`page ${page.title}`}
                      onClick={() => navigate(page.path)}
                      sx={{
                        my: 2,
                        color: 'white',
                        display: 'block',
                        fontSize: 18,
                        bgcolor: active ? 'customMenuListColor.main' : 'transparent',
                      }}
                    >
                      {t(`header.${page.title}`, { ns: 'main' })}
                    </Button>
                  </Box>
                );
              })}
            </Box>
            {/* 로그인 및 마이페이지 */}
            <Box
              sx={{
                flex: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                // visibility: 'hidden',
              }}
            >
              <Button
                onClick={() => navigate(`${pathGuide}/streamer/setting`)}
                sx={{
                  my: 2,
                  mr: 2,
                  color: 'primary.light',
                  display: 'block',
                  fontSize: 15,
                }}
              >
                {t('header.유저스트리머가이드', { ns: 'main' })}
              </Button>
              {isLoading ? (
                <Box width={116} textAlign='center'>
                  <CircularProgress sx={{ color: 'primary.main' }} disableShrink size={20} />
                </Box>
              ) : user ? (
                //* 로그인 성공 시
                // <Box sx={{ width: 'auto' }}>
                //   <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                //     <Avatar
                //       alt='profileImg'
                //       variant='square'
                //       sx={{ width: '28px', height: '28px', mr: 2, borderRadius: 2 }}
                //       src={userProfile?.profile_image_url}
                //     />
                //     <Typography variant='body2' sx={{ fontWeight: 'bold', color: '#fff' }}>
                //       {userProfile?.display_name}
                //     </Typography>
                //   </IconButton>
                //   <Menu
                //     sx={{
                //       borderRadius: 2,
                //       mt: '40px',
                //       '& .MuiPaper-root': {
                //         bgcolor: 'customMenuListColor.main',
                //         color: '#fff',
                //         fontSize: 15,
                //       },
                //       '& .MuiList-root': {
                //         p: 2,
                //       },
                //       '& .MuiMenuItem-root': {
                //         textAlign: 'center',
                //         '&:hover': {
                //           bgcolor: 'rgba(255, 255, 255, 0.1)',
                //         },
                //       },
                //       '& .MuiTypography-root': {
                //         fontSize: 13,
                //       },
                //     }}
                //     id='menu-appbar'
                //     anchorEl={anchorElUser}
                //     anchorOrigin={{
                //       vertical: 'top',
                //       horizontal: 'right',
                //     }}
                //     keepMounted
                //     transformOrigin={{
                //       vertical: 'top',
                //       horizontal: 'right',
                //     }}
                //     open={Boolean(anchorElUser)}
                //     onClose={handleCloseUserMenu}
                //   >
                //     {settings.map((setting) => (
                //       <>
                //         <MenuItem
                //           key={`setting ${setting.title}`}
                //           onClick={() => {
                //             handleCloseUserMenu();
                //             if (setting.title === '마이페이지') {
                //               navigate('/my-page');
                //             }
                //             if (setting.title === '로그아웃') {
                //               handleClickOpen();
                //             }
                //           }}
                //           sx={{
                //             borderRadius: 2,
                //             justifyContent: 'center',
                //           }}
                //         >
                //           <Typography variant='button'>
                //             {t(`header.${setting.title}`, { ns: 'main' })}
                //           </Typography>
                //         </MenuItem>
                //         {/*  로그아웃 모달창 발생  */}
                //         <CustomModalRenewal
                //           title={t(`header.로그아웃`, { ns: 'main' })}
                //           open={open}
                //           handleClose={handleClose}
                //           handleConfirm={() => {
                //             logout();
                //           }}
                //           btn1={t('취소', { ns: 'common' })}
                //           btn2={t('header.로그아웃', { ns: 'main' })}
                //         >
                //           <Typography mt={4} mb={8} variant='body1'>
                //             {t(`header.로그아웃알림`, { ns: 'main' })}
                //           </Typography>
                //         </CustomModalRenewal>
                //       </>
                //     ))}
                //   </Menu>
                // </Box>
                <></>
              ) : (
                //* 비로그인 시
                // <Button
                //   component={Link}
                //   variant='contained'
                //   href={`https://id.twitch.tv/oauth2/authorize?client_id=${TWITCH_CLIENT_ID}&redirect_uri=${
                //     window.location.origin
                //   }&response_type=code&state=${encodeURIComponent(location.pathname)}`}
                // >
                //   {/* 트위치 권한 요청 페이지로 이동 */}
                //   {t('header.로그인', { ns: 'main' })}
                // </Button>
                <></>
              )}
            </Box>
          </Toolbar>
        </Stack>
      </AppBar>
      {/* 미가입된 유저인 경우 약관동의 모달 출력  */}
      {isLoginError && (error as any).error_code === 3 && (
        <CustomLongTextModal
          open={isLoginError}
          scroll={scroll}
          handleCancel={() => {
            loginReset();
            navigate('/');
          }}
          handleClose={(_, reason) => {
            if (reason && reason === 'backdropClick') return;
            signUpMutate();
          }}
          title={t('약관동의.표제', { ns: 'popup' })}
          subtitle={t('약관동의.표제컨텐츠', { ns: 'popup' })}
          label1={t('약관동의.라벨1', { ns: 'popup' })}
          label2={t('약관동의.라벨2', { ns: 'popup' })}
          label3={t('약관동의.라벨3', { ns: 'popup' })}
          content1={
            <Box sx={termsSx}>
              <TermsForUser />
              <TermsForStreamer />
            </Box>
          }
          content2={
            <Box sx={termsSx}>
              <PrivacyPolicy />
            </Box>
          }
          btn1={t('약관동의.버튼1', { ns: 'popup' })}
          btn2={t('약관동의.버튼2', { ns: 'popup' })}
        />
      )}
    </Box>
  );
};

export default MuiHeader;

const termsSx: SxProps = {
  fontSize: 15,
  fontWeight: 'regular',
  color: 'text.primary',
  lineHeight: '24px',

  h2: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#fff',
    margin: '12px 0',
  },

  h3: {
    fontSize: 15,
    fontWeight: 'bold',
    color: '#fff',
    margin: '12px 0',
  },

  ul: {
    listStyle: 'disc',
    listStylePosition: 'inside',
    '& >li>ul': {
      paddingLeft: 5,
      listStyle: 'circle',
      listStylePosition: 'inside',
      '& >li>ul': {
        paddingLeft: 5,
        listStyle: 'square',
        listStylePosition: 'inside',
      },
    },
  },

  ol: {
    listStyle: 'decimal',
    listStylePosition: 'inside',
    '& >li>ol': {
      paddingLeft: 5,
      listStyle: 'circle',
      listStylePosition: 'inside',
      '& >li>ol': {
        paddingLeft: 5,
        listStyle: 'square',
        listStylePosition: 'inside',
      },
    },
  },

  table: {
    width: '100%',

    '& >thead>tr>th': {
      padding: '12px 0',
      bgcolor: 'customMenuListColor.main',
      border: '1px solid #3e3e42',
    },
    '& >tbody>tr>td': {
      padding: '12px 0',
      border: '1px solid #3e3e42',
    },
  },
};
