import { Typography } from '@mui/material';
import ReactPlayer from 'react-player/lazy'; // lazy loading

interface videoProps {
  video: {
    src: string;
    title?: string;
    text?: string;
  };
  [x: string]: any;
}

const VideoPlayer = ({ video, ...props }: videoProps) => {
  const { src, title, text, ...rest } = video;

  return (
    <>
      <ReactPlayer url={src} width='100%' height='100%' {...props} />
      {text && (
        <Typography variant='body2' sx={{ textAlign: 'left', lineHeight: '24px', my: 4 }}>
          <pre>{text}</pre>
        </Typography>
      )}
    </>
  );
};

export default VideoPlayer;
