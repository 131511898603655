import { CssBaseline, ThemeProvider } from '@mui/material';
import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { RecoilRoot } from 'recoil';
import App from './App';
import './i18n';
import { GlobalStyles } from './Styles/GlobalStyles';
import MUITheme from './Styles/theme/MUITheme';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <RecoilRoot>
    <ThemeProvider theme={MUITheme}>
      <CssBaseline />
      <Suspense fallback={null}>
        <GlobalStyles />
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </Suspense>
    </ThemeProvider>
  </RecoilRoot>,
);
