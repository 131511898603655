import { axiosPost } from './axios/axios';

export interface LoginInfo {
  uid: string;
  twitchToken?: string;
}
class AuthServices {
  getClientUserByToken = ({ id }: { id: string }) => {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/user/login',
        data: { id },
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  login = (data: LoginInfo) => {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/login',
        data,
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  signUp = (data: any) => {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/sign-up',
        data,
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  getUserInfo = ({ uid }: { uid: string }) => {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/mypage',
        data: { uid },
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}
export default new AuthServices();
