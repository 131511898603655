import CustomSelectBox from '@components/customSelectBox';
import styled from '@emotion/styled/macro';
import { Avatar, Box, Button, Chip, CircularProgress, MenuItem, Stack, Typography } from '@mui/material';
import { ChangeEventHandler, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useRecoilValue } from 'recoil';

import { SearchInput } from '@components/Input';
import { MyRank } from '@components/Rank';
import SeoMetaTag from '@components/SeoMetaTag';
import { getTierByRankingRatio } from '@lib/Rank/getTierByRanking';
import { SxProps } from '@mui/material';
import { ReactElement } from 'react-markdown/lib/react-markdown';
import { userAtom } from '../../../recoil/Auth/userAtom';
import RankServices, { CustomStatus, getDaliyRankingParams, RankData } from '../../../services/RankServices';

export function getKDA(data: RankData): number {
  return data.death === 0
    ? Number((data.kill * 1.2).toFixed(2))
    : Number((data.kill / data.death).toFixed(2));
}

// 검색어 하이라이팅
const getMatchedText = (nickName: string, query?: string): string | ReactElement => {
  if (!query) return nickName;
  const parts = nickName.split(new RegExp(`(${query})`, `gi`));

  return parts.length === 0 ? (
    nickName
  ) : (
    <>
      {parts.map((part: string) =>
        part.toLowerCase() === query.toLowerCase() ? (
          <Box key={`${nickName}${part}`} sx={{ color: 'primary.light', display: 'inline-block' }}>
            {part}
          </Box>
        ) : (
          part
        ),
      )}
    </>
  );
};

function DaliyRank() {
  const { t } = useTranslation(['translation', 'common', 'rank', 'popup']);
  const user = useRecoilValue(userAtom);

  // 테이블 State
  const [streamerInfo, setStreamerInfo] = useState('445859940'); // 제일 첫번째 값으로 default 처리

  // 검색창 State
  const [inputValue, setInputValue] = useState<string>('');
  const [settledValue, setSettledValue] = useState<string>('');

  // 스트리머 데일리 리스트 가져오기
  const { data: dailyStreamer } = useQuery(['dailyStreamerList'], () => RankServices.getDailyStreamerList(), {
    refetchOnWindowFocus: false,
  });

  // 데일리 랭킹
  const {
    mutate: getDaliyRanking,
    data,
    isLoading,
    isError: isRankError,
    error: rankError,
  } = useMutation((data: getDaliyRankingParams) => RankServices.getDaliyRankingBySteamer(data));

  const handleSearch = () => {
    if (!inputValue) return;

    getDaliyRanking({
      uid: user?.uid,
      channelId: streamerInfo !== 'totalData' ? streamerInfo : null,
      keyword: settledValue,
    });
  };

  const clearInput = () => {
    setInputValue('');
    setSettledValue('');
    getDaliyRanking({
      uid: user?.uid,
      channelId: streamerInfo !== 'totalData' ? streamerInfo : null,
      keyword: null,
    });
  };

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setInputValue(e.target.value);
  };
  useEffect(() => {
    if (!streamerInfo) return;
    getDaliyRanking({
      uid: user?.uid,
      channelId: streamerInfo !== 'totalData' ? streamerInfo : null,
      keyword: null,
    });
  }, [user, streamerInfo]);

  useEffect(() => {
    handleSearch();
  }, [settledValue]);

  // const renderingData = MOCK_NS_DAILY.rankingData;

  return (
    <Box sx={{ marginTop: '40px', mb: 16, width: '1176px' }}>
      <SeoMetaTag
        title='Decakill | Leaderboard'
        description='데일리 랭킹 페이지입니다.'
        keywords='데카킬 데일리 랭킹, 스트리머 랭킹, decakill daily rank'
      />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ display: 'flex' }}>
          {/* 스트리머 필터 */}
          <CustomSelectBox
            placeholder={t('스트리머', { ns: 'common' })}
            data={streamerInfo}
            setData={setStreamerInfo}
            sx={{
              marginRight: 2,
              width: 190,
              '&.MuiInputBase-root': {
                bgcolor: '#25252a',
              },
            }}
            filledSx={{
              '&.MuiFormLabel-filled ~ div': {
                color: 'white',
                border: '1px solid',
                borderColor: '#8c40cc',
              },
            }}
          >
            {dailyStreamer?.streamerList.map((item) => {
              return (
                <MenuItem key={`item ${item.idx}`} value={item.broadcastId}>
                  {item.displayName}
                </MenuItem>
              );
            })}
          </CustomSelectBox>
        </Box>
        {/* 검색창 */}
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
          <SearchInput
            autoComplete='off'
            onChange={handleInputChange}
            clearInput={clearInput}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                setSettledValue(inputValue);
              }
            }}
            value={inputValue}
            placeholder={t('유저명', { ns: 'common' })}
          />
          <Button
            variant='contained'
            sx={{
              fontWeight: 'bold',
              color: inputValue ? '#fff' : 'text.primary',
              bgcolor: inputValue ? 'primary.main' : 'customMenuListColor.main',
              padding: '8px 20px',
              borderRadius: 2,
              ml: 1,
            }}
            onClick={() => {
              setSettledValue(inputValue);
            }}
          >
            {t('검색', { ns: 'common' })}
          </Button>
        </Box>
      </Box>
      {/* 랭킹 테이블 */}
      <Box
        sx={{
          marginTop: '20px',
          borderTop: '1px solid #707070',
          width: '100%',
          overflow: 'auto',
          maxHeight: '1000px',
          position: 'relative',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          '&::-webkit-scrollbar-corner': {
            display: 'none',
          },
        }}
      >
        {!data && !streamerInfo ? (
          <Box sx={boxSx}>{t('알림5', { ns: 'rank' })}</Box>
        ) : (
          <HomeTable isLoggedIn={Boolean(user)}>
            <Stack direction='row' sx={{ p: 4, position: 'sticky', top: 0, bgcolor: '#0e0e13', zIndex: 1 }}>
              <Box width='10%'>{t('테이블헤더.순위', { ns: 'rank' })}</Box>
              <Box
                width='12.7%'
                sx={{
                  flex: 1,
                }}
              >
                {t('테이블헤더.티어', { ns: 'rank' })}
              </Box>
              <Box width='28.3%'>{t('테이블헤더.닉네임', { ns: 'rank' })}</Box>
              <Box width='8.5%'>{t('테이블헤더.참여횟수', { ns: 'rank' })}</Box>
              <Box width='8.5%'>{t('테이블헤더.킬', { ns: 'rank' })}</Box>
              <Box width='8.5%'>{t('테이블헤더.콤보킬', { ns: 'rank' })}</Box>
              <Box width='8.5%'>{t('테이블헤더.멀티킬', { ns: 'rank' })}</Box>
              <Box width='8.5%'>{t('테이블헤더.데스', { ns: 'rank' })}</Box>
              <Box width='8.5%'>{t('테이블헤더.KD', { ns: 'rank' })}</Box>
            </Stack>

            {user && data?.myRankingData && (
              <MyRank
                data={data?.myRankingData?.rankData}
                // onClick={() => modalOpenHanlder(user.uid)}
              />
            )}

            {isLoading ? (
              <Box sx={boxSx}>
                <CircularProgress sx={{ color: 'primary.main' }} disableShrink />
              </Box>
            ) : isRankError && (rankError as CustomStatus).error_code === 1045 ? (
              <Box sx={boxSx}>{t('알림4', { ns: 'rank' })}</Box>
            ) : isRankError && (rankError as CustomStatus).error_code === 1061 ? (
              <Box sx={boxSx}>{t('알림3', { ns: 'rank' })}</Box>
            ) : (isRankError && (rankError as CustomStatus).error_code === 0) ||
              data?.rankingData === null ||
              data?.rankingData?.length === 0 ? (
              <Box sx={boxSx}>{t('랭킹없음', { ns: 'rank' })}</Box>
            ) : (
              data?.rankingData?.map((el) => {
                const {
                  uid,
                  comboKill,
                  displayName,
                  death,
                  gameCount,
                  kill,
                  multiKill,
                  ranking,
                  rankingRatio,
                  profileImageUrl,
                } = el;
                return (
                  <Stack
                    direction='row'
                    key={displayName + uid}
                    sx={{
                      p: 4,
                      my: 2,
                      borderRadius: 2,
                      bgcolor: '#1c1c21',
                      display: 'flex',
                      alignItems: 'center',
                      backgroundImage: data?.myRankingData?.rankData?.every((item) => item.uid === uid)
                        ? 'linear-gradient(93deg, #613389 1%, #0b8966)'
                        : 'none',
                      '&:hover': {
                        background: data?.myRankingData?.rankData?.every((item) => item.uid === uid)
                          ? 'linear-gradient(93deg, #6E36A0 1%, #069C74)'
                          : '#25252a',
                      },
                    }}
                  >
                    <Box
                      width='10%'
                      sx={{ color: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                      {data?.myRankingData?.rankData?.every((item) => item.uid === uid) && (
                        <Chip
                          label='MY'
                          sx={{
                            padding: '8px',
                            height: '28px',
                            bgcolor: 'background.default',
                            color: 'white',
                            borderRadius: 1,
                            fontWeight: 'bold',
                            mr: 2,
                            fontSize: 13,
                            '& .MuiChip-label': {
                              padding: '2px 0',
                            },
                          }}
                        />
                      )}
                      <Typography variant='h6' sx={{ color: '#fff' }}>
                        {ranking ?? '-'}
                      </Typography>
                    </Box>
                    <Box
                      width='12.7%'
                      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1 }}
                    >
                      <Box width={24}>
                        <img
                          width='100%'
                          height='100%'
                          style={{ marginRight: 4 }}
                          alt='tier'
                          src={getTierByRankingRatio(rankingRatio).imgUrl}
                        />
                      </Box>
                      <Typography variant='body2' sx={{ color: '#fff' }}>
                        {getTierByRankingRatio(rankingRatio).rank}
                      </Typography>
                    </Box>
                    <Box width='28.3%' sx={{ display: 'flex', justifyContent: 'flex-start', ml: 3 }}>
                      {displayName === null ? (
                        <></>
                      ) : (
                        <>
                          <Avatar
                            src={profileImageUrl ?? undefined}
                            alt='profile'
                            variant='square'
                            sx={{
                              width: 24,
                              height: 24,
                              borderRadius: 1,
                            }}
                          />
                          <Typography
                            variant='body2'
                            sx={{
                              ml: 3,
                              color: '#fff',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                            }}
                          >
                            {getMatchedText(displayName, settledValue)}
                          </Typography>
                        </>
                      )}
                    </Box>
                    <Box width='8.5%' sx={{ color: '#fff' }}>
                      {gameCount ?? '-'}
                    </Box>
                    <Box width='8.5%' sx={{ color: '#fff' }}>
                      {kill ?? '-'}
                    </Box>
                    <Box width='8.5%' sx={{ color: '#fff' }}>
                      {comboKill ?? '-'}
                    </Box>
                    <Box width='8.5%' sx={{ color: '#fff' }}>
                      {(multiKill >= 10 ? 10 : multiKill) ?? '-'}
                    </Box>
                    <Box width='8.5%' sx={{ color: '#fff' }}>
                      {death ?? '-'}
                    </Box>
                    <Box width='8.5%' sx={{ color: '#fff' }}>
                      {(death !== 0 && kill / death ? (kill / death).toFixed(2) : (kill * 1.2).toFixed(2)) ??
                        '-'}
                    </Box>
                  </Stack>
                );
              })
            )}
          </HomeTable>
        )}
      </Box>
    </Box>
  );
}

export default DaliyRank;

const HomeTable = styled.div<{ isLoggedIn: boolean }>`
  width: 100%;
  border-top-color: #707070;
  border-collapse: separate;
  border-spacing: 0px 4px;

  > thead > tr {
    position: sticky;
    top: 0;
    background-color: #0e0e13;
    :nth-child(-n + 1) {
      border-top: 1px solid #707070;
    }
    padding: 16px;
    width: 100%;
    height: 56px;

    th {
      font-size: 12px;
      font-weight: bold;
      text-align: center;
      color: #817e90;
      vertical-align: middle;
    }
  }
  > tbody {
    max-height: 900px;
    overflow: auto;
  }
  > tbody > tr {
    :nth-child(-n + 1) {
      background-image: ${({ isLoggedIn }) =>
        isLoggedIn ? 'linear-gradient(93deg, #613389 1%, #0b8966)' : 'none'};
    }
    line-height: 3;
    letter-spacing: normal;
    color: #fff;
    text-align: center;
    color: #ffffff;
    vertical-align: middle;
    background-color: #1c1c21;
    height: 56px;
    width: 100%;
    td {
      line-height: 2.4;
      letter-spacing: normal;
      color: #fff;
      text-align: center;
      color: #ffffff;
      vertical-align: middle;
      width: 50px;
      :nth-of-type(1) {
        width: 10%;
      }
    }
  }
`;

const boxSx: SxProps = {
  p: 60,
  mt: 2,
  borderRadius: 2,
  bgcolor: '#1c1c21',
};
