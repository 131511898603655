import Footer from '@components/Footer';
import { Box, Stack } from '@mui/material';
import { Outlet } from 'react-router-dom';
import MuiHeader from './components/MuiHeader';

const Layout = () => {
  return (
    <Stack direction='column' sx={{ height: '100%' }}>
      <MuiHeader />
      <Box sx={{ flex: 1 }}>
        <Outlet />
      </Box>
      <Box>
        <Footer />
      </Box>
    </Stack>
  );
};

export default Layout;
