import { getDateDiff } from '@lib/getDateDIff';
import { Box, Typography } from '@mui/material';
import { RewardList } from '@services/RewardServices';
import { t } from 'i18next';

/* state - 1: 대기 2: 진행 3: 마감 */

interface P {
  data?: RewardList;
}

type scheduleDate = string | undefined;

const getScheduleStatus = (startDate: scheduleDate, endDate: scheduleDate, state: number | undefined) => {
  if (!startDate || !endDate) return null;
  let status = '';
  let status2 = '';

  const daysBeforeStart = getDateDiff(startDate.slice(0, 10), new Date().toISOString().slice(0, 10));
  const daysBeforeEndFromToday = getDateDiff(new Date().toISOString().slice(0, 10), endDate.slice(0, 10));

  // 대기
  if (state === 1 && daysBeforeStart < 0) {
    status = `${String(startDate).slice(0, 10)}`;
    status2 = `D${daysBeforeStart}`;

    // 진행
  } else if (state === 2 || (daysBeforeStart >= 0 && daysBeforeEndFromToday >= 0)) {
    status = `${String(endDate).slice(0, 10)}`;
    status2 = `D-${daysBeforeEndFromToday}`;

    // 마감
  } else if (state === 3 && daysBeforeEndFromToday < 0) {
    status = ``;
    status2 = ``;
  }
  return {
    status,
    status2,
  };
};

const ScheduleNotice: React.FC<P> = ({ data }) => {
  return (
    <Box border={1} sx={scheduleSxProps}>
      <Typography variant='body2' sx={{ color: 'primary.light', mr: 2 }}>
        Notice
      </Typography>
      <Typography variant='body2'>
        <>
          {
            getScheduleStatus(
              data?.scheduleData?.startDate,
              data?.scheduleData?.endDate,
              data?.scheduleData.state,
            )?.status
          }
          {data?.scheduleData.state === 1
            ? t('스케쥴.대기', { ns: 'reward' })
            : data?.scheduleData.state === 2
            ? t('스케쥴.진행', { ns: 'reward' })
            : ' '}
        </>
        <Box component='span' sx={{ color: '#fff', ml: 2 }}>
          {data?.scheduleData.state === 3
            ? `${t('스케쥴.마감', { ns: 'reward' })}`
            : getScheduleStatus(
                data?.scheduleData?.startDate,
                data?.scheduleData?.endDate,
                data?.scheduleData.state,
              )?.status2}
        </Box>
      </Typography>
    </Box>
  );
};

export default ScheduleNotice;

const scheduleSxProps = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 2,
  py: 3,
  my: 3,
  borderColor: '#4b4b4f',
};
