import { Box, Button, Container, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const TABS = [
  { title: '이용약관', path: '/termsAndConditions' },
  { title: '개인정보취급방침', path: '/privacyPolicy' },
];

const TABS_FOR_EXTERNAL_LINKS = [
  {
    title: '비즈니스 문의',
    link: 'https://forms.gle/yvPd4KyUwvweELtd6',
  },
  // {
  //   title: '데카킬은 채용 중!',
  //   link: 'https://www.wanted.co.kr/company/22261',
  // },
];

const Footer = () => {
  const { t } = useTranslation(['translation', 'main', 'common']);
  const navigate = useNavigate();

  return (
    <Box sx={footerStyling}>
      <Container maxWidth='xl'>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '& >span': {
              color: 'text.secondary',
              ':last-of-type': {
                display: 'none',
              },
            },
          }}
        >
          {TABS.map((tab) => {
            return (
              <>
                <Button
                  key={tab.title}
                  sx={{
                    color: 'text.secondary',
                    '&.MuiButtonBase-root:hover': {
                      bgcolor: 'transparent',
                    },
                  }}
                  onClick={() => navigate(tab.path)}
                >
                  {t(`footer.${tab.title}`, { ns: 'main' })}
                </Button>
                <span>|</span>
              </>
            );
          })}

          {TABS_FOR_EXTERNAL_LINKS.map((link) => {
            return (
              <>
                <Button
                  key={link.title}
                  sx={{
                    '&.MuiButtonBase-root:hover': {
                      bgcolor: 'transparent',
                    },
                    '& .MuiTypography-root': {
                      color: 'text.secondary',
                      '& hover': {
                        bgcolor: 'none',
                      },
                    },
                  }}
                >
                  <Link href={link.link} underline='none' target='_blank' rel='noopener'>
                    {link.title}
                  </Link>
                </Button>
                <span>|</span>
              </>
            );
          })}
        </Box>

        <Typography variant='subtitle1' component='h5'>
          Copyright ⓒDecakill. All rights reserved.
        </Typography>
      </Container>
    </Box>
  );
};

const footerStyling = {
  borderTop: `1px solid #292831`,
  backgroundColor: '#0e0e13',
  fontSize: 13,
  textAlign: 'center',
  padding: '28px',
  height: '112px',
  width: '100%',
  zIndex: 9999,
};

export default Footer;
