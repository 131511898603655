import {
  Avatar,
  Box,
  Button,
  FormHelperText,
  Input,
  List,
  ListItem,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { SetStateAction, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { errorSelector, useRecoilState, useRecoilValue } from 'recoil';
import { userAtom } from '../../../recoil/Auth/userAtom';
import MyPageServices, { inquiryData } from '../../../services/MyPageServices';
import CustomSelectBox from '../../../components/customSelectBox';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';

const TABS = [{ title: '1:1 문의' }];

const TOP_NOTICE = [
  {
    item: '접수된 모든 문의는 순차적으로 답변해 드리고 있습니다.',
  },
  {
    item: '문의량이 급증하거나 확인에 시간이 걸리는 경우, 중복된 메일인 경우 답변이 지연될 수 있습니다.',
  },
  {
    item: '주말 및 법정 공휴일은 고객센터 휴무로 메일 상담이 어려우며, 휴일 이후 영업일부터 상담업무가 재개됩니다.',
  },
];

const BOTTOM_NOTICE = [
  {
    item: '첨부파일 용량은 개당 최대 10MB까지만 지원합니다.',
  },
  {
    item: '파일은 최대 5개까지 첨부됩니다.',
  },
  {
    item: '파일은 JPEG, JPG, PNG 및 GIF 등 이미지 파일만 첨부 가능합니다.',
  },
];

const INQUIRY_TYPE = [
  { name: '문의', idx: '2' },
  { name: '버그', idx: '1' },
  { name: '기타', idx: '3' },
];

const USER_TYPE = [
  { name: '크리에이터', idx: '2' },
  { name: '시청자', idx: '1' },
];

const Inquiry = () => {
  const navigate = useNavigate();
  const [inquiryType, setInquiryType] = useState(''); //default: 문의
  const [userType, setUserType] = useState(''); //default: 크리에이터
  //   const [fileList, setFileList] = useState<SetStateAction<FileList | null>>();
  const [files, setFiles] = useState<File[]>([]);
  const user = useRecoilValue(userAtom);

  const {
    mutate,
    data,
    isLoading: isSubmitting,
    isError,
    error,
    isSuccess,
  } = useMutation((data: inquiryData | any) => MyPageServices.sendInquiry(data));

  const {
    handleSubmit,
    watch,
    control,
    formState: { errors, isDirty, dirtyFields },
    setValue,
    reset,
  } = useForm<Omit<inquiryData, 'serviceType' | 'accountsType'>>({
    defaultValues: {
      insertUserCSReq: {
        uid: user?.uid,
        email: '',
        title: '',
        content: '',
      },
      files: [],
    },
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFiles((prev) => [...prev, ...Array.from(e.target.files || [])]);
  };

  const handleDelete = (id: number) => {
    const store = new DataTransfer();
    const newFiles = files
      .filter((file, i) => i !== id)
      .map((file) => {
        store.items.add(file);
        return file;
      });

    setValue('files', Array.from(store.files));
    setFiles(newFiles);
  };

  const onSubmit = (data: any, e: any) => {
    e.preventDefault();
    const {
      insertUserCSReq: { email, title, content },
      files,
    } = data;

    const dataObj = {
      uid: user!.uid,
      email,
      title,
      content,
      serviceType: Number(inquiryType),
      accountsType: Number(userType),
    };

    const formData = new FormData();
    //  aplication/JSON, multipart/FORM-DATA 같이 보내기
    if (files.length) {
      for (let i = 0; i < files.length; i++) {
        formData.append('files', files[i]);
      }
    }
    formData.append('insertUserCSReq', new Blob([JSON.stringify(dataObj)], { type: 'application/json' }));
    mutate(formData);
  };

  return (
    <Box sx={{ borderRadius: 2, bgcolor: 'background.modal', padding: '32px' }}>
      {/* 탭 */}
      <Box sx={{ position: 'relative' }}>
        {TABS.map((tab) => {
          return (
            <Box
              borderBottom={1}
              sx={{
                display: 'flex',
                borderBottomColor: 'customMenuListColor.light',
              }}
            >
              <Typography
                variant='h6'
                sx={{ mr: 2, borderBottom: '4px solid #af51ff', paddingBottom: '8px' }}
              >
                {tab.title}
              </Typography>
            </Box>
          );
        })}
      </Box>
      {isSuccess && data.status.status_code === 200 ? (
        <Stack
          spacing={4}
          sx={{
            minHeight: 410,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            textAlign: 'center',
            py: 40,
          }}
        >
          <Typography variant='h5' sx={{ color: '#fff' }}>
            1:1문의가 완료되었습니다.
          </Typography>
          <Typography variant='body2'>
            최대한 빠르게 답변을 드리도록 노력하는 decakill 이 되겠습니다. <br />
            문의 주신 사항에 답변은 입력해주신 e-mail 로 발송됩니다.
          </Typography>
          <Button variant='contained' onClick={() => navigate('/rank')}>
            서비스 이용하기
          </Button>
        </Stack>
      ) : (
        <>
          <List>
            {TOP_NOTICE.map((el) => {
              return (
                <ListItem
                  disableGutters
                  key={el.item}
                  sx={{
                    fontSize: 15,
                    color: 'text.primary',
                  }}
                >
                  • {el.item}
                </ListItem>
              );
            })}
          </List>
          {/* 문의 form */}
          <Stack my={6} spacing={5} component='form' onSubmit={handleSubmit(onSubmit)}>
            {/* 문의 / 계정 유형 */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <CustomSelectBox
                data={inquiryType}
                setData={setInquiryType}
                renderValue={(value: string) =>
                  value ? INQUIRY_TYPE.filter((type) => type.idx === value)[0].name : '문의 유형'
                }
                required
                sx={{
                  marginRight: 2,
                  width: 473,
                  height: 48,
                  // input 배경색 지정
                  '&.MuiInputBase-root': {
                    bgcolor: '#25252a',
                  },
                }}
                filledSx={{
                  // 값 선택 시 보더 추가
                  '&.MuiFormLabel-filled ~ div': {
                    color: 'white',
                    border: '1px solid',
                    borderColor: '#8c40cc',
                  },
                }}
              >
                {INQUIRY_TYPE.map((item) => {
                  return (
                    <MenuItem key={`item ${item}`} value={item.idx}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </CustomSelectBox>
              <CustomSelectBox
                data={userType}
                setData={setUserType}
                renderValue={(value: string) =>
                  value ? USER_TYPE.filter((type) => type.idx === value)[0].name : '계정 유형'
                }
                required
                sx={{
                  marginRight: 2,
                  width: 473,
                  height: 48,

                  // input 배경색 지정
                  '&.MuiInputBase-root': {
                    bgcolor: '#25252a',
                  },
                }}
                filledSx={{
                  // 값 선택 시 보더 추가
                  '&.MuiFormLabel-filled ~ div': {
                    color: 'white',
                    border: '1px solid',
                    borderColor: '#8c40cc',
                  },
                }}
              >
                {USER_TYPE.map((item) => {
                  return (
                    <MenuItem key={`item ${item}`} value={item.idx}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </CustomSelectBox>
            </Box>
            {/* 이메일 */}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ width: 473, mr: 5 }}>
                <Controller
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { error: emailError },
                  }) => (
                    <TextField
                      // type='email'
                      id='email'
                      name={name}
                      autoComplete='off'
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      inputRef={ref}
                      error={!!emailError}
                      //   label={`${t(`나의정보.성`, { ns: 'mypage' })} * `}
                      label='이메일'
                      inputProps={{ readOnly: false }}
                      sx={{
                        width: '100%',
                        bgcolor: 'customMenuListColor.main',
                        borderRadius: 2,
                        '& input[type=number]::-webkit-outer-spin-button': {
                          '-webkit-appearance': 'none',
                          margin: 0,
                        },
                        '& input[type=number]::-webkit-inner-spin-button': {
                          '-webkit-appearance': 'none',
                          margin: 0,
                        },
                        '& .MuiOutlinedInput-input': {
                          //   padding: '12px ',
                        },
                      }}
                    />
                  )}
                  name='insertUserCSReq.email'
                  control={control}
                  rules={{
                    required: '이메일을 입력해주세요.',
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i,
                      message: '이메일 형식이 아닙니다.',
                    },
                  }}
                />
                {errors.insertUserCSReq?.email && (
                  <FormHelperText
                    sx={{
                      color: 'text.helper',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Avatar
                      src='/images/icon/ico-warning-blank.svg'
                      alt='warning'
                      sx={{
                        width: '14px',
                        height: '14px',
                        mr: 1,
                      }}
                    />
                    {/* {t(`나의정보.미입력에러`, { ns: 'mypage' })} */}
                    {errors.insertUserCSReq?.email.message}
                  </FormHelperText>
                )}
              </Box>
              <Typography variant='body2'>작성해주신 이메일로 답변이 발송됩니다.</Typography>
            </Box>
            {/* 제목 */}
            <Box sx={{ width: 966 }}>
              <Controller
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { error: titleError },
                }) => (
                  <TextField
                    // type='text'
                    id='title'
                    name={name}
                    autoComplete='off'
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    inputRef={ref}
                    error={!!titleError}
                    //   label={`${t(`나의정보.성`, { ns: 'mypage' })} * `}
                    label='제목'
                    inputProps={{ readOnly: false, minLength: 1, maxLength: 50 }}
                    sx={{
                      width: '100%',
                      bgcolor: 'customMenuListColor.main',
                      borderRadius: 2,
                      '& input[type=number]::-webkit-outer-spin-button': {
                        '-webkit-appearance': 'none',
                        margin: 0,
                      },
                      '& input[type=number]::-webkit-inner-spin-button': {
                        '-webkit-appearance': 'none',
                        margin: 0,
                      },
                    }}
                  />
                )}
                name='insertUserCSReq.title'
                control={control}
                rules={{
                  required: '제목을 입력해주세요.',
                  minLength: {
                    value: 1,
                    message: '최소 1글자 이상 입력해주세요.',
                  },
                }}
              />
              {errors.insertUserCSReq?.title && (
                <FormHelperText
                  sx={{
                    color: 'text.helper',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Avatar
                    src='/images/icon/ico-warning-blank.svg'
                    alt='warning'
                    sx={{
                      width: '14px',
                      height: '14px',
                      mr: 1,
                    }}
                  />
                  {/* {t(`나의정보.미입력에러`, { ns: 'mypage' })} */}
                  {errors.insertUserCSReq?.title.message}
                </FormHelperText>
              )}
            </Box>
            {/* 에러 텍스트 표기 */}
            {/* 본문 */}
            <Box sx={{ width: 966 }}>
              <Controller
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { error: contentsError },
                }) => (
                  <TextField
                    // type='text'
                    multiline
                    rows={5}
                    id='content'
                    name={name}
                    autoComplete='off'
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    inputRef={ref}
                    error={!!contentsError}
                    helperText={`(${value?.length ?? 0}/500)`}
                    //   label={`${t(`나의정보.성`, { ns: 'mypage' })} * `}
                    label='문의내용'
                    inputProps={{ readOnly: false, minLength: 5, maxLength: 500 }}
                    sx={{
                      width: '100%',
                      bgcolor: 'customMenuListColor.main',
                      borderRadius: 2,
                      '& input[type=number]::-webkit-outer-spin-button': {
                        '-webkit-appearance': 'none',
                        margin: 0,
                      },
                      '& input[type=number]::-webkit-inner-spin-button': {
                        '-webkit-appearance': 'none',
                        margin: 0,
                      },
                      '& .MuiFormHelperText-root': {
                        position: 'absolute',
                        right: '0px',
                        bottom: '10px',
                      },
                    }}
                  />
                )}
                name='insertUserCSReq.content'
                control={control}
                rules={{
                  required: '본문을 입력해주세요.',
                  minLength: {
                    value: 5,
                    message: '최소 5글자 이상 입력해주세요.',
                  },
                }}
              />
              {errors.insertUserCSReq?.content && (
                <FormHelperText
                  sx={{
                    color: 'text.helper',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Avatar
                    src='/images/icon/ico-warning-blank.svg'
                    alt='warning'
                    sx={{
                      width: '14px',
                      height: '14px',
                      mr: 1,
                    }}
                  />
                  {/* {t(`나의정보.미입력에러`, { ns: 'mypage' })} */}
                  {errors.insertUserCSReq?.content.message}
                </FormHelperText>
              )}
            </Box>

            {/* 첨부파일 */}
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
              <Stack spacing={2}>
                <Controller
                  render={({
                    field: { onChange, onBlur, value, ref, name },
                    fieldState: { error: fileError },
                  }) => {
                    return (
                      <Button
                        variant='contained'
                        component='label'
                        sx={{ width: '107px', bgcolor: '#25252a' }}
                      >
                        파일 첨부
                        <input
                          hidden
                          accept='image/*,.gif'
                          multiple
                          type='file'
                          name={name}
                          onChange={(e) => {
                            // e.target.value = '';
                            if (files.length >= 5) {
                              return alert('파일은 최대 5개까지 첨부할 수 있습니다.');
                            }
                            if (
                              Array.from(e.target.files || []).some((file) => file.size > 10 * 1024 * 1024)
                            ) {
                              return alert('파일 크기는 10MB를 넘을 수 없습니다.');
                            }
                            onChange(e.target.files); // RHF onchange event
                            handleChange(e); // custom onchange event
                          }}
                          onBlur={onBlur}
                        />
                      </Button>
                    );
                  }}
                  name='files'
                  control={control}
                  rules={{ required: false }}
                />

                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}>
                  {files.map((item, id) => {
                    return (
                      <Box
                        sx={{
                          mx: 1,
                          width: 150,
                          height: 150,
                          bgcolor: '#3b3b40',
                        }}
                      >
                        <Stack
                          sx={{
                            position: 'relative',
                            height: '100%',
                          }}
                        >
                          <IconButton
                            aria-label='delete'
                            onClick={() => handleDelete(id)}
                            sx={{
                              width: 24,
                              height: 24,
                              position: 'absolute',
                              right: 0,
                              top: 0,
                              borderRadius: 0,
                              padding: 0,
                              backgroundColor: 'primary.main',
                              color: 'white',

                              '&:hover, &:active': {
                                backgroundColor: 'primary.dark',
                              },
                            }}
                          >
                            <ClearIcon sx={{ fontSize: 18 }} />
                          </IconButton>
                          <Box sx={{ flex: 1, overflow: 'hidden' }}>
                            <img
                              src={window.URL.createObjectURL(item)}
                              alt={item.name}
                              style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'contain',
                              }}
                            />
                          </Box>
                          <Typography
                            variant='body2'
                            sx={{
                              px: 1,
                              color: '#fff',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textAlign: 'center',
                            }}
                          >
                            {item.name.split('.')[0].slice(0, 10)}
                            ...
                            {item.name.slice(-4)}
                          </Typography>
                        </Stack>
                      </Box>
                    );
                  })}
                </Box>
              </Stack>
            </Box>
            {/* 하단 문구 */}
            <List>
              {BOTTOM_NOTICE.map((el) => {
                return (
                  <ListItem
                    disableGutters
                    key={el.item}
                    sx={{
                      fontSize: 15,
                      color: 'text.secondary',
                    }}
                  >
                    • {el.item}
                  </ListItem>
                );
              })}
            </List>

            {/* 문의버튼 */}
            <LoadingButton
              type='submit'
              autoFocus
              loading={isSubmitting}
              variant='contained'
              sx={{
                '&.Mui-disabled': {
                  bgcolor: 'primary.main',
                },
                width: '107px',
              }}
            >
              {/* {t(`저장`, { ns: 'common' })} */}
              문의하기
            </LoadingButton>
          </Stack>
        </>
      )}
    </Box>
  );
};

export default Inquiry;
