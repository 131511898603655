import { axiosGet, axiosPost } from './axios/axios';
import { AllData, getAllData, getYearlyData, YearlyData } from './MyPageServices';

export interface getDaliyRankingParams {
  uid: string | undefined;
  channelId: string | null;

  keyword: string | null;
}

export interface getRank {
  uid: string;
  channelId: string | null;
  year: number;
  keyword: string | null;
}

export interface getRankByUser {
  channelId: string;
  year: string;
  keyword: string;
}

interface RankResponse {
  rankingCount: number;
  rankingData: RankData[];
  myRankingData: {
    uid: string;
    rankData: RankData[] | null;
    myRankingRatio: number;
  };
  status: CustomStatus;
}

export interface CustomStatus {
  auth: string;
  description: string;
  error_code: null | number;
  message: string;
  status_code: number;
}

export interface RankData {
  uid: string;
  displayName: null | string;
  profileImageUrl: string | null;
  kill: number;
  comboKill: number;
  multiKill: number;
  death: number;
  gameCount: number;
  ranking: number;
  rankingRatio: number;
}

export interface getUserInfo {
  uid: string;
}

export interface UserInfoResponse {
  status: CustomStatus;
  userInfo: {
    uid: string;
    displayName: string;
    profileImageUrl: string;
  };
}

export interface StreamerListResponse {
  status: CustomStatus;
  streamerList: List[];
  streamerListCount: number;
}

export interface List {
  idx: number;
  broadcastId: string;
  displayName: string;
  profileImageUrl: string;
}

export interface LeagueListResponse {
  status: CustomStatus;
  leagueList: List[];
  leagueListCount: number;
}

class RankServices {
  // 이스포츠
  //  리그 목록 (전체 랭킹 only)
  getLeagueList = (): Promise<LeagueListResponse> => {
    return new Promise((resolve, reject) => {
      axiosGet({
        url: '/ranking/league',
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  //  리그 목록 (데일리 랭킹 only)
  getDailyLeagueList = (): Promise<LeagueListResponse> => {
    return new Promise((resolve, reject) => {
      axiosGet({
        url: '/ranking/league/daily',
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  getEsportsRanking = (data: getRank): Promise<RankResponse> => {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/ranking/league',
        data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  getAllData = (data: getAllData): Promise<AllData> => {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/ranking/league/pop-up/all',
        data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  getYearlyData = (data: getYearlyData): Promise<YearlyData> => {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/ranking/league/pop-up/yearly',
        data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  getUserInfo = (data: getUserInfo): Promise<UserInfoResponse> => {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/ranking/league/pop-up/info',
        data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  //  스트리머 목록 (전체 랭킹 only)
  getStreamerList = (): Promise<StreamerListResponse> => {
    return new Promise((resolve, reject) => {
      axiosGet({
        url: '/ranking/streamer',
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  //  스트리머 목록 (데일리 랭킹 only)
  getDailyStreamerList = (): Promise<StreamerListResponse> => {
    return new Promise((resolve, reject) => {
      axiosGet({
        url: '/ranking/streamer/daily',
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  getStreamerRanking = (data: getRank): Promise<RankResponse> => {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/ranking/streamer',
        data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  getStreamerAllData = (data: getAllData): Promise<AllData> => {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/ranking/streamer/pop-up/all',
        data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  getStreamerYearlyData = (data: getYearlyData): Promise<YearlyData> => {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/ranking/streamer/pop-up/yearly',
        data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  getStreamerUserInfo = (data: getUserInfo): Promise<UserInfoResponse> => {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/ranking/streamer/pop-up/info',
        data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  getDaliyRankingBySteamer = (data: getDaliyRankingParams): Promise<RankResponse> => {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/ranking/streamer/daily',
        data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export default new RankServices();
